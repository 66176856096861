import { UserRoleName } from 'domain/UserProfile';

type GenericCommunityUser = {
  category?: string;
  communityRole?: UserRoleName;
  role?: UserRoleName | string;
};

type CommunityOwner = {
  category?: string;
  communityRole?: UserRoleName.CommunityOwner;
  role?: UserRoleName.CommunityOwner;
};

type CommunityManager = {
  communityRole?: UserRoleName.CommunityManager;
  role?: UserRoleName.CommunityManager;
};

type CommunityMember = {
  category?: string;
  communityRole?: UserRoleName.CommunityMember;
  role?: UserRoleName.CommunityMember;
};

export const isCommunityOwner = (user: GenericCommunityUser = {}): user is CommunityOwner =>
  user.role === UserRoleName.CommunityOwner ||
  user.communityRole === UserRoleName.CommunityOwner ||
  user.category === 'CO';

export const isCommunityManager = (user: GenericCommunityUser = {}): user is CommunityManager =>
  user.role === UserRoleName.CommunityManager || user.communityRole === UserRoleName.CommunityManager;

export const isCommunityMember = (user: GenericCommunityUser = {}): user is CommunityMember =>
  user.role === UserRoleName.CommunityMember || user.communityRole === UserRoleName.CommunityMember;
