import React, { FC, ReactNode } from 'react';

import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { SearchBox } from 'common/components';
import { FilterByTags } from 'community/components';
import type { Tag, TagsCategory } from 'domain/Common';
import type { CommunityTag } from 'domain/Community';
import type { UserRole } from 'domain/UserProfile';

import { ShowCommunityManagers } from '../ShowCommunityManagers';
import { useMembersHeaderStyles } from './MembersHeader.styles';
import { FilterByRole } from './components';

const TEST_IDS = {
  membersHeaderSelectedTags: 'MembersHeader-selectedTags',
};

export const MembersHeader: FC<{
  label?: ReactNode;
  selectedTags: Tag[];
  selectedRoles?: UserRole[];
  showManagers: boolean;
  showFilters: boolean;
  tagsCategories: TagsCategory[];
  onShowManagersChange?(value: boolean): void;
  onSelectedTagsChange(tags: Tag[]): void;
  onSearchPhraseChange(value: string): void;
  onSelectedRolesChange?(tags: UserRole[]): void;
}> = ({
  label,
  selectedTags,
  selectedRoles = [],
  showManagers,
  showFilters,
  tagsCategories,
  onSelectedTagsChange,
  onShowManagersChange,
  onSearchPhraseChange,
  onSelectedRolesChange,
}) => {
  const classes = useMembersHeaderStyles();
  const theme = useTheme();
  const clearSelectedTag = (tag: CommunityTag) => () =>
    onSelectedTagsChange(selectedTags.filter((t) => t.id !== tag.id));

  const clearAllSelectedTags = () => onSelectedTagsChange([]);

  return (
    <Stack spacing={1}>
      <Grid display="grid" gridTemplateColumns="1fr 304px" container item>
        <Stack direction="row" columnGap={4} rowGap={2} flexWrap="wrap" alignItems="center">
          {label}
          <Box flex={1} />
          {showFilters && (
            <Stack direction="row" alignItems="center" gap={4}>
              {!!onSelectedRolesChange && (
                <FilterByRole selectedRoles={selectedRoles} onChange={onSelectedRolesChange} />
              )}
              <FilterByTags
                tagsCategories={tagsCategories}
                selectedTags={selectedTags}
                onChange={onSelectedTagsChange}
              />
            </Stack>
          )}

          {!!onShowManagersChange && (
            <ShowCommunityManagers isSelected={showManagers} onClick={() => onShowManagersChange(!showManagers)} />
          )}
        </Stack>
        <Grid container item display="flex" justifyContent="flex-end" pl={5}>
          <SearchBox onSearchDebounced={onSearchPhraseChange} placeholder="Search Members" />
        </Grid>
      </Grid>
      {!!selectedTags?.length && (
        <Grid container alignItems="center">
          {selectedTags.map((tag, index) => (
            <Grid
              item
              data-testid={TEST_IDS.membersHeaderSelectedTags}
              display="flex"
              alignItems="center"
              sx={{ paddingTop: 1, paddingBottom: 0.5, marginLeft: index !== 0 ? 1 : 0 }}
              key={tag.id}
              className={classes.tag}
            >
              <Grid item>{`${tag.name[0] === '#' ? '' : '#'}${tag.name}`}</Grid>
              <Grid
                item
                sx={{ cursor: 'pointer' }}
                color={theme.palette.custom.greySolid}
                marginLeft={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding={0.25}
                onClick={clearSelectedTag(tag)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Grid>
            </Grid>
          ))}

          <Grid item sx={{ paddingLeft: 2, cursor: 'pointer' }}>
            <Typography
              variant="caption"
              color={theme.palette.common.black}
              fontWeight="bold"
              lineHeight={0}
              onClick={clearAllSelectedTags}
            >
              Clear all
            </Typography>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};
