import { InferType, array, object, string } from 'yup';

export const userEmailSchema = array()
  .of(
    object({
      email: string().label('Email').email('Please make sure that the emails are in the correct format.').required(),
    })
  )
  .default([]);
export type UserEmailFormValues = InferType<typeof userEmailSchema>;
