import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { addAppSuccessMessage } from 'store/features/alerts';

import { SetupSurveyProgressTrackerStep } from '../components/SetupSurveyStep/constants';
import { SerializedSurveyFormData } from '../components/SetupSurveyStep/utils';
import { createReport, deleteSurvey } from './surveyWizard.effects';

export const surveysWizardActions = {
  reset: createAction('[Survey Wizard] reset'),
  navigationBar: {
    exitButton: {
      clicked: createAction('[Survey Wizard NavigationBar] exit button clicked'),
    },
    deleteButton: {
      clicked: deleteSurvey,
    },
    previewButton: {
      clicked: createAction('[Survey Wizard NavigationBar] preview button clicked'),
    },
    createReportButton: {
      clicked: createReport,
    },
  },
  actionButton: {
    createButton: {
      clicked: createAction<{ name: string | undefined }>('[Survey Wizard NavigationBar] create button clicked'),
    },
    cancelButton: {
      clicked: createAction('[Survey Wizard ActionButton] cancel button clicked'),
    },
    backButton: {
      clicked: createAction('[Survey Wizard ActionButton] back button clicked'),
    },
    nextButton: {
      clicked: createAction('[Survey Wizard ActionButton] next button clicked'),
    },
  },
  surveyPreview: {
    closeButton: {
      clicked: createAction('[Survey Wizard NavigationBar] preview close button clicked'),
    },
    submitButton: {
      clicked: createAsyncThunk<void, void>(
        '[Survey Wizard SurveyPreview] submit button clicked',
        (_, { dispatch }) => {
          dispatch(addAppSuccessMessage(`Survey successfully submitted by the end user`));
        }
      ),
    },
  },
  progressTracker: {
    stepCompleted: createAction<SetupSurveyProgressTrackerStep>('[Survey Wizard Progress Tracker] step completed'),
    stepClicked: createAction<SetupSurveyProgressTrackerStep>('[Survey Wizard Progress Tracker] step clicked'),
    setAllStepsCompleted: createAction('[Survey Wizard Progress Tracker] set all steps completed'),
  },
  untitledSurveyDetected: createAction('[Survey Wizard] untitled survey detected'),
  form: {
    submitted: createAction<SerializedSurveyFormData>('[Survey Wizard Form] form submitted'),
  },
};
