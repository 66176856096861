import React, { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';
import { ButtonBase, ClickAwayListener, ListItemText, Popper, Typography } from '@mui/material';
import { Button } from 'common/components/Buttons';
import { motion } from 'framer-motion';

import {
  MultipleSelectActions,
  MultipleSelectContent,
  MultipleSelectHeader,
  MultipleSelectInputCheckbox,
  MultipleSelectInputMenuItem,
  MultipleSelectInputSearchInput,
  MultipleSelectInputSearchWrapper,
  MultipleSelectInputSelectAllItem,
  MultipleSelectList,
} from './MultipleSelectInput.styled';

export type MultipleSelectInputOption = { label: string; value: string | number; count?: number };

const getOptionLabel = (option: MultipleSelectInputOption) =>
  option.count === undefined ? option.label : `${option.label} (${option.count})`;

type Props = {
  label: string;
  name: string;
  options: MultipleSelectInputOption[];
  searchLabel: string | undefined;
  anchorEl: HTMLButtonElement | null;
  onClose(): void;
};

export const MultipleSelectInputContent: FC<Props> = ({
  label,
  searchLabel = `Search ${label}`,
  name,
  options,
  anchorEl,
  onClose,
}) => {
  const { field } = useController<{ [key: string]: MultipleSelectInputOption['value'][] | undefined }>({ name });
  const { value = [], onChange } = field;
  const [searchQuery, setSearchQuery] = useState('');
  const [tmpValue, setTmpValue] = useState<(string | number)[]>(value);
  const filteredOptions = useMemo(
    () => options.filter((option) => option.label.toLowerCase().includes(searchQuery.trim().toLowerCase())),
    [options, searchQuery]
  );
  const isAllOptionsSelected = useMemo(() => tmpValue.length === options.length, [options, tmpValue]);
  const isOpen = !!anchorEl;
  const showSearch = options.length > 1;
  const showSelectAllOption = showSearch && !searchQuery;

  const resetTempValue = () => setTmpValue([]);

  const handleSelectAll = () => {
    if (isAllOptionsSelected) {
      resetTempValue();
    } else {
      setTmpValue(options.map((option) => option.value));
    }
  };

  const handleChange = (option: MultipleSelectInputOption) => () => {
    const isCurrentlySelected = tmpValue.includes(option.value);
    const newValue = isCurrentlySelected ? tmpValue.filter((v) => v !== option.value) : tmpValue.concat([option.value]);
    setTmpValue(newValue);
  };

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchQuery(event.target.value);
  };

  const applyChanges = () => {
    onChange({ target: { name, value: tmpValue } });
    onClose();
  };

  const preventPropagation = (e: { stopPropagation: Function }) => e.stopPropagation();

  useEffect(() => {
    if (!anchorEl) return;

    setTmpValue(value);
    setSearchQuery('');
  }, [anchorEl, value]);

  return (
    <Popper open={isOpen} anchorEl={anchorEl}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
        <ClickAwayListener onClickAway={onClose}>
          <MultipleSelectList>
            <MultipleSelectHeader>
              <Typography variant="natter-text-sm" fontWeight={600}>
                {label}
              </Typography>
              <ButtonBase onClick={onClose}>
                <CloseIcon fontSize="small" />
              </ButtonBase>
            </MultipleSelectHeader>

            <MultipleSelectContent>
              {showSearch && (
                <MultipleSelectInputSearchWrapper onKeyDown={preventPropagation}>
                  <MultipleSelectInputSearchInput
                    size="small"
                    label={searchLabel}
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </MultipleSelectInputSearchWrapper>
              )}

              {showSelectAllOption && (
                <MultipleSelectInputSelectAllItem onClick={handleSelectAll}>
                  <MultipleSelectInputCheckbox checked={isAllOptionsSelected} size="small" />
                  <ListItemText primary="Select all" />
                </MultipleSelectInputSelectAllItem>
              )}

              {filteredOptions.map((option, index) => (
                <MultipleSelectInputMenuItem
                  key={index}
                  value={option.value}
                  title={option.label}
                  onClick={handleChange(option)}
                >
                  <MultipleSelectInputCheckbox checked={tmpValue.includes(option.value)} size="small" />
                  <ListItemText primary={getOptionLabel(option)} />
                </MultipleSelectInputMenuItem>
              ))}
            </MultipleSelectContent>

            <MultipleSelectActions>
              <Button onClick={resetTempValue} variant="text">
                Reset
              </Button>
              <Button onClick={applyChanges}>Apply</Button>
            </MultipleSelectActions>
          </MultipleSelectList>
        </ClickAwayListener>
      </motion.div>
    </Popper>
  );
};
