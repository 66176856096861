import { createAsyncThunk } from '@reduxjs/toolkit';
import { assert, generateDynamicPath } from 'common/utils';
import { pick } from 'lodash';
import { push } from 'redux-first-history';
import { AppRoutes } from 'router';
import type { Effect, RootState } from 'store';
import { ReportSource } from 'store/apis/insightsReport';
import { AsyncSurvey, selectCurrentSurvey, selectIsCurrentQuestionsReadOnly, surveyApi } from 'store/apis/survey';
import { addAppConfirmationDialog } from 'store/features/alerts';
import { selectActiveCommunityId } from 'store/features/community';

import { SetupSurveyProgressTrackerStep } from '../components/SetupSurveyStep/constants';
import { SerializedSurveyFormData } from '../components/SetupSurveyStep/utils';
import { DEFAULT_SURVEY_NAME } from '../constants';
import { surveysWizardActions } from './surveyWizard.actions';
import { selectActiveStep, selectIsEditMode } from './surveyWizard.selectors';

export const exitOrCanceledEffect: Effect = (_, { dispatch }) => {
  dispatch(push(AppRoutes.Surveys));
};

export const untitledSurveyDetectedEffect: Effect = (_, { dispatch }) => {
  dispatch(
    addAppConfirmationDialog({
      title: 'Survey title is empty',
      description: 'The Survey title cannot be empty. Please enter a title to proceed.',
      severity: 'danger',
      confirmLabel: 'Understood',
      showWarningIcon: false,
      centered: true,
    })
  );
};

export const startCreateSurveyEffect: Effect = ({ payload: { name } }, { dispatch }) => {
  dispatch(surveysWizardActions.progressTracker.stepClicked(SetupSurveyProgressTrackerStep.Questions));
  dispatch(push(AppRoutes.SurveyWizardCreate, { name }));
};

export const publishSucceededEffect: Effect = (_, { dispatch }) => {
  dispatch(push(AppRoutes.Surveys));
};

export const updateSucceededEffect: Effect = (_, { dispatch }) => {
  dispatch(push(AppRoutes.Surveys));
  dispatch(
    addAppConfirmationDialog({
      title: 'Survey successfully updated',
      description: 'Your Survey was successfully updated and shared with your participants.',
      confirmLabel: 'Got it',
      showWarningIcon: false,
      centered: true,
    })
  );
};

export const deleteSurvey = createAsyncThunk<void, void>(
  '[SurveyWizard] delete survey button clicked',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const survey = selectCurrentSurvey(state);
    assert(survey);

    const { id } = survey;

    try {
      await dispatch(surveyApi.endpoints.deleteSurvey.initiate({ id })).unwrap();
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const deleteSucceededEffect: Effect = (_, { dispatch }) => {
  dispatch(push(AppRoutes.Surveys));
};

export const nextButtonClickedEffect: Effect = (_, { dispatch, getState }) => {
  const state = getState();

  const currentStep = selectActiveStep(state);
  // @ts-expect-error - TS doesn't know that the enum is iterable
  const nextStep = SetupSurveyProgressTrackerStep[SetupSurveyProgressTrackerStep[currentStep + 1]];

  dispatch(surveysWizardActions.progressTracker.stepCompleted(currentStep));
  dispatch(surveysWizardActions.progressTracker.stepClicked(nextStep));
};

export const backButtonClickedEffect: Effect = (_, { dispatch, getState }) => {
  const state = getState();

  const currentStep = selectActiveStep(state);
  // @ts-expect-error - TS doesn't know that the enum is iterable
  const nextStep = SetupSurveyProgressTrackerStep[SetupSurveyProgressTrackerStep[currentStep - 1]];

  dispatch(surveysWizardActions.progressTracker.stepCompleted(currentStep));
  dispatch(surveysWizardActions.progressTracker.stepClicked(nextStep));
};

export const formSubmittedEffect: Effect<ReturnType<typeof surveysWizardActions.form.submitted>> = (
  { payload },
  { dispatch, getState }
) => {
  if (!payload.name || payload.name === DEFAULT_SURVEY_NAME) {
    dispatch(surveysWizardActions.untitledSurveyDetected());
    return;
  }

  const state = getState();
  const isEditMode = selectIsEditMode(state);

  isEditMode ? dispatch(updateSurvey(payload)) : dispatch(publishSurvey(payload));
};

export const publishSurvey = createAsyncThunk<void, SerializedSurveyFormData>(
  '[SurveyWizard] publish initialized',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const communityId = selectActiveCommunityId(state);
    assert(communityId);

    const createFields: Array<keyof SerializedSurveyFormData> = [
      'name',
      'dueBy',
      'reminderIntervalAmount',
      'reminderIntervalTimeUnit',
      'welcomeMessage',
    ];

    const createValues = pick(payload, createFields);

    try {
      const { id } = await dispatch(
        surveyApi.endpoints.createSurvey.initiate({ communityId, ...createValues })
      ).unwrap();

      const { emails, questions } = payload;
      await dispatch(surveyApi.endpoints.createSurveyUsers.initiate({ id, emails })).unwrap();
      await dispatch(surveyApi.endpoints.createSurveyQuestions.initiate({ id, questions })).unwrap();

      await dispatch(surveyApi.endpoints.publishSurvey.initiate({ id })).unwrap();
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const updateSurvey = createAsyncThunk<void, SerializedSurveyFormData>(
  '[SurveyWizard] update initialized',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const surveyData = selectCurrentSurvey(state);
    const isQuestionsReadOnly = selectIsCurrentQuestionsReadOnly(state);
    assert(surveyData);

    const { id } = surveyData;

    const updateFields: Array<keyof AsyncSurvey> = [
      'name',
      'dueBy',
      'reminderIntervalAmount',
      'reminderIntervalTimeUnit',
      'welcomeMessage',
    ];

    const currentValues = pick(surveyData, updateFields);
    const updatedValues = pick(payload, updateFields);

    try {
      await dispatch(surveyApi.endpoints.updateSurvey.initiate({ ...currentValues, ...updatedValues, id })).unwrap();

      const { emails, questions } = payload;
      if (emails.length) {
        await dispatch(surveyApi.endpoints.createSurveyUsers.initiate({ id, emails })).unwrap();
      }

      if (!isQuestionsReadOnly) {
        await dispatch(surveyApi.endpoints.createSurveyQuestions.initiate({ id, questions })).unwrap();
      }
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const createReport = createAsyncThunk<void, void>(
  '[SurveyWizard] create report button clicked',
  (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const survey = selectCurrentSurvey(state);
    assert(survey);

    const { id, name, invitedUsers } = survey;

    dispatch(
      push(generateDynamicPath(AppRoutes.InsightsReportsWizardSurveys, {}), {
        reportSource: {
          guestCount: invitedUsers,
          id,
          name,
        } as ReportSource,
      })
    );
  }
);
