import React, { FC } from 'react';

import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import { IconButtonWithTooltip } from 'common/components';

type Props = {
  onCopy(): void;
};

export const EventCopyPinButton: FC<Props> = ({ onCopy }) => (
  <IconButtonWithTooltip
    title="Copy Event PIN"
    size="medium"
    color="secondary"
    onClick={onCopy}
    aria-label="Copy Event PIN"
  >
    <PinOutlinedIcon fontSize="small" />
  </IconButtonWithTooltip>
);
