import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, ButtonBase, Checkbox, FormControl, MenuItem, MenuProps, Stack, styled } from '@mui/material';
import { SearchInput } from 'common/components/Inputs';
import { generateContrastColor, shadows } from 'common/theme';

export const MultipleSelectInputFormControl = styled(FormControl)(() => ({
  minWidth: 200,
}));

export const MultipleSelectInputCheckbox = styled(Checkbox)(() => ({
  padding: 0,
  '& .MuiSvgIcon-root': {
    width: 24,
    height: 24,
  },
}));

export const MultipleSelectInputMenuItem = styled(MenuItem)(({ theme: { spacing, typography } }) => ({
  display: 'flex',
  gap: spacing(1),
  height: '36px',
  paddingLeft: spacing(3),
  paddingRight: spacing(2),
  '&.Mui-selected': {
    backgroundColor: 'unset',
  },
  '& .MuiListItemText-primary': {
    ...typography['natter-text-sm'],
  },
}));

export const MultipleSelectInputSelectAllItem = styled(MultipleSelectInputMenuItem)(() => ({
  '& .MuiListItemText-primary': {
    fontWeight: 700,
  },
}));

export const MultipleSelectInputSearchWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 2, 2, 2),
}));

export const MultipleSelectInputSearchInput = styled(SearchInput)(() => ({
  width: '100%',
}));

export const MultipleSelectInputMenuProps: Partial<MenuProps> = {
  PaperProps: {
    sx: ({ spacing }) => ({
      boxShadow: shadows.medium,
      marginTop: spacing(1),
    }),
  },
  variant: 'menu',
};

export const MultipleSelectButton = styled(ButtonBase)<{ isActive: boolean; fullWidth: boolean }>(
  ({ theme: { spacing, palette }, isActive, fullWidth }) => ({
    border: '1px solid',
    borderRadius: '8px',
    padding: spacing(0, 1),
    height: 32,
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 500,
    width: fullWidth ? '100%' : undefined,
    ...(isActive
      ? {
          borderColor: generateContrastColor(palette.primary.main),
          backgroundColor: generateContrastColor(palette.primary.main),
          color: palette.primary.main,
        }
      : {
          borderColor: palette.grey[300],
          backgroundColor: palette.common.white,
          color: palette.grey[700],
        }),
  })
);

export const MultipleSelectButtonContent = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const MultipleSelectArrowIcon = styled(ArrowDropDownIcon)<{ isOpen: boolean }>(({ isOpen }) => ({
  fontSize: '20px',
  transform: isOpen ? 'rotate(-180deg)' : '',
  transition: 'transform 200ms',
}));

export const MultipleSelectList = styled(Stack)(({ theme: { spacing, palette } }) => ({
  boxShadow: shadows.medium,
  background: palette.common.white,
  padding: spacing(2, 0),
  gap: spacing(2),
  width: 357,
}));

export const MultipleSelectHeader = styled(Stack)(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: spacing(0, 2),
}));

export const MultipleSelectActions = styled(Stack)(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  gap: spacing(2),
  justifyContent: 'end',
  padding: spacing(0, 2),
}));

export const MultipleSelectContent = styled(Stack)(() => ({
  maxHeight: '50vh',
  overflow: 'auto',
}));
