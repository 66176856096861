import React, { FC, MouseEvent, MouseEventHandler, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';

import ClearIcon from '@mui/icons-material/Clear';
import { Typography } from '@mui/material';
import { textEllipsis } from 'common/theme/typography';

import {
  MultipleSelectArrowIcon,
  MultipleSelectButton,
  MultipleSelectButtonContent,
} from './MultipleSelectInput.styled';
import { MultipleSelectInputContent, MultipleSelectInputOption } from './MultipleSelectInputContent';

type Props = {
  label: string;
  name: string;
  options: MultipleSelectInputOption[];
  searchLabel?: string;
  fullWidth?: boolean;
};

export const MultipleSelectInput: FC<Props> = ({
  label,
  searchLabel = `Search ${label}`,
  name,
  options,
  fullWidth = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { field } = useController<{ [key: string]: MultipleSelectInputOption['value'][] | undefined }>({ name });
  const { value = [], onChange } = field;
  const selectedOptions = useMemo(() => options.filter((option) => value.includes(option.value)), [options, value]);
  const isActive = value.length > 0;
  const isOpen = !!anchorEl;
  const buttonLabel = useMemo(() => {
    switch (selectedOptions.length) {
      case 0:
        return label;
      case 1:
        return selectedOptions[0].label;
      default:
        return `${selectedOptions[0].label} +${selectedOptions.length - 1}`;
    }
  }, [selectedOptions, label]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetValue: MouseEventHandler<unknown> = (event) => {
    event.stopPropagation();
    onChange({ target: { name, value: [] } });
    handleClose();
  };

  const preventPropagation = (e: { stopPropagation: Function }) => e.stopPropagation();

  return (
    <>
      <MultipleSelectButton onClick={handleClick} isActive={isActive} fullWidth={fullWidth}>
        <MultipleSelectButtonContent>
          <Typography sx={{ ...textEllipsis }} title={buttonLabel}>
            {buttonLabel}
          </Typography>
          {isActive ? (
            <ClearIcon fontSize="small" onClick={resetValue} onMouseDown={preventPropagation} sx={{ marginLeft: 1 }} />
          ) : (
            <MultipleSelectArrowIcon isOpen={isOpen} />
          )}
        </MultipleSelectButtonContent>
      </MultipleSelectButton>
      <MultipleSelectInputContent
        label={label}
        name={name}
        options={options}
        searchLabel={searchLabel}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </>
  );
};
