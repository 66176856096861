import React, { FC } from 'react';

import { faUserFriends } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';

import { useGuestsCounterStyles } from './GuestsCounter.styles';

export type GuestListProps = {
  amount: number;
};

export const GuestsCounter: FC<GuestListProps> = ({ amount }) => {
  const classes = useGuestsCounterStyles();

  return (
    <div className={classes.guestsCounterCounter}>
      <FontAwesomeIcon className={classes.guestsCounterIcon} size="sm" icon={faUserFriends} />
      <Typography className={classes.guestsCounterLabel}>{amount}</Typography>
    </div>
  );
};
