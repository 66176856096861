import { RefObject, useEffect } from 'react';

type Params = { ref: RefObject<HTMLInputElement | HTMLTextAreaElement> };

export const useTextInputAutoFocus = ({ ref }: Params) => {
  useEffect(() => {
    if (!ref.current) return;

    ref.current.focus();
    ref.current.selectionStart = ref.current.selectionEnd = ref.current.value.length;
  }, [ref]);
};
