import React, { FC, useMemo } from 'react';

import { Stack, Typography } from '@mui/material';
import { UserAvatar } from 'common/components';
import { useAppSelector } from 'store';
import { selectCurrentCommunityMember } from 'store/apis/community';
import { selectUserAccount } from 'store/apis/user';

export const SidebarProfile: FC = () => {
  const userAccount = useAppSelector(selectUserAccount);
  const communityMember = useAppSelector(selectCurrentCommunityMember);
  const displayName = useMemo(
    () =>
      communityMember
        ? communityMember.displayName ?? communityMember.name
        : `Account / ${userAccount?.firstName || ''} ${userAccount?.lastName || ''}`,
    [communityMember, userAccount?.firstName, userAccount?.lastName]
  );
  const title = useMemo(
    () => (communityMember ? communityMember?.role : userAccount?.email),
    [communityMember, userAccount?.email]
  );

  if (!userAccount) return null;

  return (
    <Stack flexDirection="row" gap={2} data-testid="SidebarProfile">
      <UserAvatar dimensions={72} name={userAccount.displayName} avatarLink={userAccount.avatarLink} />

      <Stack alignItems="start">
        <Typography variant="body1" fontWeight={600}>
          {displayName}
        </Typography>
        <Typography variant="body2" color="custom.darkGrey">
          {title}
        </Typography>
      </Stack>
    </Stack>
  );
};
