import React, { ComponentType, FC, ReactElement } from 'react';

import { faUsersSlash } from '@fortawesome/pro-light-svg-icons';
import { BaseGuard, Loadable } from 'common/components';
import { Community } from 'domain/Community';
import { useAppSelector } from 'store';
import { selectMyCommunity, selectMyCommunityIsError, selectMyCommunityIsLoading } from 'store/apis/community';

export const withCommunityJoined =
  <P extends { myCommunity: Community }>(WrappedComponent: ComponentType<P>): FC<Omit<P, 'myCommunity'>> =>
  (props): ReactElement => {
    const isLoading = useAppSelector(selectMyCommunityIsLoading);
    const isError = useAppSelector(selectMyCommunityIsError);
    const myCommunity = useAppSelector(selectMyCommunity);

    return (
      <Loadable isLoading={isLoading}>
        <BaseGuard
          icon={faUsersSlash}
          shouldDisplay={!isError && !!myCommunity}
          title="You are not a part of any Community"
          description="Contact Community Owner to add you as a Member."
        >
          <WrappedComponent {...(props as P)} myCommunity={myCommunity} />
        </BaseGuard>
      </Loadable>
    );
  };
