import { createApi } from '@reduxjs/toolkit/query/react';
import { dataToCsvFile } from 'common/utils';
import { serializeDateFields } from 'common/utils/time';
import { StartNatteringParams } from 'domain/Broadcast';
import { BasicPaginationParams, PaginatedApiPayloadV2, PaginationParams } from 'domain/Common';
import {
  EventDetails,
  EventGuest,
  EventImageApiResponse,
  EventImageParams,
  EventListItem,
  EventQuestionAssignmentType,
  EventQuestionDefinition,
  EventStatus,
  PublicEventDetails,
} from 'domain/event';
import type { CreateAnswerRequest } from 'domain/question';
import { flow } from 'lodash/fp';
import {
  mapConnectionsFromApi,
  mapEventDetailsFromApi,
  mapEventQuestionsFromApi,
  mapEventWizardFormValuesToApi,
  mapGetEventGuestsParams,
  mapPaginatedEventGuestsFromApi,
  mapPaginatedEventListItemsFromApi,
  mapPublicEventFromApi,
} from 'mappers/event';
import { getApiBaseUrl } from 'modules/api/utils';
import type { EventWizardFormSerializedValues } from 'pages/EventWizard/validation/schema';
import { generateFormData } from 'store/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import type { CreateEventQuestionRequest, CreatedEventApiResponse } from './types';
import { omitUndefined } from './utils';

type WithEventId<T = unknown> = { id: number | undefined } & T;
type EventListFilters = { communityId?: number; states?: EventStatus[]; searchText?: string };

type EventAnswers = { answers: CreateAnswerRequest[]; type: 'pre-event' | 'post-event' };

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v2') }),
  tagTypes: ['EVENT', 'ALL_EVENTS', 'PAST_EVENTS', 'UPCOMING_EVENT', 'EVENT_QUESTIONS'],
  endpoints: (builder) => ({
    getEventsList: builder.query<PaginatedApiPayloadV2<EventListItem>, BasicPaginationParams<EventListFilters>>({
      providesTags: ['ALL_EVENTS'],
      query: ({ page, pageSize, filters }) => ({
        url: `/event`,
        params: {
          page,
          size: pageSize,
          states: filters?.states?.join(','),
          communityId: filters?.communityId,
          searchText: filters?.searchText,
        },
      }),
      transformResponse: mapPaginatedEventListItemsFromApi,
    }),
    getEventDetails: builder.query<EventDetails, WithEventId>({
      query: ({ id }) => ({
        url: `/event/${id}`,
      }),
      transformResponse: flow(serializeDateFields('startDate'), mapEventDetailsFromApi),
      providesTags: (result) => (result ? [{ type: 'EVENT', id: result.id }] : []),
    }),
    getPublicEventDetails: builder.query<PublicEventDetails, WithEventId>({
      query: ({ id }) => ({
        url: `/event/${id}/register`,
      }),
      transformResponse: flow(serializeDateFields('startTime'), mapPublicEventFromApi),
    }),
    getEventGuests: builder.query<
      PaginatedApiPayloadV2<EventGuest>,
      PaginationParams & WithEventId<{ tagIds: number[] }>
    >({
      query: ({ id, ...args }) => ({
        url: `/event/${id}/guests`,
        params: mapGetEventGuestsParams(args),
      }),
      transformResponse: mapPaginatedEventGuestsFromApi,
    }),
    createEvent: builder.mutation<CreatedEventApiResponse, EventWizardFormSerializedValues & { communityId: number }>({
      query: (data) => ({
        url: '/event',
        method: 'POST',
        data: mapEventWizardFormValuesToApi(data),
      }),
      invalidatesTags: ['ALL_EVENTS', 'UPCOMING_EVENT'],
    }),
    updateEvent: builder.mutation<void, EventWizardFormSerializedValues & { relatedImageIds: number[] }>({
      query: ({ id, ...data }) => ({
        url: `/event/${id}`,
        method: 'PUT',
        data: mapEventWizardFormValuesToApi(data),
      }),
      invalidatesTags: ['ALL_EVENTS'],
    }),
    getEventQuestions: builder.query<
      EventQuestionDefinition[],
      WithEventId<{ assignmentType?: EventQuestionAssignmentType }>
    >({
      query: ({ id, assignmentType }) => ({
        url: `/event/${id}/questions`,
        params: { assignmentType },
      }),
      transformResponse: mapEventQuestionsFromApi,
      providesTags: ['EVENT_QUESTIONS'],
    }),
    createEventQuestions: builder.mutation<void, WithEventId<{ questions: CreateEventQuestionRequest[] }>>({
      query: ({ id, questions }) => ({
        url: `/event/${id}/questions`,
        method: 'POST',
        data: questions,
      }),
      invalidatesTags: ['EVENT_QUESTIONS'],
    }),
    createEventMeetingQuestions: builder.mutation<void, WithEventId<{ questions: CreateEventQuestionRequest[] }>>({
      query: ({ id, questions }) => ({
        url: `/event/${id}/meeting-questions`,
        method: 'POST',
        data: questions,
      }),
      invalidatesTags: ['EVENT_QUESTIONS'],
    }),
    updateEventAnswers: builder.mutation<void, WithEventId<EventAnswers>>({
      query: ({ id, answers, type }) => ({
        url: `/event/${id}/answers/${type}`,
        method: 'POST',
        data: answers,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'EVENT', id }],
    }),
    deleteEvent: builder.mutation<void, WithEventId>({
      query: ({ id }) => ({
        url: `/event/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => ['ALL_EVENTS', 'UPCOMING_EVENT', { type: 'EVENT', id }],
    }),
    registerForEvent: builder.mutation<void, WithEventId>({
      query: ({ id }) => ({
        url: `/event/${id}/register`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'EVENT', id }],
    }),
    startEvent: builder.mutation<void, WithEventId<StartNatteringParams>>({
      query: ({ id, ...params }) => ({
        url: `/meeting/${id}/start`,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: (_result, _error, { id }) => ['ALL_EVENTS', 'UPCOMING_EVENT', { type: 'EVENT', id }],
    }),
    stopEvent: builder.mutation<void, WithEventId>({
      query: ({ id }) => ({
        url: `/meeting/${id}/stop`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, { id }) => ['ALL_EVENTS', 'PAST_EVENTS', { type: 'EVENT', id }],
    }),
    downloadParticipantsSurveyData: builder.query<void, WithEventId>({
      query: ({ id }) => ({
        url: `/event/${id}/report/participants-survey-data`,
      }),
      transformResponse: (data: BlobPart, _meta, { id }) => {
        dataToCsvFile(data, `ParticipantsSurveyData-Event-${id}.csv`);
      },
    }),
    downloadConversationalData: builder.query<void, WithEventId>({
      query: ({ id }) => ({
        url: `/event/${id}/report/conversational-data`,
      }),
      transformResponse: (data: BlobPart, _meta, { id }) => {
        dataToCsvFile(data, `ConversationalData-Event-${id}.csv`);
      },
    }),
    startBroadcast: builder.mutation<void, WithEventId>({
      query: ({ id }) => ({
        url: `/meeting/${id}/start?withBroadcast=1`,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'EVENT', id }],
    }),
    postEventImage: builder.query<EventImageApiResponse, WithEventId<EventImageParams>>({
      forceRefetch: () => true,
      query: ({ id, ...params }) => ({
        url: `/event/${id}/images`,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'POST',
        data: generateFormData(omitUndefined(params)),
      }),
    }),
    getConnections: builder.query<EventGuest[], WithEventId>({
      query: ({ id }) => `/meeting/${id}/myConnection`,
      transformResponse: mapConnectionsFromApi,
    }),
    validateEventPin: builder.query<void, WithEventId<{ pin: string }>>({
      query: ({ id, pin }) => ({ url: `/event/${id}/validate-pin`, params: { pin } }),
    }),
    getTroubleshootingToken: builder.mutation<{ jwtToken: string }, void>({
      query: () => `/meeting/connectionTestToken`,
    }),
  }),
});

export const {
  useCreateEventMutation,
  useCreateEventQuestionsMutation,
  useCreateEventMeetingQuestionsMutation,
  useDeleteEventMutation,
  useGetConnectionsQuery,
  useGetEventDetailsQuery,
  useGetEventGuestsQuery,
  useGetEventQuestionsQuery,
  useGetEventsListQuery,
  useGetTroubleshootingTokenMutation,
  useLazyGetEventQuestionsQuery,
  useGetPublicEventDetailsQuery,
  useLazyValidateEventPinQuery,
  useRegisterForEventMutation,
  useStartBroadcastMutation,
  useStartEventMutation,
  useStopEventMutation,
  useUpdateEventAnswersMutation,
  useUpdateEventMutation,
} = eventApi;
