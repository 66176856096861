import { ElementType } from 'react';

import { Typography, TypographyProps, styled } from '@mui/material';

export const InlineEditorInactive = styled(Typography, {
  name: 'InlineEditorWrapper',
})<TypographyProps & { component: ElementType; disabled: boolean }>(({ theme: { spacing, palette }, disabled }) => ({
  width: '100%',
  border: '1px solid transparent',
  borderRadius: spacing(0.5),
  fontWeight: 500,
  padding: spacing(0.125, 1),
  cursor: disabled ? undefined : 'pointer',

  '&:hover': {
    borderColor: disabled ? undefined : palette.text.primary,
  },
}));
