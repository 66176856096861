import React, { FC } from 'react';

import { ButtonMenu, MenuItemType } from 'common/components';
import { useExportAsPNG } from 'common/hooks';

export const ChartMoreMenu: FC<{
  chartId: string;
  actions?: MenuItemType[];
}> = ({ chartId, actions = [] }) => {
  const { exportAsPNG, isLoading } = useExportAsPNG(chartId, `${chartId}_${new Date().toISOString()}`);

  return (
    <ButtonMenu
      variant="text"
      isLoading={isLoading}
      size="medium"
      items={[
        {
          text: 'Export as PNG',
          onClick: exportAsPNG,
        },

        ...actions,
      ]}
    />
  );
};
