import React, { FC, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { Loadable } from 'common/components';
import ErrorPage from 'pages/ErrorPage';
import { useJoinOrganizationMutation } from 'store/apis/masterAdmin';

const JoinOrganization: FC = () => {
  const [joinOrganization, { isLoading, isSuccess, isUninitialized, error }] = useJoinOrganizationMutation();
  const { organizationId } = useParams<{ organizationId: string }>();

  useEffect(() => {
    joinOrganization({
      organizationId: Number(organizationId),
    });
  }, [organizationId, joinOrganization]);

  return (
    <Loadable isLoading={isUninitialized || isLoading}>
      {isSuccess ? <Redirect to="/" /> : <ErrorPage title="Unable to join organization" error={error} />}
    </Loadable>
  );
};

export default JoinOrganization;
