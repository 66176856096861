import { type Components, type Theme } from '@mui/material';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.grey[500],
      minHeight: 'auto',
    }),
    flexContainer: ({ theme: { spacing } }) => ({
      gap: spacing(2),
    }),
    indicator: {
      height: 1,
    },
  },
};
