import React, { FC } from 'react';

import { CircularProgress, type IconButtonProps as MuiIconButtonProps } from '@mui/material';

import { mapButtonSizeToLoaderSize } from '../Button/utils/mapButtonSizeToLoaderSize';
import { LoadingIndicatorContainer, StyledIconButton } from './IconButton.styled';
import type { IconButtonVariant } from './types';

export type IconButtonProps = Pick<
  MuiIconButtonProps,
  'aria-label' | 'disabled' | 'size' | 'onClick' | 'sx' | 'className' | 'disableRipple'
> & {
  color?: 'primary' | 'secondary' | 'info' | 'error' | 'light';
  isLoading?: boolean;
  variant?: IconButtonVariant;
};

export const IconButton: FC<IconButtonProps> = ({
  children,
  color = 'primary',
  disabled,
  isLoading,
  size = 'medium',
  variant,
  disableRipple,
  ...buttonProps
}) => (
  <StyledIconButton
    color={color}
    disabled={isLoading || disabled}
    disableRipple={disableRipple ?? variant === 'text'}
    isLoading={isLoading}
    variant={variant}
    size={size}
    {...buttonProps}
  >
    {isLoading ? (
      <LoadingIndicatorContainer alignItems="center">
        <CircularProgress color="inherit" size={mapButtonSizeToLoaderSize(size)} />
      </LoadingIndicatorContainer>
    ) : (
      children
    )}
  </StyledIconButton>
);
