import { createListenerMiddleware } from '@reduxjs/toolkit';
import { P2PInsightsQuestionAssignmentType } from 'pages/P2PInsights/types/v2';
import { mapP2PInsightUserToEventRoomAttendee } from 'pages/P2PInsights/utils';
import type { RootState } from 'store';
import { p2pInsightApi } from 'store/apis/p2pInsight';

import { p2piMeetingEnded_WS } from './actions';
import { setupP2PIMeetingAttendees, setupP2PIMeetingQuestionAssignment } from './p2piSlice';

export const p2piListener = createListenerMiddleware<RootState>();

p2piListener.startListening({
  actionCreator: p2piMeetingEnded_WS,
  effect: (_, { dispatch }) => {
    dispatch(p2pInsightApi.util.invalidateTags(['MEETINGS']));
  },
});

p2piListener.startListening({
  matcher: p2pInsightApi.endpoints.getP2PInsightsMeetingById.matchFulfilled,
  effect: ({ payload }, { dispatch }) => {
    const attendees = payload.meeting.communityMembers.map(mapP2PInsightUserToEventRoomAttendee);
    dispatch(setupP2PIMeetingAttendees(attendees));
  },
});

p2piListener.startListening({
  matcher: p2pInsightApi.endpoints.getP2PInsightsMeetingById.matchFulfilled,
  effect: ({ payload }, { dispatch }) => {
    const topic = payload.questions.findLast(
      ({ questionAssignment }) => questionAssignment.assignmentType === P2PInsightsQuestionAssignmentType.Topic
    );
    if (!topic) return;
    dispatch(setupP2PIMeetingQuestionAssignment(topic.questionAssignment.questionAssignmentKey));
  },
});
