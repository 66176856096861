import { useCallback, useEffect } from 'react';

import { useApiRequestParams } from 'common/hooks';
import type { SortOrder, Tag } from 'domain/Common';
import type { UserRole } from 'domain/UserProfile';
import { GetCommunityMembersParams } from 'store/apis/community';

export const useMembersQuery = ({
  communityId,
  initialSortBy,
  initialPageSize,
  tags,
  showManagers,
}: {
  communityId?: number;
  initialSortBy?: string;
  initialPageSize: number;
  tags?: Tag[];
  showManagers?: boolean;
}) => {
  const {
    queryParams,
    setQueryParams,
    handleCurrentPageChange,
    handleOrderByChange,
    handleOrderChange,
    handlePageSizeChange,
    handleSearchPhraseChange,
    handleSortChange,
  } = useApiRequestParams({
    initialPageSize,
    initialSortBy,
    initialQueryParams: {
      communityId,
      pageSize: initialPageSize,
      page: 1,
      sortBy: initialSortBy ?? '',
      sortOrder: 'asc' as SortOrder,
      tags: [] as Tag[],
      roles: [] as UserRole[],
      searchPhrase: '',
      showManagers: false,
    } as GetCommunityMembersParams,
  });

  const handleShowManagersChange = useCallback(
    (value: boolean) => {
      setQueryParams((currentQueryParams) => ({
        ...currentQueryParams,
        showManagers: value,
        page: 1,
      }));
    },
    [setQueryParams]
  );

  const handleSelectedTagsChange = useCallback(
    (value: Tag[]) => {
      setQueryParams((currentQueryParams) => ({
        ...currentQueryParams,
        tags: value,
        page: 1,
      }));
    },
    [setQueryParams]
  );

  const handleSelectedRolesChange = useCallback(
    (value: UserRole[]) => {
      setQueryParams((currentQueryParams) => ({
        ...currentQueryParams,
        roles: value,
        page: 1,
      }));
    },
    [setQueryParams]
  );

  useEffect(() => {
    if (communityId === undefined) return;

    setQueryParams((currentQueryParams) => ({
      ...currentQueryParams,
      communityId,
      page: 1,
    }));
  }, [communityId, setQueryParams]);

  useEffect(() => {
    handleSelectedTagsChange(tags ?? []);
  }, [handleSelectedTagsChange, tags]);

  useEffect(() => {
    handleShowManagersChange(!!showManagers);
  }, [handleShowManagersChange, showManagers]);

  return {
    queryParams,
    handleShowManagersChange,
    handleSearchPhraseChange,
    handleSelectedTagsChange,
    handleSelectedRolesChange,
    handleOrderChange,
    handleOrderByChange,
    handleCurrentPageChange,
    handlePageSizeChange,
    handleSortChange,
  };
};
