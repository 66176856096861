import React, { FC, SVGProps } from 'react';

const RolesIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#prefix__clip0_7356_17757)" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
      <path d="M5.833 10h2.5v3.333h-2.5V10z" />
      <path d="M8.333 5h-5a.833.833 0 00-.833.833v10a.833.833 0 00.833.834h13.334a.833.833 0 00.833-.834v-10A.833.833 0 0016.667 5h-5" />
      <path d="M8.333 3.333a.833.833 0 01.834-.833h1.666a.833.833 0 01.834.833v2.5a.833.833 0 01-.834.834H9.167a.833.833 0 01-.834-.834v-2.5zM11.667 13.333h1.666M11.667 10H15" />
    </g>
    <defs>
      <clipPath id="prefix__clip0_7356_17757">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default RolesIcon;
