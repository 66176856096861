import React, { ComponentType, FC, useMemo, useState } from 'react';

import { ErrorBoundary } from 'common/components';
import {
  BarChart,
  BasicChartProps,
  DensityPlot,
  DonutChart,
  Histogram,
  HorizontalBarChart,
  HorizontalStackedBarChart,
  PieChart,
  VerticalStackedBarChart,
} from 'common/components/Charts';
import { uuid } from 'common/utils';

import {
  ChartCardChartWrapper,
  ChartCardHeader,
  ChartCardHeaderRightSection,
  ChartCardTitle,
  ChartCardWrapper,
} from './ChartCard.styled';
import { ChartErrorFallback, ChartMoreMenu, ChartSelect, ChartType } from './components';
import { useExportChartDataToCSV } from './components/ChartMoreMenu/hooks';

const chartsMap: Record<ChartType, ComponentType<BasicChartProps>> = {
  [ChartType.Bar]: BarChart,
  [ChartType.HorizontalBar]: HorizontalBarChart,
  [ChartType.VerticalStackedBar]: VerticalStackedBarChart,
  [ChartType.HorizontalStackedBar]: HorizontalStackedBarChart,
  [ChartType.Donut]: DonutChart,
  [ChartType.Pie]: PieChart,
  [ChartType.Histogram]: Histogram,
  [ChartType.DensityPlot]: DensityPlot,
};

export type ChartCardProps = {
  title: string;
  chartProps: BasicChartProps;
  availableChartTypes: ChartType[];
  hasError?: boolean;
};

export const ChartCard: FC<ChartCardProps> = ({
  title,
  chartProps,
  availableChartTypes,
  hasError = false,
  children,
}) => {
  const chartId = useMemo(uuid, []);
  const [selectedChartType, setSelectedChartType] = useState(availableChartTypes[0]);
  const SelectedChartComponent = chartsMap[selectedChartType];
  const displayChartSelect = availableChartTypes.length > 1;
  const { exportAsCSV } = useExportChartDataToCSV(chartProps.data);
  const actions = useMemo(
    () => [
      {
        text: 'Export as CSV',
        onClick: exportAsCSV,
      },
    ],
    [exportAsCSV]
  );

  return (
    <ChartCardWrapper>
      <ChartCardHeader>
        <ChartCardTitle title={title}>{title}</ChartCardTitle>
        <ChartCardHeaderRightSection>
          {displayChartSelect && (
            <ChartSelect
              availableChartTypes={availableChartTypes}
              value={selectedChartType}
              onChange={setSelectedChartType}
            />
          )}
          <ChartMoreMenu chartId={chartId} actions={actions} />
        </ChartCardHeaderRightSection>
      </ChartCardHeader>
      <ErrorBoundary fallback={<ChartErrorFallback />} hasError={hasError}>
        <ChartCardChartWrapper id={chartId}>
          <SelectedChartComponent {...chartProps}>{children}</SelectedChartComponent>
        </ChartCardChartWrapper>
      </ErrorBoundary>
    </ChartCardWrapper>
  );
};
