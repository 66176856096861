import React, { FC, MouseEvent, useState } from 'react';

import { Box, Stack, Typography, styled } from '@mui/material';
import RolesIcon from 'community/components/icons/RolesIcon';
import { UserRole } from 'domain/UserProfile';

import { FilterByRoleModal } from './components';

const StyledBadge = styled(Box)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.main),
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  minWidth: 20,
  height: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: theme.spacing(1),
  fontSize: '12px',
}));

export const FilterByRole: FC<{
  selectedRoles: UserRole[];
  onChange(roles: UserRole[]): void;
}> = ({ selectedRoles, onChange }) => {
  const [filtersAnchor, setFiltersAnchor] = useState<(EventTarget & HTMLDivElement) | null>(null);
  const handleFilterClick = (event: MouseEvent<HTMLDivElement>) =>
    setFiltersAnchor(filtersAnchor ? null : event.currentTarget);
  const handleFilterClose = () => setFiltersAnchor(null);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={({ palette }) => ({
          color: filtersAnchor ? palette.primary.main : undefined,
          cursor: 'pointer',
          '&:hover': {
            color: palette.primary.main,
          },
        })}
        onClick={handleFilterClick}
        data-testid="FilterByRole-button"
      >
        <RolesIcon fontSize="20px" />
        <Typography variant="bodyBold" marginLeft={1}>
          Filter by roles
        </Typography>
        {selectedRoles.length ? <StyledBadge>{selectedRoles.length}</StyledBadge> : null}
      </Stack>
      <FilterByRoleModal
        anchor={filtersAnchor}
        onClose={handleFilterClose}
        initialSelectedRoles={selectedRoles}
        onChange={onChange}
      />
    </>
  );
};
