import React, { FC } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Dialog, DialogActions, DialogContent, DialogContentText, Stack } from '@mui/material';
import { Button } from 'common/components/_legacy/Button';
import { DialogTitle } from 'common/components/_legacy/DialogTitle';

export interface ChromeWarningModalProps {
  shouldDisplay: boolean;
  onClose: () => void;
  isBrowserError: boolean;
}

export const ChromeWarningModal: FC<ChromeWarningModalProps> = ({ shouldDisplay, onClose, isBrowserError }) => (
  <Dialog open={shouldDisplay} maxWidth="xs" onClose={isBrowserError ? undefined : onClose}>
    <DialogTitle>We work best on Google Chrome</DialogTitle>
    <DialogContentText>
      {isBrowserError
        ? 'This browser is not supported yet. You will not be able to participate in this session using this browser. Use Google Chrome. If you are not able to install Chrome, join using Firefox or Edge.'
        : 'The browser you are using is not fully supported yet. To ensure a smooth experience, we recommend using Google Chrome'}
    </DialogContentText>
    <DialogContent>
      <Stack alignItems="center" spacing={1.5}>
        <LazyLoadImage
          alt="Google Chrome Logo"
          src="https://www.google.com/chrome/static/images/chrome-logo.svg"
          style={{ width: 100 }}
        />
        <a rel="noopener noreferrer" target="_blank" href="https://www.google.com/chrome">
          Download
        </a>
      </Stack>
    </DialogContent>
    <DialogActions>
      {!isBrowserError && (
        <Button variant="contained" color="primary" onClick={onClose}>
          Dismiss
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
