import { Palette } from '@mui/material';

export const mapColorForTextVariant = (palette: Palette, color?: string) => {
  switch (color) {
    case 'primary':
      return palette.primary.main;
    case 'info':
      return palette.info.contrastText;
    case 'error':
      return palette.error.main;
    default:
      return palette.secondary.contrastText;
  }
};

export const mapHoverColorForTextVariant = (palette: Palette, color?: string) => {
  switch (color) {
    case 'primary':
      return palette.primary.dark;
    case 'info':
      return palette.info.contrastText;
    case 'error':
      return palette.error.dark;
    default:
      return palette.secondary.contrastText;
  }
};

export const mapFocusColorForTextVariant = (palette: Palette, color?: string) => {
  switch (color) {
    case 'primary':
      return palette.extendedPrimary[700];
    case 'info':
      return palette.info.contrastText;
    case 'error':
      return palette.error.dark;
    default:
      return palette.secondary.contrastText;
  }
};

export const mapActiveColorForTextVariant = (palette: Palette, color?: string) => {
  switch (color) {
    case 'primary':
      return palette.extendedPrimary[25];
    case 'info':
      return palette.info.contrastText;
    case 'error':
      return palette.error.dark;
    default:
      return palette.secondary.contrastText;
  }
};

export const mapColorForOutlinedVariant = mapColorForTextVariant;

export const mapHoverColorForOutlinedVariant = mapActiveColorForTextVariant;

export const mapFocusColorForOutlinedVariant = (palette: Palette, color?: string) => {
  switch (color) {
    case 'primary':
      return palette.info.light;
    case 'info':
      return palette.info.contrastText;
    case 'error':
      return palette.error.dark;
    default:
      return palette.secondary.contrastText;
  }
};
