import React, { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import { Menu, MenuItemType } from 'common/components/Menu';

import { ChartSelectWrapper } from './ChartSelect.styled';

export enum ChartType {
  Bar = 'Bar Chart',
  HorizontalBar = 'Horizontal Bar Chart',
  VerticalStackedBar = 'Vertical Stacked Bar Chart',
  HorizontalStackedBar = 'Horizontal Stacked Bar Chart',
  Donut = 'Donut Chart',
  Pie = 'Pie Chart',
  Histogram = 'Histogram',
  DensityPlot = 'Density Plot',
}

export const ChartSelect: FC<{
  availableChartTypes: ChartType[];
  value: ChartType;
  onChange(chartType: ChartType): void;
}> = ({ value, availableChartTypes, onChange }) => {
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  const handleItemClick = useCallback(
    (chartType: ChartType) => () => {
      onChange(chartType);
    },
    [onChange]
  );

  const menuItems = useMemo(
    () =>
      availableChartTypes.map<MenuItemType>((chartType) => ({
        text: chartType,
        onClick: handleItemClick(chartType),
      })),
    [availableChartTypes, handleItemClick]
  );

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handleClose = () => setAnchor(null);

  return (
    <>
      <ChartSelectWrapper isActive={!!anchor} onClick={handleClick}>
        <Typography variant="natter-text-xs" fontWeight={500} whiteSpace="nowrap">
          {value}
        </Typography>
        <KeyboardArrowDownIcon fontSize="small" />
      </ChartSelectWrapper>
      <Menu
        anchor={anchor}
        onClose={handleClose}
        items={menuItems}
        activeIndex={availableChartTypes.indexOf(value)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
};
