import React, { FC } from 'react';

import { Menu as MuiMenu, PopoverOrigin, PopoverVirtualElement, SxProps, Theme } from '@mui/material';

import { MenuItem, type MenuItemProps } from './components';
import { MenuItemType } from './types';

type MenuProps = Pick<MenuItemProps, 'onClose'> & {
  anchor: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined;
  items: MenuItemType[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  sx?: SxProps<Theme>;
  activeIndex?: number;
};

const defaultAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const defaultTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export const Menu: FC<MenuProps> = ({
  anchor,
  onClose,
  items,
  anchorOrigin = defaultAnchorOrigin,
  transformOrigin = defaultTransformOrigin,
  sx,
  activeIndex,
}) => {
  const isOpen = Boolean(anchor);

  return (
    <MuiMenu
      sx={sx}
      open={isOpen}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClick={(e) => e.stopPropagation()}
      disableRestoreFocus={true}
    >
      {items.map((item, index) => (
        <MenuItem key={index} isActive={activeIndex === index} item={item} onClose={onClose} parentMenuOpen={isOpen} />
      ))}
    </MuiMenu>
  );
};
