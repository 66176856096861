import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import {
  AnyAction,
  Dispatch,
  ListenerEffect,
  PreloadedState,
  ThunkAction,
  ThunkDispatch,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import { configService } from 'modules/app/services';
import { keycloakService } from 'modules/keycloak/services';
import { eventBroadcastListener } from 'pages/EventBroadcast/state/eventBroadcast.listener';
import { eventQueueListener } from 'pages/EventQueue/state';
import { eventVideoCallListener } from 'pages/EventVideoCall/state';
import { eventWizardListener, eventWizardReducer } from 'pages/EventWizard/state';
import { insightsReportsWizardSlice } from 'pages/InsightsReportsWizard/state';
import { myEventsReducer } from 'pages/MyEvents/EventsList/state';
import { surveysWizardListener, surveysWizardReducer } from 'pages/SurveyWizard/state';
import { surveysListListener } from 'pages/Surveys/state';

import { attachmentsApi, attachmentsApiListener } from './apis/attachments';
import { coDashboardApi } from './apis/coDashboard/coDashboardApi';
import { communityApi, communityApiListener } from './apis/community';
import { activeEventListener, eventApi } from './apis/event';
import { eventsReportApi, eventsReportApiLister } from './apis/eventsReport';
import { insightsReportApi, insightsReportApiLister } from './apis/insightsReport';
import { masterAdminApi, masterAdminListener } from './apis/masterAdmin';
import { p2pInsightApi } from './apis/p2pInsight';
import { publicEventsReportApi, publicEventsReportApiLister } from './apis/publicEventsReport';
import { publicSurveysReportApi, publicSurveysReportApiLister } from './apis/publicSurveysReport';
import { surveyApi, surveyApiLister } from './apis/survey';
import { surveyReportApi, surveyReportApiLister } from './apis/surveyReport';
import { userApi, userApiListener } from './apis/user';
import { alertsSlice } from './features/alerts';
import { appSlice } from './features/app';
import { chatListener, chatSlice } from './features/chat';
import { communityListener, communitySlice } from './features/community';
import { eventDetailsListener, eventSlice, meetingListener } from './features/event';
import { insightsReportsSlice } from './features/insightsReports';
import { masterAdminSlice } from './features/masterAdmin';
import { p2pSlice, p2piListener } from './features/p2pi';
import { participantsSearchListener, participantsSearchSlice } from './features/participantsSearch';
import { questionsListener } from './features/questions';
import { questionSlice } from './features/questions/questionSlice';
import { reactionsListener, reactionsSlice } from './features/reactions';
import { roomsSearchListener, roomsSearchSlice } from './features/roomsSearch';
import { routerMiddleware, routerReducer } from './features/router';
import { Socket, socketMiddleware, socketSlice } from './features/socket';
import { userSlice } from './features/user';
import { userListener } from './features/user/listener';

const sentryReduxEnhancer = createReduxEnhancer();

export const rootReducer = combineReducers({
  router: routerReducer,
  [socketSlice.name]: socketSlice.reducer,
  [reactionsSlice.name]: reactionsSlice.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [insightsReportApi.reducerPath]: insightsReportApi.reducer,
  [surveyReportApi.reducerPath]: surveyReportApi.reducer,
  [publicSurveysReportApi.reducerPath]: publicSurveysReportApi.reducer,
  [eventsReportApi.reducerPath]: eventsReportApi.reducer,
  [publicEventsReportApi.reducerPath]: publicEventsReportApi.reducer,
  [masterAdminApi.reducerPath]: masterAdminApi.reducer,
  [p2pInsightApi.reducerPath]: p2pInsightApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [attachmentsApi.reducerPath]: attachmentsApi.reducer,
  [communityApi.reducerPath]: communityApi.reducer,
  [surveyApi.reducerPath]: surveyApi.reducer,
  [eventSlice.name]: eventSlice.reducer,
  [participantsSearchSlice.name]: participantsSearchSlice.reducer,
  [roomsSearchSlice.name]: roomsSearchSlice.reducer,
  [alertsSlice.name]: alertsSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [appSlice.name]: appSlice.reducer,
  [chatSlice.name]: chatSlice.reducer,
  [p2pSlice.name]: p2pSlice.reducer,
  [coDashboardApi.reducerPath]: coDashboardApi.reducer,
  [masterAdminSlice.name]: masterAdminSlice.reducer,
  [communitySlice.name]: communitySlice.reducer,
  [insightsReportsSlice.name]: insightsReportsSlice.reducer,
  /** Question Service */
  question: questionSlice,

  eventWizard: eventWizardReducer,
  [insightsReportsWizardSlice.name]: insightsReportsWizardSlice.reducer,
  surveyWizard: surveysWizardReducer,
  myEvents: myEventsReducer,
});

export const setupStore = (
  preloadedState?: PreloadedState<RootState>,
  socket = new Socket({ keycloakService, configService })
) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(meetingListener.middleware)
        .concat(activeEventListener.middleware)
        .concat(participantsSearchListener.middleware)
        .concat(roomsSearchListener.middleware)
        .concat(communityApiListener.middleware)
        .concat(eventApi.middleware)
        .concat(userApi.middleware)
        .concat(userApiListener.middleware)
        .concat(communityApi.middleware)
        .concat(routerMiddleware)
        .concat(p2pInsightApi.middleware)
        .concat(p2piListener.middleware)
        .concat(coDashboardApi.middleware)
        .concat(masterAdminApi.middleware)
        .concat(communityListener.middleware)
        .concat(masterAdminListener.middleware)
        .concat(questionsListener.middleware)
        .concat(reactionsListener.middleware)
        .concat(chatListener.middleware)
        .concat(insightsReportApi.middleware)
        .concat(surveyReportApi.middleware)
        .concat(publicSurveysReportApi.middleware)
        .concat(eventsReportApi.middleware)
        .concat(publicEventsReportApi.middleware)
        .concat(surveyApi.middleware)
        .concat(surveysWizardListener.middleware)
        .concat(eventWizardListener.middleware)
        .concat(eventDetailsListener.middleware)
        .concat(insightsReportApiLister.middleware)
        .concat(surveyApiLister.middleware)
        .concat(surveysListListener.middleware)
        .concat(eventQueueListener.middleware)
        .concat(eventVideoCallListener.middleware)
        .concat(eventBroadcastListener.middleware)
        .concat(userListener.middleware)
        .concat(eventsReportApiLister.middleware)
        .concat(publicEventsReportApiLister.middleware)
        .concat(surveyReportApiLister.middleware)
        .concat(publicSurveysReportApiLister.middleware)
        .concat(attachmentsApi.middleware)
        .concat(attachmentsApiListener.middleware)
        .concat(socketMiddleware(socket)),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers.concat(sentryReduxEnhancer),
  });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = typeof store.dispatch;

export type CombinedDispatch = Dispatch<AnyAction> & ThunkDispatch<RootState, unknown, AnyAction>;

export type Effect<Action extends AnyAction = AnyAction> = ListenerEffect<Action, RootState, CombinedDispatch>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
