import { mapValues, startCase, toLower } from 'lodash';
import { userRolesColors } from 'theme/palette';

/* eslint-disable @typescript-eslint/naming-convention */
export enum UserRoleName {
  CommunityMember = 'COMMUNITY_MEMBER',
  CommunityOwner = 'COMMUNITY_OWNER',
  CommunityManager = 'COMMUNITY_MANAGER',
  Custom = 'COMMUNITY_MEMBER_CUSTOM',
}

export interface UserRole {
  id: number;
  name: UserRoleName;
}

export const UserRoleDetails: Record<UserRoleName, { id: number; label: string }> = {
  [UserRoleName.CommunityOwner]: {
    id: 2,
    label: 'Community Owner',
  },
  [UserRoleName.CommunityManager]: {
    id: 3,
    label: 'Manager',
  },
  [UserRoleName.CommunityMember]: {
    id: 1,
    label: 'Member',
  },
  [UserRoleName.Custom]: {
    id: 4,
    label: 'Custom access',
  },
};

export const userRolesList: {
  id: number;
  label: string;
  key: UserRoleName;
}[] = Object.keys(UserRoleDetails).map((key) => ({
  key: key as UserRoleName,
  ...UserRoleDetails[key as UserRoleName],
}));

export const userRoleToLabelMap: Record<UserRoleName, string> = {
  [UserRoleName.CommunityManager]: 'Manager',
  [UserRoleName.CommunityMember]: 'Member',
  [UserRoleName.CommunityOwner]: 'Community Owner',
  [UserRoleName.Custom]: 'Custom access',
};

export const userRoleToColorMap: Record<UserRoleName, string> = {
  [UserRoleName.CommunityManager]: userRolesColors.communityManager,
  [UserRoleName.CommunityMember]: userRolesColors.communityMember,
  [UserRoleName.CommunityOwner]: userRolesColors.communityOwner,
  [UserRoleName.Custom]: userRolesColors.custom,
};

export enum UserPrivilegeName {
  EDIT_COMMUNITY_MANAGERS = 'EDIT_COMMUNITY_MANAGERS',
  MANAGE_COMMUNITY_MEMBERS = 'MANAGE_COMMUNITY_MEMBERS',
  VIEW_COMMUNITY_MEMBERS = 'VIEW_COMMUNITY_MEMBERS',
  VIEW_COMMUNITY_MEMBERS_TAGS = 'VIEW_COMMUNITY_MEMBERS_TAGS',
  VIEW_MY_CONNECTIONS = 'VIEW_MY_CONNECTIONS',
  VIEW_MY_EVENTS = 'VIEW_MY_EVENTS',
  CREATE_EVENT = 'CREATE_EVENT',
  MODIFY_EVENT = 'MODIFY_EVENT',
  MODIFY_COMMUNITY = 'MODIFY_COMMUNITY',
  VIEW_DASHBOARD = 'VIEW_DASHBOARD',
  VIEW_REPORTS = 'VIEW_REPORTS',
  MANAGE_REPORTS = 'MANAGE_REPORTS',
  CREATE_DASHBOARD = 'CREATE_DASHBOARD',
  MANAGE_P2P_INSIGHTS = 'MANAGE_P2P_INSIGHTS',
  VIEW_ALL_EVENTS_IN_COMMUNITY = 'VIEW_ALL_EVENTS_IN_COMMUNITY',
  VIEW_EVENT_RECORDINGS = 'VIEW_EVENT_RECORDINGS',
  MANAGE_SURVEYS = 'MANAGE_SURVEYS',
  VIEW_ALL_SURVEYS_IN_COMMUNITY = 'VIEW_ALL_SURVEYS_IN_COMMUNITY',
}

export type UserPrivilege = {
  id: number;
  name: UserPrivilegeName;
};

export const userPrivilegeLabels: Record<UserPrivilegeName, string> = mapValues(UserPrivilegeName, (p) =>
  startCase(toLower(p)).replace('P 2 P', 'P2P')
);

export const userPrivilegeIds: Record<UserPrivilegeName, number> = {
  [UserPrivilegeName.EDIT_COMMUNITY_MANAGERS]: 1,
  [UserPrivilegeName.MANAGE_COMMUNITY_MEMBERS]: 2,
  [UserPrivilegeName.VIEW_COMMUNITY_MEMBERS]: 3,
  [UserPrivilegeName.VIEW_COMMUNITY_MEMBERS_TAGS]: 4,
  [UserPrivilegeName.VIEW_MY_CONNECTIONS]: 5,
  [UserPrivilegeName.VIEW_MY_EVENTS]: 6,
  [UserPrivilegeName.CREATE_EVENT]: 7,
  [UserPrivilegeName.MODIFY_EVENT]: 8,
  [UserPrivilegeName.MODIFY_COMMUNITY]: 9,
  [UserPrivilegeName.VIEW_DASHBOARD]: 10,
  [UserPrivilegeName.VIEW_REPORTS]: 11,
  [UserPrivilegeName.CREATE_DASHBOARD]: 12,
  [UserPrivilegeName.MANAGE_P2P_INSIGHTS]: 13,
  [UserPrivilegeName.MANAGE_REPORTS]: 14,
  [UserPrivilegeName.VIEW_ALL_EVENTS_IN_COMMUNITY]: 15,
  [UserPrivilegeName.VIEW_EVENT_RECORDINGS]: 16,
  [UserPrivilegeName.MANAGE_SURVEYS]: 17,
  [UserPrivilegeName.VIEW_ALL_SURVEYS_IN_COMMUNITY]: 18,
};

type PrivilegeDetails = UserPrivilege & { key: UserPrivilegeName; label: string; disabled: boolean };

export const UserPrivilegeDetails: Record<UserPrivilegeName, PrivilegeDetails> = mapValues(UserPrivilegeName, (p) => ({
  id: userPrivilegeIds[p],
  name: p,
  key: p,
  label: userPrivilegeLabels[p],
  disabled: userPrivilegeIds[p] === 0,
}));

export const userPrivilegesList: PrivilegeDetails[] = Object.values(UserPrivilegeDetails);

export const getUserPrivilegeLabel = (privilegeName: UserPrivilegeName | string) => {
  const labelFound = userPrivilegeLabels[privilegeName as UserPrivilegeName];
  if (!labelFound) {
    console.error(`[getUserPrivilegeLabel] Unable to map privilege: ${privilegeName}`);
  }
  return labelFound ?? 'Unknown';
};
