import React, { FC, memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FilterItem, Filters, MultipleSelectInputOption } from 'common/components/ReactHookForm';
import { useInsightsReportContext } from 'pages/InsightsReport/hooks';
import { useAppDispatch } from 'store';
import { DemographicFiltersType, setDemographicFilters } from 'store/features/insightsReports';

import { DemographicFiltersToolbar } from './components';

export const DemographicFilters: FC = memo(() => {
  const dispatch = useAppDispatch();
  const { demographicData, shouldShowFilters, isLoading } = useInsightsReportContext();
  const filtersItems = useMemo(
    () =>
      demographicData?.map<FilterItem>((tagCategory) => ({
        label: tagCategory.tagCategoryType,
        name: tagCategory.categoryId.toString(),
        options: tagCategory.tags.map<MultipleSelectInputOption>(({ tagName, count, tagId }) => ({
          label: tagName,
          value: tagId,
          count,
        })),
      })) ?? [],
    [demographicData]
  );
  const methods = useForm({
    defaultValues: {},
  });
  const values = methods.watch();

  useEffect(() => {
    if (!filtersItems) return;

    methods.reset(
      filtersItems.reduce((prev, current) => {
        prev[current.name] = [];
        return prev;
      }, {} as DemographicFiltersType)
    );
  }, [methods, filtersItems]);

  useEffect(() => {
    dispatch(setDemographicFilters(values));
  }, [dispatch, values]);

  return (
    <DemographicFiltersToolbar open={shouldShowFilters}>
      <FormProvider {...methods}>
        <Filters items={filtersItems} isLoading={isLoading} />
      </FormProvider>
    </DemographicFiltersToolbar>
  );
});
