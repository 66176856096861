import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Button } from 'common/components/_legacy/Button';
import { DialogTitle } from 'common/components/_legacy/DialogTitle';
import { TextInput } from 'common/components/_legacy/Form';
import { useAppSelector } from 'store';
import {
  selectCurrentCommunityMember,
  selectMyCommunityId,
  useUpdateCurrentCommunityMemberMutation,
} from 'store/apis/community';
import { object, string } from 'yup';

const schema = object({
  role: string()
    .min(2, 'The role must be between 2 and 50 characters long')
    .max(50, 'The role must be between 2 and 50 characters long'),
});

const MemberJobTitleDialog: FC<{
  open: boolean;
  onClose(): void;
}> = ({ open, onClose }) => {
  const communityMember = useAppSelector(selectCurrentCommunityMember);
  const communityId = useAppSelector(selectMyCommunityId);
  const [updateCurrentCommunityMember, { isLoading, isSuccess }] = useUpdateCurrentCommunityMemberMutation();
  const methods = useForm({ resolver: yupResolver(schema), mode: 'onChange' });
  const { reset } = methods;

  useEffect(() => {
    if (communityMember) {
      reset({ role: communityMember.role });
    }
  }, [reset, communityMember]);

  const handleSubmit = methods.handleSubmit((data) => {
    updateCurrentCommunityMember({
      // @ts-expect-error add check that communityId is not undefined
      communityId,
      data,
      currentData: communityMember,
    });
  });

  const closeDialog = useCallback(() => {
    reset({ role: communityMember?.role ?? '' });
    onClose();
  }, [reset, onClose, communityMember?.role]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth disableRestoreFocus>
      <FormProvider {...methods}>
        <DialogTitle onClose={closeDialog}>Edit Role</DialogTitle>
        <DialogContentText>This is the title people will see when attending Events or natters.</DialogContentText>
        <DialogContent>
          <TextInput name="role" label="Role" placeholder="Role" />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit} isLoading={isLoading}>
            Confirm
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default MemberJobTitleDialog;
