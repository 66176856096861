import React, { FC } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButtonWithTooltip } from 'common/components';

type Props = {
  onClick(): void;
};

export const EventDeleteButton: FC<Props> = ({ onClick }) => (
  <IconButtonWithTooltip
    title="Delete Event"
    size="medium"
    color="secondary"
    onClick={onClick}
    aria-label="Delete Event"
  >
    <DeleteIcon fontSize="small" />
  </IconButtonWithTooltip>
);
