import React, { FC } from 'react';

import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { Button } from 'common/components/_legacy/Button';
import { ErrorBox } from 'common/components/_legacy/ErrorBox';
import { TopBar } from 'layout/Dashboard/components';
import { useAppSelector } from 'store';
import { selectMyCommunity } from 'store/apis/community';

interface ErrorPageProps {
  title?: string;
  status?: string;
  message?: string;
  error?: unknown;
}

const ErrorPage: FC<ErrorPageProps> = ({ title, status, message, error }) => {
  const myCommunity = useAppSelector(selectMyCommunity);

  return (
    <>
      <TopBar center community={myCommunity} />
      <Box display="flex" width={1} height={1} alignItems="center" justifyContent="center">
        <Container maxWidth="sm">
          <Stack alignItems="center" gap={3}>
            {status && (
              <Typography variant="h1" fontSize={38}>
                {status}
              </Typography>
            )}
            <Typography variant="h1" fontSize={38} textAlign="center">
              {title ?? (
                <>
                  Oops!
                  <br />
                  Something went wrong
                </>
              )}
            </Typography>
            {typeof message === 'string' && <Typography variant="h6">{message}</Typography>}
            {error ? <ErrorBox error={error} /> : <Typography>Looks like there was some unexpected error.</Typography>}
            <Typography>
              Refresh the page or contact the Support Team{' '}
              <Link underline="hover" href="https://intercom.help/natter/en/" target="_blank" rel="noreferrer">
                here
              </Link>
              .
            </Typography>
            <Button href="/">Go back to Natter</Button>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default ErrorPage;
