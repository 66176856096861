import { UserRoleName, userPrivilegesList, userRolesList } from 'domain/UserProfile';

type UserBeforeMapping = {
  email?: string;
  role?: string;
  privileges?: string;
};

export type UserWithRole = {
  communityRole: UserRoleName | '';
  email?: string;
  privileges: string[];
};

export const mapUsersWithRoles = (users: UserBeforeMapping[]): UserWithRole[] =>
  users.map((user) => ({
    email: user.email?.trim(),
    communityRole: userRolesList.some((role) => role.key === user.role?.trim())
      ? (user.role?.trim() as UserRoleName)
      : '',
    privileges:
      user.privileges
        ?.split?.(',')
        .map((privilege: string) => privilege.trim())
        .filter((privilege: string) => userPrivilegesList.some((privilegeItem) => privilegeItem.key === privilege)) ??
      [],
  }));

export const getUsersFromData = ({ data, expectedHeaders }: { data: string[][]; expectedHeaders: string[] }) => {
  const indexesOfInterest: { field: string; index: number }[] = [];
  const header = data.splice(0, 1)[0].filter((h) => h);
  header.forEach((headerItem, index) => {
    expectedHeaders.forEach((field: string) => {
      if (field.toLowerCase().includes(headerItem.toLowerCase())) {
        indexesOfInterest.push({
          index,
          field,
        });
      }
    });
  });

  const getGuest = (guest: string[]) => {
    const guestObject: Record<string, unknown> = {};
    indexesOfInterest.forEach((indexOfInterest) => {
      guestObject[indexOfInterest.field] = guest[indexOfInterest.index];
    });
    return guestObject;
  };

  return data.map(getGuest);
};
