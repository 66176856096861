import React, { FC, useMemo } from 'react';

import { AnswerStatistics, isTextAnswerStatistics } from 'store/apis/insightsReport';

import { ChartCard, ThemesChartCard } from '../ChartCard';
import { ReportCard } from '../layout';
import { mapAnswerStatisticsToChartCardProps } from './chartsMapper';

export const AnswerStatisticsCard: FC<{
  answerStatistics: AnswerStatistics;
}> = ({ answerStatistics }) => {
  const { title } = answerStatistics;
  const {
    availableChartTypes,
    chartProps,
    title: chartTitle,
  } = useMemo(() => mapAnswerStatisticsToChartCardProps(answerStatistics), [answerStatistics]);

  return (
    <ReportCard title={title}>
      {isTextAnswerStatistics(answerStatistics) ? (
        <ThemesChartCard title={chartTitle} chartProps={chartProps} themes={answerStatistics.themes} />
      ) : (
        <ChartCard title={chartTitle} chartProps={chartProps} availableChartTypes={availableChartTypes} />
      )}
    </ReportCard>
  );
};
