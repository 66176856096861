import { QuestionAssignmentDefinition, QuestionDefinition } from 'domain/question';

export enum P2PInsightsQuestionAssignmentType {
  BeforeMeeting = 'BEFORE_MEETING',
  AfterMeeting = 'AFTER_MEETING',
  Topic = 'TOPIC',
}

export type P2PIQuestionAssignment = QuestionAssignmentDefinition<P2PInsightsQuestionAssignmentType> & {
  p2pInsightsMeetingId: number;
};
export type P2PIQuestionDefinition = QuestionDefinition<P2PIQuestionAssignment>;
