import React, { FC } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { MultipleSelectInput, MultipleSelectInputOption } from 'common/components/ReactHookForm/MultipleSelectInput';

import { ResetFiltersButton } from './ResetFiltersButton';

export type FilterItem = {
  name: string;
  label: string;
  options: MultipleSelectInputOption[];
};

export const Filters: FC<{
  items: FilterItem[];
  isLoading?: boolean;
}> = ({ items, isLoading }) => (
  <Stack direction="row" gap={1} flexWrap="wrap" width="100%" alignItems="center">
    <Stack direction="row" alignItems="center" color="grey.600" gap={0.5} pr={1}>
      <FilterListIcon />
      <Typography variant="natter-text-sm" fontWeight={500}>
        Filter by:
      </Typography>
    </Stack>
    {isLoading ? (
      <Skeleton variant="rectangular" width={200} height={32} sx={{ borderRadius: 2 }} />
    ) : (
      items.map(({ name, label, options }) => (
        <Box key={name}>
          <MultipleSelectInput name={name} label={label} options={options} fullWidth />
        </Box>
      ))
    )}
    <ResetFiltersButton />
  </Stack>
);
