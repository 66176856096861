import React, { FC, useMemo } from 'react';

import { Tooltip } from 'common/components';
import { Community } from 'domain/Community';
import { head, take, upperCase, words } from 'lodash';

import { Logo, LogoProps } from '../Logo';
import { CommunityLogoAvatar } from './CommunityLogo.styled';

const getCommunityInitials = (name: string, count: number) =>
  take(
    words(name).map((word) => upperCase(head(word))),
    count
  ).join('');

type CommunityLogoProps = Pick<LogoProps, 'variant' | 'size'> & {
  community?: Community | null;
  centered?: boolean;
};

export const CommunityLogo: FC<CommunityLogoProps> = ({ community, centered, variant, size }) => {
  const logoLink = community?.logoImageLinkSmall ?? community?.logoImageLink;
  const communityInitials = useMemo(
    () => (community?.name ? getCommunityInitials(community.name, 3) : undefined),
    [community?.name]
  );

  return !logoLink && community?.name ? (
    <Tooltip title={community?.name}>
      <CommunityLogoAvatar brandingColor={community?.brandingColor} variant="rounded" size={size}>
        {communityInitials}
      </CommunityLogoAvatar>
    </Tooltip>
  ) : (
    <Logo logoImageLink={logoLink} centered={centered} variant={variant} size={size} />
  );
};
