import { useMemo } from 'react';

import { Tag, TagsCategory } from 'domain/Common';
import { mapTagsFromCategories } from 'mappers/account';
import { useAppSelector } from 'store';
import { selectCurrentCommunityMemberTags, selectMyCommunityTagsCategories } from 'store/apis/community';

export const useUserTagsCategories = (): {
  userTagsCategories: TagsCategory[];
  tags: Tag[];
} => {
  const tagsCategories = useAppSelector(selectMyCommunityTagsCategories);
  const userTags = useAppSelector(selectCurrentCommunityMemberTags);

  const userTagsCategories = useMemo(
    () =>
      tagsCategories
        .filter((category) => !!category.tags.length)
        .map((c) => ({
          ...c,
          tags: c.tags.map((tag) => ({
            ...tag,
            isSelected: !!userTags.find((ut) => ut.id === c.id)?.tags?.some((t) => t.id === tag.id),
          })),
        })),
    [tagsCategories, userTags]
  );

  const tags = useMemo(() => mapTagsFromCategories(userTags), [userTags]);

  return {
    userTagsCategories,
    tags,
  };
};
