import { createListenerMiddleware } from '@reduxjs/toolkit';
import { logger } from 'common/services';
import { isError } from 'common/utils';
import type { RootState } from 'store';
import { addAppErrorMessage } from 'store/features/alerts';
import { selectActiveRoomDetails } from 'store/features/event';

import { MEDIA_ERRORS } from '../hooks/useTwilio/constants';
import { TWILIO_SENTRY_TAGS } from './constants';
import { eventVideoCallActions } from './eventVideoCall.actions';

export const eventVideoCallListener = createListenerMiddleware<RootState>();

eventVideoCallListener.startListening({
  actionCreator: eventVideoCallActions.disconnectedFromRoom,
  effect: ({ payload: { room, error } }, { getState }) => {
    if (!error) {
      return;
    }

    const activeRoomDetails = selectActiveRoomDetails(getState());

    logger.error(error, {
      message: '[Twilio] Disconnected from room',
      tags: TWILIO_SENTRY_TAGS,
      extra: { room, activeRoomDetails },
    });
  },
});

eventVideoCallListener.startListening({
  actionCreator: eventVideoCallActions.participant.trackWarningEmitted,
  effect: ({ payload: { name, publication } }) => {
    logger.warning('[Twilio] Track warning', {
      tags: TWILIO_SENTRY_TAGS,
      extra: { name, publication },
    });
  },
});

// https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices#handling-errors
eventVideoCallListener.startListening({
  actionCreator: eventVideoCallActions.connectToRoom.failed,
  effect: ({ payload: { error } }, { getState }) => {
    const activeRoomDetails = selectActiveRoomDetails(getState());

    const extra = { activeRoomDetails };

    if (!isError(error)) {
      logger.error(error, {
        message: '[Twilio] Unexpected error',
        tags: [TWILIO_SENTRY_TAGS],
        extra,
      });
      return;
    }

    if (MEDIA_ERRORS.includes(error.name)) {
      logger.error(error, {
        message: '[Twilio] Failed to acquire media',
        tags: TWILIO_SENTRY_TAGS,
        extra,
      });
      return;
    }

    if ('code' in error) {
      logger.error(error, {
        message: '[Twilio] Failed to join room',
        tags: TWILIO_SENTRY_TAGS,
        extra,
      });
      return;
    }

    logger.error(error, {
      message: '[Twilio] Unknown error',
      tags: TWILIO_SENTRY_TAGS,
      extra,
    });
  },
});

eventVideoCallListener.startListening({
  actionCreator: eventVideoCallActions.localAudioTrack.create.failed,
  effect: ({ payload: { error } }, { dispatch, getState }) => {
    const activeRoomDetails = selectActiveRoomDetails(getState());

    if (isError(error)) {
      dispatch(
        addAppErrorMessage({
          metadata: 'useTwilio.useEffect.activeAudioInputDevice',
          message: error.message,
        })
      );
    }

    logger.error(error, {
      message: '[Twilio] Failed to create local audio track',
      tags: TWILIO_SENTRY_TAGS,
      extra: { activeRoomDetails },
    });
  },
});

eventVideoCallListener.startListening({
  actionCreator: eventVideoCallActions.localVideoTrack.create.failed,
  effect: ({ payload: { error } }, { dispatch, getState }) => {
    const activeRoomDetails = selectActiveRoomDetails(getState());

    if (isError(error)) {
      dispatch(
        addAppErrorMessage({
          metadata: 'useTwilio.useEffect.activeVideoDevice',
          message: error.message,
        })
      );
    }

    logger.error(error, {
      message: '[Twilio] Failed to create local audio track',
      tags: TWILIO_SENTRY_TAGS,
      extra: { activeRoomDetails },
    });
  },
});

eventVideoCallListener.startListening({
  actionCreator: eventVideoCallActions.connecionTimedOut,
  effect: ({ payload: { participants, attendees, disconnectedAttendees } }, { getState }) => {
    const activeRoomDetails = selectActiveRoomDetails(getState());

    logger.error('[EventVideoCall] Unable to join conversation', {
      tags: TWILIO_SENTRY_TAGS,
      extra: { participants, attendees, disconnectedAttendees, activeRoomDetails },
    });
  },
});
