import React, { FC } from 'react';

import { InlineEditor } from 'common/components/ReactHookForm';
import { useInsightsReportContext } from 'pages/InsightsReport/hooks';

import { schema } from './schema';

export const ReportNameContainer: FC = () => {
  const { reportData, updateReportName, isPublicReport } = useInsightsReportContext();
  const { name } = reportData ?? {};

  return (
    <InlineEditor
      typographyVariant="natter-text-xl"
      schema={schema}
      defaultValue={name}
      onSubmit={updateReportName}
      component="h1"
      noWrap
      disabled={isPublicReport}
    />
  );
};
