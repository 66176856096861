import { Avatar, styled } from '@mui/material';

import { LogoProps } from '../Logo';

export const CommunityLogoAvatar = styled(Avatar, {
  name: 'CommunityLogoAvatar',
  shouldForwardProp: (prop) => prop !== 'brandingColor',
})<Pick<LogoProps, 'size'> & { brandingColor?: string }>(({ theme: { palette }, brandingColor, size }) => {
  const color = brandingColor ?? palette.primary.main;
  return {
    backgroundColor: color,
    fontWeight: 600,
    color: palette.getContrastText(color),
    width: 56,
    height: 56,
    ...(size === 'small' && {
      width: 24,
      height: 24,
    }),
  };
});
