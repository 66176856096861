import { createSelector } from '@reduxjs/toolkit';
import { OrganizationMemberRole } from 'domain/Organization';
import type { RootState } from 'store';

import { masterAdminApi } from './masterAdminApi';

export const selectCurrentOrganization = (state: RootState) =>
  masterAdminApi.endpoints.getManagedOrganizations.select()(state)?.data?.organizations?.[0];

export const selectCurrentOrganizationId = createSelector(
  [selectCurrentOrganization],
  (data) => data?.organization?.organizationId
);

export const selectCurrentOrganizationSlug = createSelector(
  [selectCurrentOrganization],
  (data) => data?.organization.organizationSlug
);

export const selectIsUserCurrentOrganizationAdmin = createSelector(
  [selectCurrentOrganization],
  (data) => data?.organizationRole === OrganizationMemberRole.ADMIN
);
