import { createAction } from '@reduxjs/toolkit';
import { P2PInsightWSMessage, P2PInsightWSMessages } from 'common/constants';
import { SocketRoomPrefix } from 'store/features/socket';

type P2PInsightWSMessageAction = `${typeof SocketRoomPrefix.P2PInsight}:${P2PInsightWSMessage}`;

/**
 * Factory function for creating WebSocket message actions related to **P2PInsight**.
 *
 * @template P - The payload type of the WebSocket message action (default is undefined).
 *
 * @param {P2PInsightWSMessage} message - The specific action or event identifier to create a WebSocket message for.
 *
 * @returns {string} A WebSocket message action identifier generated by concatenating the 'Meetings' namespace
 *                 with the provided action string.
 */
export const createP2PInsightWSMessage = <P = undefined>(message: P2PInsightWSMessage) => {
  if (!Object.values(P2PInsightWSMessages).includes(message))
    throw Error(`Message '${message}' is not supported by P2PInsight WS`);

  return createAction<P, P2PInsightWSMessageAction>(`${SocketRoomPrefix.P2PInsight}:${message}`);
};
