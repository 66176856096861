import { FormEventQuestion } from 'domain/event';
import { AnswerTypes, CreateQuestionVariants } from 'domain/question';
import { AnswerTypesToQuestionVariant } from 'mappers/questions';

import { CreateQuestionRequest, P2PInsightApiQuestion } from '../../types';
import { P2PIQuestionDefinition, P2PInsightsQuestionAssignmentType } from '../../types/v2';

export const mapP2PInsightQuestion = (
  question: FormEventQuestion,
  assignmentType: P2PInsightsQuestionAssignmentType
) => ({
  question: {
    title: question.question,
    type: AnswerTypesToQuestionVariant[question.format] ?? CreateQuestionVariants.TextQuestion,
    ...(question.format === AnswerTypes.SingleChoice &&
      question.answers && {
        choices: question.answers.map((answer) => answer.value),
      }),
  },
  isObligatory: !!question.mandatory,
  assignmentType,
});

export const groupP2PInsightQuestionsByAssignmentType = (questions: P2PIQuestionDefinition[]) => {
  const assignmentTypeToQuestions = {
    [P2PInsightsQuestionAssignmentType.BeforeMeeting]: 'preEventQuestions',
    [P2PInsightsQuestionAssignmentType.AfterMeeting]: 'finalFeedbackQuestions',
    [P2PInsightsQuestionAssignmentType.Topic]: 'topics',
  } as const;

  return questions.reduce(
    (memo, question) => {
      memo[assignmentTypeToQuestions[question.questionAssignment.assignmentType]].push(question);
      return memo;
    },
    {
      preEventQuestions: [] as P2PIQuestionDefinition[],
      finalFeedbackQuestions: [] as P2PIQuestionDefinition[],
      topics: [] as P2PIQuestionDefinition[],
    }
  );
};

export const mapP2PApiQuestionToFormEventQuestion = (question: P2PInsightApiQuestion): FormEventQuestion => ({
  format: question.answerType,
  question: question.definition,
  questionType: question.questionType,
  answerLibraryType: question.answerLibraryType ?? undefined,
  answers: question.answerOptions.map((a) => ({
    value: a.content,
  })),
  id: question.id,
  mandatory: question.obligatory,
  answerLibrary: !!question.answerLibraryType,
});

export const mapFormQuestionToCreateQuestionRequest = (question: FormEventQuestion): CreateQuestionRequest => ({
  answerType: question.format,
  answerOptions: question.answers?.map((answer) => answer.value) ?? [],
  question: question.question,
  questionType: question.questionType,
  order: 1,
  obligatory: !!question.mandatory,
  answerLibraryType: question.answerLibraryType ?? null,
});
