import React, { FC } from 'react';

import { useEventRoutes } from 'event/hooks';
import { useAppSelector } from 'store';
import { selectActiveEventId } from 'store/features/event';

import { EventEditButton } from './EventEditButton';

export const EventEditButtonContainer: FC = () => {
  const eventId = useAppSelector(selectActiveEventId);
  const { goToEditEvent } = useEventRoutes();

  const handleEdit = () => {
    if (!eventId) return;

    goToEditEvent({ eventId });
  };

  return <EventEditButton onEdit={handleEdit} />;
};
