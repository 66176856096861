export const P2PInsightWSChannels = {
  public: '/p2pi/meetings/:meetingId',
  private: '/user/p2pi/meetings/:meetingId',
} as const;

export const P2PInsightWSMessages = {
  /** Message is sent via WS but not handled on FE */
  UserJoinedMeeting: 'user_joined_meeting',
  JoinedMeeting: 'joined_meeting',
  TopicResponseAssignedToAllUsers: 'topic_response_assigned_to_all_users',
  MeetingEnded: 'meeting_ended',
} as const;

export type P2PInsightWSMessage = (typeof P2PInsightWSMessages)[keyof typeof P2PInsightWSMessages];

const P2PInsightWSPath = '/p2pi/:p2pInsightsId/meetings/:meetingId' as const;

export const P2PInsightWSActions = {
  Join: `${P2PInsightWSPath}/join`,
  SubmitFeedback: `${P2PInsightWSPath}/submit_feedback`, // empty answers list
  End: `${P2PInsightWSPath}/end`,
  ConnectionError: `${P2PInsightWSPath}/connection_error`,
} as const;

export type P2PInsightWSAction = (typeof P2PInsightWSActions)[keyof typeof P2PInsightWSActions];
