import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { type TypographyProps } from '@mui/material';
import { type InferType, type StringSchema, object } from 'yup';

import { StyledForm, StyledTextField } from './InlineEditorInput.styled';

type Props<S extends StringSchema> = {
  typographyVariant: TypographyProps['variant'];
  name?: string;
  schema: S;
  defaultValue: InferType<S>;
  placeholder?: string;
  onSubmit?(value: InferType<S>): void;
  onCancel?(): void;
};

export const InlineEditorInput: FC<Props<StringSchema>> = ({
  typographyVariant,
  name = 'name',
  schema,
  defaultValue,
  placeholder,
  onSubmit: handleSubmit,
  onCancel,
}) => {
  const methods = useForm({
    resolver: yupResolver(object({ [name]: schema })),
  });

  const onSubmit = methods.handleSubmit(
    (data) => {
      const value = data[name];
      if (value === defaultValue) {
        onCancel?.();
        return;
      }

      handleSubmit?.(value);
    },
    (errors) => console.error('InlineEditorInput validation', errors)
  );

  return (
    <StyledForm onSubmit={onSubmit}>
      <StyledTextField
        {...methods.register(name, { value: defaultValue })}
        typographyVariant={typographyVariant}
        placeholder={defaultValue || placeholder}
        onBlur={onSubmit}
        autoFocus
        fullWidth
        error={!!methods.formState.errors?.name}
      />
    </StyledForm>
  );
};
