import React, { FC, useMemo } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { useAppSelector } from 'store';
import { selectIsDarkTheme, selectThemeColor } from 'store/features/app';
import { theme } from 'theme';

type ThemeProps = {
  color?: string;
  darkTheme?: boolean;
  omitBaseline?: boolean;
};

const PublicTheme: FC<ThemeProps> = ({ children, color, darkTheme: localDarkTheme, omitBaseline = false }) => {
  const themeColor = useAppSelector(selectThemeColor);
  const globalDarkTheme = useAppSelector(selectIsDarkTheme);
  const darkTheme = localDarkTheme ?? globalDarkTheme;

  const natterTheme = useMemo(
    () => theme(color ?? themeColor, darkTheme ? 'dark' : 'light'),
    [color, darkTheme, themeColor]
  );

  return (
    <ThemeProvider theme={natterTheme}>
      {!omitBaseline && <CssBaseline />}
      {children}
    </ThemeProvider>
  );
};

export default PublicTheme;
