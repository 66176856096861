import React, { FC, useEffect } from 'react';

import { StackProps } from '@mui/material';
import { withTheme } from 'common/hocs';
import { useBreakpoints } from 'common/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from 'store';
import {
  AlertContext,
  removeAlertsMatchingContext,
  selectLastDialogAlertByContext,
  selectSnackBarAlertsByContext,
} from 'store/features/alerts';

import { AlertsWrapper } from './Alerts.styled';
import { AlertDialogContainer, SnackbarContainer } from './components';

type Props = Pick<StackProps, 'sx'> & {
  context: AlertContext;
};

const Alerts: FC<Props> = ({ sx, context }) => {
  const dispatch = useAppDispatch();
  const snackbarAlerts = useAppSelector((state) => selectSnackBarAlertsByContext(state, context));
  const lastDialogAlert = useAppSelector((state) => selectLastDialogAlertByContext(state, context));
  const { isMobile } = useBreakpoints();

  useEffect(
    () => () => {
      dispatch(removeAlertsMatchingContext(context));
    },
    [dispatch, context]
  );

  return (
    <>
      {lastDialogAlert && <AlertDialogContainer {...lastDialogAlert} />}

      <AlertsWrapper data-testid="AlertsContainer-snackbarAlerts" sx={sx}>
        <AnimatePresence initial={false}>
          {snackbarAlerts?.map((alert) => (
            <motion.div
              key={alert.id}
              initial={{ opacity: 0, y: isMobile ? -50 : 50, scale: 0.3 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            >
              <SnackbarContainer alertData={alert} />
            </motion.div>
          ))}
        </AnimatePresence>
      </AlertsWrapper>
    </>
  );
};

export const AlertsContainer = withTheme(Alerts);
