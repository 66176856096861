import { type Components, type Theme } from '@mui/material';

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme: { spacing, typography } }) => ({
      ...typography['natter-text-sm'],
      fontWeight: 600,

      minHeight: 'auto',
      minWidth: 'auto',
      padding: spacing(1, 0),
      textTransform: 'none',
    }),
  },
};
