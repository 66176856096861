import React, { FC, useState } from 'react';

import { Box, Button, Stack, Typography } from '@mui/material';
import { Tags } from 'common/components';
import { useUserTagsCategories } from 'community/hooks';
import MemberJobTitleDialog from 'layout/AppNavbar/components/MemberJobTitleDialog';
import { MemberTagsDialog } from 'layout/AppNavbar/components/MemberTagsDialog';

export const SidebarTags: FC = () => {
  const [isTagsDialogOpen, setTagsDialogOpen] = useState(false);
  const [isRoleDialogOpen, setRoleDialogOpen] = useState(false);
  const { tags } = useUserTagsCategories();

  return (
    <Box>
      <Typography variant="h4" fontWeight={600}>
        My tags
      </Typography>
      <Box pt={1} pb={0.5}>
        <Tags tags={tags.map((t) => t.name)} />
      </Box>

      <Stack alignItems="start" gap={1}>
        <Button variant="link" onClick={() => setTagsDialogOpen(true)}>
          Edit Tags
        </Button>
        <MemberTagsDialog open={isTagsDialogOpen} handleClose={() => setTagsDialogOpen(false)} />

        <Button variant="link" onClick={() => setRoleDialogOpen(true)}>
          Edit Role
        </Button>
        <MemberJobTitleDialog open={isRoleDialogOpen} onClose={() => setRoleDialogOpen(false)} />
      </Stack>
    </Box>
  );
};
