import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Stack, Typography, TypographyProps, styled } from '@mui/material';

export const EventTitle = styled(Typography)<TypographyProps>(({ theme: { breakpoints } }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
  fontWeight: 600,
  [breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

EventTitle.defaultProps = {
  variant: 'natter-display-sm',
  component: 'h1',
};

export const EventImage = styled(LazyLoadImage)(({ theme: { palette } }) => ({
  width: 88,
  height: 88,
  borderRadius: '50%',
  border: '1px solid',
  borderColor: palette.grey[300],
}));

export const EventTimeWrapper = styled(Stack)(({ theme: { breakpoints, spacing } }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  gap: spacing(1),
  flexWrap: 'wrap',
  width: '100%',
  [breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const HeaderWrapper = styled(Stack)(({ theme: { breakpoints, spacing } }) => ({
  alignItems: 'start',
  gap: spacing(1),
  [breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

export const HeaderBottomSection = styled(Stack)(({ theme: { breakpoints, spacing } }) => ({
  flexDirection: 'row',
  gap: spacing(8),
  justifyContent: 'space-between',
  alignItems: 'start',
  width: '100%',
  [breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

export const HeaderBottomSectionContent = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(1),
  minWidth: 0,
  width: '100%',
}));

export const OrganizatorLabel = styled(Typography)(({ theme: { breakpoints } }) => ({
  [breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

OrganizatorLabel.defaultProps = {
  variant: 'natter-text-md',
};
