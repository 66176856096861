import React, { FC } from 'react';

import { Stack } from '@mui/material';
import { InlineEditorInactive } from 'common/components/ReactHookForm';

import { ReportNameContainer } from '../ReportName';

export const InsightsReportHeader: FC<{
  reportName: string;
  isPublic: boolean;
}> = ({ reportName, isPublic }) => (
  <Stack
    direction="row"
    sx={({ spacing, palette }) => ({
      backgroundColor: palette.grey[50],
      padding: spacing(1.25, 3),
      borderRadius: '100px',
      width: '100%',
    })}
  >
    {isPublic ? (
      <InlineEditorInactive disabled component="div">
        {reportName}
      </InlineEditorInactive>
    ) : (
      <ReportNameContainer />
    )}
  </Stack>
);
