import { useCallback, useState } from 'react';

import { blobToData } from 'common/utils';
import { saveAs } from 'file-saver';
import { InterLight, InterMedium, InterRegular } from 'fonts/Inter';
import html2canvas from 'html2canvas';
import { useAppDispatch } from 'store';
import { addAppErrorMessage } from 'store/features/alerts';

const fontFamilyName = 'Inter';
const lightFontUrl = InterLight;
const regularFontUrl = InterRegular;
const mediumFontUrl = InterMedium;

const fontsDefinitions = [
  {
    familyName: fontFamilyName,
    fontWeight: 400,
    url: lightFontUrl,
  },
  {
    familyName: fontFamilyName,
    fontWeight: 500,
    url: regularFontUrl,
  },
  {
    familyName: fontFamilyName,
    fontWeight: 700,
    url: mediumFontUrl,
  },
];

const getFontFace = async (familyName: string, fontWeight: number, url: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const fontData = await blobToData(blob);

    return `@font-face {
          font-family: "${familyName}";
          font-style: normal;
          font-weight: ${fontWeight};
          font-display: swap;
          src: url("${fontData}");
      }`;
  } catch (err) {
    console.error('Error getting font face', err);
    return '';
  }
};

const loadFonts = async () => {
  const loadedFonts: string[] = [];
  for (const fontDefinition of fontsDefinitions) {
    const fontFace = await getFontFace(fontDefinition.familyName, fontDefinition.fontWeight, fontDefinition.url);
    loadedFonts.push(fontFace);
  }
  return loadedFonts;
};

const appendStyle = (css: string, target: Element) => {
  const styleEl = document.createElement('style');
  styleEl.appendChild(document.createTextNode(css));
  target.appendChild(styleEl);
};

const appendFonts = (fonts: string[], target: Element) => {
  fonts.forEach((font) => {
    appendStyle(font, target);
  });
};

export const useExportAsPNG = (elementId: string, fileName: string | number) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const exportAsPNG = useCallback(async () => {
    setIsLoading(true);
    try {
      const element = document.getElementById(elementId);
      if (!element) return;

      const fonts = await loadFonts();
      const canvas = await html2canvas(element, {
        logging: false,
        onclone: (_document, clone) => {
          const svg = clone.querySelector('svg');
          if (!svg) return;

          appendFonts(fonts, svg);
        },
      });

      const data = canvas.toDataURL('image/png');
      saveAs(data, `${fileName}.png`);
    } catch {
      dispatch(addAppErrorMessage(`Could not save '${fileName}' file.`));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, elementId, fileName]);

  return { exportAsPNG, isLoading };
};
