import { P2PInsightWSAction, P2PInsightWSActions } from 'common/constants';
import { extractPathVariables, generateDynamicPath } from 'common/utils';
import omit from 'lodash/omit';
import { AppDispatch } from 'store';
import { SocketSendParams } from 'store/features/socket';
import { websocketSend } from 'store/features/socket/actions';

/**
 * Factory function for creating Redux action creators for **P2Pi** WebSocket actions.
 * @TODO Make it generic to handle other WS endpoints
 *
 * @template T - The type of data that will be sent with the WebSocket action.
 *
 * @param {P2PInsightWSAction} path - The path for the WebSocket action, which can contain variables indicated by a colon (e.g., '/meeting/:eventId/join').
 *
 * @param {T} data - The data to be sent with the WebSocket action.
 *
 * @example
 * ```ts
 * //before
 *
 * export const meetingJoin =
 * ({ meetingId, p2pInsightsId }: { p2pInsightsId: number; meetingId: number }) =>
 * (dispatch: AppDispatch) => {
 *   dispatch(
 *     websocketSend({
 *       path: `/p2pi/${p2pInsightsId}/meetings/${meetingId}/join`,
 *     })
 *   );
 * };
 *
 * // after
 * export const meetingJoin = createP2PInsightWSAction<P2PInsightsWSParams>('/p2pi/:p2pInsightsId/meetings/:meetingId/join);
 *
 * // usage in component or hook
 * const dispatch = useAppDispatch();
 * dispatch(meetingJoin());
 * ```
 *  */
export const createP2PInsightWSAction =
  <T extends Record<string, unknown>>(
    action: P2PInsightWSAction,
    callback?: (dispatch: AppDispatch, data: T) => void
  ) =>
  (data: T = {} as T, config?: Partial<SocketSendParams>) =>
  (dispatch: AppDispatch): void => {
    if (!Object.values(P2PInsightWSActions).includes(action))
      throw Error(`Action '${action}' is not supported by P2PInsight WS`);

    const payload = omit(data, extractPathVariables(action));
    dispatch(
      websocketSend({
        path: generateDynamicPath(action, data),
        data: Object.keys(payload).length ? payload : undefined,
        plainBody: Object.keys(payload).length ? config?.plainBody ?? true : undefined,
      })
    );

    if (typeof callback === 'function') {
      callback(dispatch, data);
    }
  };
