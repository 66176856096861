import React, { FC } from 'react';

import { useTheme } from '@mui/material';

const IconCommunityManagerButton: FC<{
  width: number | string;
  active?: boolean;
  contained?: boolean;
  color?: string;
}> = ({ width, active = false, contained = false, color: colorProp }) => {
  const theme = useTheme();
  const color = colorProp || theme.palette.custom.darkGrey;

  return (
    <svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5417_17023)">
        <path
          d={active ? 'M20 18H5L3 8L8 12L12 6L16 12L21 8L20 18' : 'M14 18H5L3 8L8 12L12 6L16 12L21 8L20 13'}
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={contained ? color : undefined}
        />
        {!active && (
          <>
            <path d="M17 18H21" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19 16V20" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
          </>
        )}
      </g>
      <defs>
        <clipPath id="clip0_5417_17023">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCommunityManagerButton;
