import {
  LocalAudioTrack,
  LocalAudioTrackPublication,
  LocalParticipant,
  LocalVideoTrack,
  LocalVideoTrackPublication,
} from 'twilio-video';

const detachTracks = (tracks: (LocalVideoTrack | LocalAudioTrack)[]) => {
  tracks.forEach((track) => {
    if (track) {
      track.detach().forEach((detachedElement) => {
        detachedElement.remove();
      });
    }
  });
};

export const stopTracks = (tracks: (LocalVideoTrack | LocalAudioTrack)[]): void => {
  tracks.forEach((track) => {
    if (!track) {
      return;
    }
    track.disable();
    track.stop();
  });
};

export const disposeTracks = (tracks?: (LocalVideoTrack | LocalAudioTrack)[]): void => {
  if (!tracks) {
    return;
  }
  detachTracks(tracks);
  stopTracks(tracks);
};

export const disposeTrackPublications = (
  trackPublications: (LocalAudioTrackPublication | LocalVideoTrackPublication)[],
  localParticipant?: LocalParticipant
): void => {
  const tracks = trackPublications.map((trackPublication) => trackPublication.track);
  localParticipant?.unpublishTracks(tracks);
  disposeTracks(tracks);
};
