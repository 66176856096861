import { IconButton, Stack, darken, styled } from '@mui/material';

import { isColorWhiteListed } from '../Button/utils/isColorWhiteListed';
import { IconButtonVariant } from './types';
import {
  mapActiveColorForTextVariant,
  mapColorForOutlinedVariant,
  mapColorForTextVariant,
  mapFocusColorForOutlinedVariant,
  mapFocusColorForTextVariant,
  mapHoverColorForOutlinedVariant,
  mapHoverColorForTextVariant,
} from './utils';

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isLoading' && prop !== 'variant',
})<{
  isLoading?: boolean;
  variant?: IconButtonVariant;
}>(({ isLoading, variant, theme: { palette }, color }) => ({
  ...(color &&
    isColorWhiteListed(color) &&
    isLoading && {
      '&:disabled': {
        backgroundColor: palette[color].main,
        color: palette[color].contrastText,
      },
    }),
  ...(variant === 'text' && {
    backgroundColor: 'transparent',
    color: mapColorForTextVariant(palette, color),

    '&:disabled': {
      backgroundColor: 'transparent',
      color: palette.grey[300],
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: mapHoverColorForTextVariant(palette, color),
    },
    '&:focus': {
      backgroundColor: 'transparent',
      color: mapFocusColorForTextVariant(palette, color),
    },
    '&:active, &.active': {
      backgroundColor: mapActiveColorForTextVariant(palette, color),
    },
  }),
  ...(variant === 'outlined' && {
    backgroundColor: 'transparent',
    color: mapColorForTextVariant(palette, color),
    border: '1px solid',
    borderColor: mapColorForOutlinedVariant(palette, color),

    '&:disabled': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: mapHoverColorForOutlinedVariant(palette, color),
    },
    '&:focus': {
      backgroundColor: mapFocusColorForOutlinedVariant(palette, color),
    },
    '&:active, &.active': {
      backgroundColor: mapHoverColorForOutlinedVariant(palette, color),
      borderColor: mapHoverColorForOutlinedVariant(palette, color),
    },
  }),
  ...(variant === 'plain' && {
    backgroundColor: 'transparent',
    color: palette.text.secondary,

    '&:disabled': {
      backgroundColor: 'transparent',
      color: palette.grey[300],
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: darken(palette.text.secondary, 0.3),
    },
    '&:focus': {
      backgroundColor: palette.extendedPrimary[25],
    },
    '&:active, &.active': {
      backgroundColor: 'transparent',
    },
  }),
}));

export const LoadingIndicatorContainer = styled(Stack)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});
