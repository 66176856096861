import { Stack, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type Props = {
  isActive?: boolean;
};

export const ChartSelectWrapper = styled(Stack, {
  shouldForwardProp: omitForwardedProps<Props>('isActive'),
})<Props>(({ theme: { palette, spacing }, isActive }) => ({
  flexDirection: 'row',
  padding: spacing(0, 1),
  alignItems: 'center',
  backgroundColor: isActive ? palette.extendedPrimary[50] : palette.grey[100],
  color: isActive ? palette.primary.main : palette.grey[700],
  borderRadius: '4px',
  height: 24,
  cursor: 'pointer',
}));
