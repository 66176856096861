import React, { FC, ReactElement } from 'react';

import { Box, Divider, Stack } from '@mui/material';
import { LinkifyText } from 'common/components';
import { useBreakpoints } from 'common/hooks';
import { PageActions } from 'common/layout';
import { EventDetails } from 'domain/event';
import { DEFAULT_EVENT_IMAGE } from 'event/management/constants';
import { isVisibleForPublished, isVisibleForPublishedOrStarted } from 'event/management/utils';

import { EventActions } from '../EventActions';
import { EventCopyLinkButtonContainer } from '../EventActions/components';
import { EventScheduledTime } from '../EventScheduledTime';
import { EventSponsors } from '../EventSponsors';
import { EventStatusBadge } from '../EventStatusBadge';
import { EventTimeCounter } from '../EventTimeCounter';
import {
  EventActionButtonsContainer,
  EventDescription,
  EventImage,
  EventImageContainer,
  EventName,
  EventOwner,
  EventPreviewContainer,
  EventPreviewHeader,
  EventPreviewSummaryContainer,
  EventSponsorsContainer,
  EventSponsorsTitle,
} from './EventPreview.styled';

type Props = Pick<
  EventDetails,
  'id' | 'name' | 'eventTime' | 'eventImage' | 'description' | 'sponsors' | 'status' | 'isOwned'
> & {
  ownerName?: string;
  actionButton: ReactElement;
  withTimeCounter?: boolean;
};

export const EventPreview: FC<Props> = ({ actionButton, withTimeCounter = true, ...eventDetails }) => {
  const { isMobile } = useBreakpoints();
  const {
    id,
    name,
    description,
    eventImage = DEFAULT_EVENT_IMAGE,
    sponsors,
    eventTime,
    status,
    isOwned,
    ownerName,
  } = eventDetails;

  return (
    <>
      {withTimeCounter && isVisibleForPublished(status) && <EventTimeCounter startDate={eventTime.startTime} />}

      <EventPreviewContainer>
        <EventPreviewHeader>
          <EventImageContainer>
            <EventImage src={eventImage.src} alt={name} />
          </EventImageContainer>

          <EventPreviewSummaryContainer>
            {!isMobile ? (
              <>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <EventStatusBadge status={status} />
                  <EventScheduledTime startDate={eventTime.startTime} duration={eventTime.durationInMinutes} />
                </Stack>
                <EventName>{name}</EventName>
              </>
            ) : (
              <>
                <EventStatusBadge status={status} />
                <EventName>{name}</EventName>
                <EventScheduledTime startDate={eventTime.startTime} duration={eventTime.durationInMinutes} />
              </>
            )}

            {ownerName && <EventOwner>Organized by: {ownerName}</EventOwner>}
            {!isMobile && (
              <Box mt={3} minWidth={240}>
                {actionButton}
              </Box>
            )}
          </EventPreviewSummaryContainer>

          {!isMobile && (
            <EventActionButtonsContainer>
              <EventActions isOwned={isOwned} eventId={id} eventState={status} />
            </EventActionButtonsContainer>
          )}
        </EventPreviewHeader>

        {description && (
          <>
            <Divider flexItem />
            <EventDescription>
              <LinkifyText>{description}</LinkifyText>
            </EventDescription>
          </>
        )}

        {!!sponsors.length && (
          <>
            <Divider flexItem />
            <EventSponsorsContainer>
              <EventSponsorsTitle>Sponsors</EventSponsorsTitle>
              <EventSponsors sponsors={sponsors} />
            </EventSponsorsContainer>{' '}
          </>
        )}
      </EventPreviewContainer>
      {isMobile && (
        <PageActions>
          <>
            {actionButton}
            {isVisibleForPublishedOrStarted(status) && <EventCopyLinkButtonContainer eventId={id} variant="text" />}
          </>
        </PageActions>
      )}
    </>
  );
};
