import React, { FC, useCallback, useEffect } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import { faCalendarDay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CalendarPickerView } from '@mui/lab';
import { Stack, type TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { addYears } from 'date-fns';

import { ErrorBox } from '../../ErrorBox';

interface DateInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  inputFormat?: string;
  views?: readonly CalendarPickerView[];
  isStatic?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (value: Date | null) => void;
}

export const DateInput: FC<DateInputProps> = ({
  label,
  name,
  placeholder,
  inputFormat = 'EEE dd MMM yyyy',
  views = ['year', 'month', 'day'],
  isStatic,
  minDate = new Date(),
  maxDate = addYears(new Date(), 1),
  onChange,
}) => {
  const { register, control, clearErrors } = useFormContext();
  const value = useWatch({ name });
  const { field, fieldState } = useController({
    name,
    control,
  });

  useEffect(() => {
    register(name);
  }, [register, name]);

  const handleChange = useCallback(
    (date: Date | null) => {
      clearErrors(name);
      field.onChange(date);
      field.onBlur();
      onChange?.(date);
    },
    [field, onChange, clearErrors, name]
  );

  const props = {
    componentsProps: isStatic
      ? {
          actionBar: { actions: [] },
        }
      : undefined,
    disablePast: true,
    minDate,
    maxDate,
    views,
    label,
    slotProps: {
      leftArrowIcon: { sx: { paddingLeft: 1 } },
      textField: {
        'data-testid': 'datePicker',
        placeholder,
      } as TextFieldProps,
    },
    components: {
      OpenPickerIcon: () => <FontAwesomeIcon size="1x" icon={faCalendarDay} />,
    },
    value,
    onChange: handleChange,
    format: inputFormat,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack gap={3}>
        {!!fieldState.error && <ErrorBox>{fieldState.error.message}</ErrorBox>}
        {isStatic ? <StaticDatePicker {...props} /> : <DatePicker {...props} />}
      </Stack>
    </LocalizationProvider>
  );
};
