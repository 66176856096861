import React, { FC, useMemo, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonBase, Stack } from '@mui/material';
import { Community } from 'domain/Community';

import { CommunitySelectorItem, CommunitySelectorMenu } from './components';

type Props = {
  activeCommunity: Community | undefined;
  communities: Community[];
  onCommunityClick(communityId: number): void;
};

export const CommunitySelectorView: FC<Props> = ({ activeCommunity, communities, onCommunityClick }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const menuItems = useMemo(
    () =>
      communities.map((community) => ({
        component: <CommunitySelectorItem community={community} isActive={community.id === activeCommunity?.id} />,
        onClick: () => onCommunityClick(community.id),
      })),
    [communities, activeCommunity, onCommunityClick]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!activeCommunity) {
    return null;
  }

  if (communities.length === 1) {
    return <CommunitySelectorItem community={activeCommunity} />;
  }

  return (
    <>
      <ButtonBase sx={{ width: '100%' }} onClick={handleClick}>
        <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between" alignItems="center">
          <CommunitySelectorItem community={activeCommunity} />
          <KeyboardArrowDownIcon />
        </Stack>
      </ButtonBase>
      <CommunitySelectorMenu anchorEl={anchorEl} onClose={handleClose} items={menuItems} />
    </>
  );
};
