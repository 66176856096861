import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { isApiResponse } from 'common/utils';
import type { RootState } from 'store';
import { handleQueryError } from 'store/utils';

import { surveysWizardActions } from './surveyWizard.actions';
import {
  backButtonClickedEffect,
  deleteSucceededEffect,
  deleteSurvey,
  exitOrCanceledEffect,
  formSubmittedEffect,
  nextButtonClickedEffect,
  publishSucceededEffect,
  publishSurvey,
  startCreateSurveyEffect,
  untitledSurveyDetectedEffect,
  updateSucceededEffect,
  updateSurvey,
} from './surveyWizard.effects';

export const surveysWizardListener = createListenerMiddleware<RootState>();

surveysWizardListener.startListening({
  matcher: isAnyOf(
    surveysWizardActions.navigationBar.exitButton.clicked,
    surveysWizardActions.actionButton.cancelButton.clicked
  ),
  effect: exitOrCanceledEffect,
});

surveysWizardListener.startListening({
  actionCreator: surveysWizardActions.actionButton.createButton.clicked,
  effect: startCreateSurveyEffect,
});

surveysWizardListener.startListening({
  actionCreator: surveysWizardActions.untitledSurveyDetected,
  effect: untitledSurveyDetectedEffect,
});

surveysWizardListener.startListening({
  actionCreator: deleteSurvey.fulfilled,
  effect: deleteSucceededEffect,
});

surveysWizardListener.startListening({
  actionCreator: surveysWizardActions.actionButton.nextButton.clicked,
  effect: nextButtonClickedEffect,
});

surveysWizardListener.startListening({
  actionCreator: surveysWizardActions.actionButton.backButton.clicked,
  effect: backButtonClickedEffect,
});

surveysWizardListener.startListening({
  actionCreator: surveysWizardActions.form.submitted,
  effect: formSubmittedEffect,
});

surveysWizardListener.startListening({
  actionCreator: publishSurvey.fulfilled,
  effect: publishSucceededEffect,
});

surveysWizardListener.startListening({
  actionCreator: updateSurvey.fulfilled,
  effect: updateSucceededEffect,
});

surveysWizardListener.startListening({
  matcher: isAnyOf(updateSurvey.rejected, publishSurvey.rejected),
  effect: ({ type, payload }, { dispatch }) => {
    const response = isApiResponse(payload) ? payload : undefined;
    handleQueryError(payload, dispatch, type, response?.data || 'Unable to update Survey');
  },
});
