import React, { BaseSyntheticEvent, useState } from 'react';

import { Box, Chip, Grid, Popover, Stack, Typography } from '@mui/material';
import { Button } from 'common/components/_legacy/Button';
import { UserRole, userRolesList } from 'domain/UserProfile';

interface RoleItem {
  label: string;
  value: UserRole;
  isSelected: boolean;
}

export const FilterByRoleModal: React.FC<{
  initialSelectedRoles: UserRole[];
  onClose: () => void;
  anchor: Element | null;
  onChange(tags: UserRole[]): void;
}> = ({ initialSelectedRoles, onClose, anchor, onChange }) => {
  const [roles, setRoles] = useState<RoleItem[]>(
    userRolesList.map((role) => ({
      value: {
        id: role.id,
        name: role.key,
      },
      label: role.label,
      isSelected: initialSelectedRoles.some((r) => r.id === role.id),
    }))
  );

  const handleConfirm = () => {
    onChange(roles.filter((r) => r.isSelected).map((r) => r.value));
    onClose();
  };

  const handleCancel = () => {
    setRoles((value) =>
      value.map((role) => ({
        ...role,
        isSelected: initialSelectedRoles.includes(role.value),
      }))
    );
    onClose();
  };

  const handleClearSelectedRoles = () =>
    setRoles((value) =>
      value.map((role) => ({
        ...role,
        isSelected: false,
      }))
    );

  const handleRoleClick = (roleClicked: RoleItem) => () => {
    setRoles((value) =>
      value.map((role) => ({
        ...role,
        isSelected: roleClicked === role ? !role.isSelected : role.isSelected,
      }))
    );
  };

  const handleClose = (event: BaseSyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return handleConfirm();
    }
    onClose();
  };

  return (
    <Popover
      slotProps={{
        paper: {
          sx: ({ spacing }) => ({
            width: 520,
            padding: spacing(4),
            display: 'flex',
            flexDirection: 'column',
          }),
        },
      }}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
      open={!!anchor}
      anchorEl={anchor}
      sx={{ marginTop: 2 }}
    >
      <Box data-testid="FilterByRoleModal">
        <Grid container spacing={1.5}>
          {roles.map((role) => (
            <Grid item key={role.value.name}>
              <Chip
                data-testid={`FilterByRoleItem-${role.value.name}`}
                label={role.label}
                variant={role.isSelected ? 'filled' : 'outlined'}
                onClick={handleRoleClick(role)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Stack flexDirection="row" gap={1} mt={4}>
        <Typography
          onClick={handleClearSelectedRoles}
          variant="caption"
          display="flex"
          alignItems="center"
          sx={(theme) => ({
            flex: 1,
            fontSize: 14,
            color: theme.palette.common.black,
            cursor: 'pointer',
          })}
        >
          Clear all
        </Typography>

        <Button variant="outlined" color="inherit" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </Stack>
    </Popover>
  );
};
