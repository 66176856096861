import React from 'react';

import { UserAvatar } from 'common/components';
import IconCommunityManager from 'community/components/icons/IconCommunityManager';
import { avatarColors } from 'theme/palette';

type Props = {
  isCommunityManager?: boolean;
  isCommunityOwner?: boolean;
  avatarName: string;
  avatarLink?: string;
};

export const AvatarImage = ({ isCommunityManager, isCommunityOwner, avatarName, avatarLink }: Props) => {
  if (isCommunityManager) {
    return <IconCommunityManager width={40} />;
  }
  if (isCommunityOwner) {
    return <IconCommunityManager width={40} color={avatarColors.communityOwner} />;
  }
  return <UserAvatar avatarLink={avatarLink} name={avatarName} />;
};
