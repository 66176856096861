import React, { FC, MouseEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Box, Divider, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { UserAvatar } from 'common/components';
import { AppRoutes } from 'router';
import { useGetAccountQuery } from 'store/apis/user';
import { useKeycloakContext } from 'store/context/KeycloakContext';

export const CurrentUser: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const keycloak = useKeycloakContext();
  const { data: userAccount } = useGetAccountQuery();

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    keycloak.logout();
  };

  const displayName = userAccount?.displayName ?? userAccount?.username;
  const email = userAccount?.email;

  return (
    <Box data-testid="current-user">
      <Stack flexDirection="row" alignItems="center">
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <UserAvatar name={userAccount?.displayName} avatarLink={userAccount?.avatarLink} dimensions={24} />
        </IconButton>
      </Stack>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="current-user-menu"
        MenuListProps={{
          sx: {
            px: 0,
          },
        }}
      >
        <Stack gap={0.5} p={1.5} pt={1}>
          <Typography fontWeight={500}>{displayName}</Typography>
          <Typography variant="natter-text-xs">{email}</Typography>
        </Stack>
        <Divider />
        <Stack mt={0.75}>
          <MenuItem component={NavLink} to={AppRoutes.Account}>
            Account settings
          </MenuItem>
          <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
        </Stack>
      </Menu>
    </Box>
  );
};
