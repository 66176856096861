import { makeStyles } from '@mui/styles';

export const useMembersHeaderStyles = makeStyles(
  (theme) => ({
    actionsContainer: {
      flex: 1,
    },
    filterButton: {
      margin: theme.spacing(0, 2.5),
    },
    buttonsContainer: {
      flex: '0 0 100%',
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2.5),
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    filtersContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    tag: {
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      fontSize: '12px',
      lineHeight: '22px',
      border: `solid 1px ${theme.palette.custom.lightGrey}`,
      padding: `0 ${theme.spacing(1)}`,
      borderRadius: 4,
      fontDisplay: 'swap',
    },
    membersLabel: {
      fontSize: '14px',
      color: theme.palette.custom.darkGrey,
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'membersHeader' }
);
