import { useCallback, useMemo } from 'react';

import { isCommunityManager, isCommunityMember, isCommunityOwner } from 'common/utils';
import { UserPrivilegeDetails, UserPrivilegeName } from 'domain/UserProfile';
import { useAppSelector } from 'store';
import { selectMyCommunityId, useGetCurrentCommunityMemberQuery } from 'store/apis/community';
import { selectUserAccount } from 'store/apis/user';

export const useAuthorization = () => {
  const myCommunityId = useAppSelector(selectMyCommunityId);
  const account = useAppSelector(selectUserAccount);
  const { data, isLoading, isError } = useGetCurrentCommunityMemberQuery(
    { communityId: myCommunityId },
    { skip: !myCommunityId }
  );
  const { communityRole, privileges: userPrivileges } = data ?? {};

  const isActivityAllowed = useMemo<Record<UserPrivilegeName, boolean>>(
    () =>
      Object.values(UserPrivilegeDetails).reduce(
        (allPrivileges, { id, name }) => {
          allPrivileges[name] = !!userPrivileges?.some((up) => up.id === id);
          return allPrivileges;
        },
        {} as Record<UserPrivilegeName, boolean>
      ),
    [userPrivileges]
  );

  const hasPrivilege = useCallback(
    (privilegeNames: UserPrivilegeName[]) => privilegeNames?.some((p) => !!userPrivileges?.some((ap) => ap.name === p)),
    [userPrivileges]
  );

  return {
    isError,
    isLoading,
    isCommunityMember: isCommunityMember({ communityRole }),
    isWithoutCommunity: !myCommunityId,
    isOrganizationMember: !!account?.isOrganizationMember,
    isCommunityManager: isCommunityManager({ communityRole }),
    isCommunityOwner: isCommunityOwner({ communityRole }),
    hasPrivilege,
    isActivityAllowed,
  };
};
