import React, { MouseEvent, PropsWithChildren, ReactNode, useCallback, useMemo } from 'react';

import { Box, Stack, styled } from '@mui/material';
import { type Columns, Table } from 'common/components/_legacy/Table';
import { UserAvatar } from 'common/components/_legacy/Table/components/UserAvatar/UserAvatar';
import type { SortOrder } from 'domain/Common';
import { UserRoleName, userRoleToColorMap, userRoleToLabelMap } from 'domain/UserProfile';

import type { MemberInfo } from '../../types';

const CommunityMemberRole = styled(Box)(({ theme }) => ({
  borderRadius: '100px',
  backgroundColor: theme.palette.warning.light,
  padding: '4px 16px',
}));

const baseTableSchema: Columns<MemberInfo> = [
  {
    id: 'displayName',
    label: 'Member',
    format: (data): ReactNode => (
      <UserAvatar
        data={data}
        displayAsCommunityManager={data.communityRole === UserRoleName.CommunityManager}
        displayAsCommunityOwner={data.communityRole === UserRoleName.CommunityOwner}
      />
    ),
    classNames: 'standardColumn',
    sortable: true,
  },
  {
    id: 'email',
    label: 'Email',
    format: (data) => data.email || '-',
    classNames: 'standardColumn',
    sortable: true,
  },
  {
    id: 'role',
    label: 'Job Title',
    format: (data) => data.role || 'Awaiting Registration',
    classNames: 'standardColumn',
    sortable: true,
  },
  {
    id: 'communityRole',
    label: 'Role',
    format: (data) => (
      <Stack direction="row">
        <CommunityMemberRole sx={{ backgroundColor: userRoleToColorMap[data.communityRole] }}>
          {userRoleToLabelMap[data.communityRole] ?? 'Unknown'}
        </CommunityMemberRole>
      </Stack>
    ),
    classNames: 'standardColumn',
    sortable: true,
    width: '20%',
  },
];

export function MembersTable<T extends MemberInfo>({
  communityMembers,
  order,
  orderBy,
  emptyTableText,
  headerOverlay,
  onOrderChange,
  onOrderByChange,
  createTableSchema,
}: PropsWithChildren<{
  communityMembers: T[];
  order: SortOrder;
  orderBy: string;
  emptyTableText: string;
  headerOverlay?: ReactNode;
  onOrderChange(order: SortOrder): void;
  onOrderByChange(orderBy: string): void;
  createTableSchema(baseTableSchema: Columns<T>): Columns<T>;
}>) {
  const handleSortChange = useCallback(
    (event: MouseEvent<unknown>, newOrderBy: string) => {
      if (newOrderBy === orderBy) {
        onOrderChange(order === 'asc' ? 'desc' : 'asc');
      }
      onOrderByChange(newOrderBy);
    },
    [onOrderByChange, onOrderChange, order, orderBy]
  );

  const tableSchema = useMemo(() => createTableSchema(baseTableSchema), [createTableSchema]);

  return (
    <Table
      emptyTableText={emptyTableText}
      itemsList={communityMembers}
      columns={tableSchema}
      order={order}
      orderBy={orderBy}
      onSortChange={handleSortChange}
      headerOverlay={headerOverlay}
      fixedTableLayout
    />
  );
}
