/* eslint-disable sonarjs/no-duplicate-string */
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Typography, styled } from '@mui/material';
import type { TypographyWithComponent } from 'common/theme/typography';

export const EventPreviewContainer = styled('div', { name: 'EventPreviewContainer' })(
  ({ theme: { breakpoints, spacing } }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
    alignItems: 'start',

    [breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: spacing(2),
      alignItems: 'center',
    },
  })
);

export const EventPreviewHeader = styled('div', { name: 'EventPreviewHeader' })(
  ({ theme: { breakpoints, spacing } }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(4),
    alignItems: 'start',
    width: '100%',

    [breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: spacing(2),
      alignItems: 'center',
    },
  })
);

export const EventActionButtonsContainer = styled('div', { name: 'EventActionButtonsContainer' })(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
  })
);

export const EventPreviewSummaryContainer = styled('section', { name: 'EventPreviewSummaryContainer' })(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: spacing(0.5),
    flex: 1,

    [breakpoints.down('md')]: {
      alignItems: 'center',
    },
  })
);

export const EventName = styled(Typography, { name: 'EventName' })<TypographyWithComponent>(
  ({ theme: { breakpoints, typography } }) => ({
    ...typography['natter-display-sm'],
    fontWeight: 600,

    [breakpoints.down('md')]: {
      ...typography['natter-display-xs'],
      textAlign: 'center',
    },
  })
);

EventName.defaultProps = {
  component: 'h2',
};

export const EventOwner = styled(Typography, { name: 'EventOwner' })<TypographyWithComponent>(
  ({ theme: { palette, typography } }) => ({
    ...typography['natter-text-sm'],

    color: palette.grey[700],
  })
);

EventOwner.defaultProps = {
  component: 'span',
};

export const EventDescription = styled(Typography, { name: 'EventDescription' })<TypographyWithComponent>(
  ({ theme: { breakpoints, palette, spacing, typography } }) => ({
    ...typography['natter-text-sm'],

    color: palette.grey[700],
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',

    [breakpoints.down('md')]: {
      textAlign: 'center',
      padding: spacing(2, 1, 2, 0),
    },
  })
);

export const EventImageContainer = styled('div', { name: 'EventImageContainer' })(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    overflow: 'hidden',
    border: '1px solid',
    borderColor: palette.divider,
    borderRadius: spacing(1),
    width: spacing(19),
    height: spacing(19),
    transition: 'all 0.1s linear',

    [breakpoints.down('md')]: {
      width: spacing(7),
      height: spacing(7),
    },
  })
);

export const EventImage = styled(LazyLoadImage, { name: 'EventImage' })({
  flex: 1,
  minWidth: 0,
  minHeight: 0,
});

export const EventSponsorsContainer = styled('div', { name: 'EventSponsorsContainer' })(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(1.25),
  alignSelf: 'stretch',
}));

export const EventSponsorsTitle = styled(Typography, { name: 'EventSponsorsTitle' })<TypographyWithComponent>(
  ({ theme: { typography } }) => ({
    ...typography['natter-text-sm'],
    fontWeight: 600,
  })
);

EventSponsorsTitle.defaultProps = {
  component: 'span',
};
