import React, { FC, useMemo } from 'react';

import { ErrorBoundary } from 'common/components';
import { HorizontalBarChart } from 'common/components/Charts';
import { uuid } from 'common/utils';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { ChartCardProps } from '../../ChartCard';
import {
  ChartCardChartWrapper,
  ChartCardHeader,
  ChartCardHeaderRightSection,
  ChartCardTitle,
  ChartCardWrapper,
} from '../../ChartCard.styled';
import { ChartErrorFallback } from '../ChartErrorFallback';
import { ThemesChartCardActions } from './components';

type ThemesChartCardProps = Omit<ChartCardProps, 'availableChartTypes'> & {
  themes: TextAnswerTheme[];
};

export const ThemesChartCard: FC<ThemesChartCardProps> = ({
  title,
  chartProps,
  hasError = false,
  children,
  themes,
}) => {
  const chartId = useMemo(uuid, []);

  return (
    <>
      <ChartCardWrapper>
        <ChartCardHeader>
          <ChartCardTitle title={title}>{title}</ChartCardTitle>
          <ChartCardHeaderRightSection>
            <ThemesChartCardActions chartId={chartId} themes={themes} />
          </ChartCardHeaderRightSection>
        </ChartCardHeader>
        <ErrorBoundary fallback={<ChartErrorFallback />} hasError={hasError}>
          <ChartCardChartWrapper id={chartId}>
            <HorizontalBarChart {...chartProps}>{children}</HorizontalBarChart>
          </ChartCardChartWrapper>
        </ErrorBoundary>
      </ChartCardWrapper>
    </>
  );
};
