import React, { ComponentType, FC, ReactElement } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { BaseGuard } from 'common/components';
import { generateDynamicPath, isCommunityOwner } from 'common/utils';
import { AppRoutes, Routes } from 'router';
import { useAppSelector } from 'store';
import { selectCommunityMemberConfigurationInfo, selectCurrentCommunityMember } from 'store/apis/community';

export const withMemberSetupDone =
  <P extends object>(WrappedComponent: ComponentType<P>, config = { autoRedirect: true }): FC<P> =>
  (props): ReactElement => {
    const location = useLocation();
    const communityMember = useAppSelector(selectCurrentCommunityMember);
    const { isAccountConfigured, isRegistered } = useAppSelector(selectCommunityMemberConfigurationInfo);
    const isMemberSetupRequired = isRegistered && !isAccountConfigured;
    const shouldRedirectToMemberSetup =
      isMemberSetupRequired && config.autoRedirect && !isCommunityOwner(communityMember);

    if (shouldRedirectToMemberSetup) {
      return (
        <Redirect to={generateDynamicPath(AppRoutes.MemberSetup, undefined, { redirectUrl: location.pathname })} />
      );
    }

    return (
      <BaseGuard
        shouldDisplay={!isMemberSetupRequired}
        title="Your account is not configured"
        description="Please finish your Member setup"
        buttonLabel="Finish Member Setup"
        href={Routes.MemberSetup}
      >
        <WrappedComponent {...props} />
      </BaseGuard>
    );
  };
