import { serializeDateFields } from 'common/utils/time';
import { PaginatedApiPayloadV2 } from 'domain/Common';
import {
  EventDetails,
  EventDetailsApiResponse,
  EventListItem,
  EventListItemApiResponse,
  PublicEventDetails,
  PublicEventDetailsApiResponse,
  SimilarEvent,
  SimilarEventApiResponse,
  SimilarEventQuestion,
  SimilarEventQuestionApResponse,
} from 'domain/event';
import { flow } from 'lodash/fp';
import pick from 'lodash/pick';
import { transformPaginatedApiPayload } from 'modules/api/utils';

import {
  mapEventRecordingFromApi,
  mapEventRelatedVideoFromApi,
  mapEventTimeFromApi,
  mapOwnerBaseFromApi,
} from './common';
import { mapEventRelatedImagesToEventImage, mapEventRelatedImagesToSponsors } from './eventImage';

export const mapPublicEventFromApi = (data: PublicEventDetailsApiResponse): PublicEventDetails => ({
  ...pick(data, ['id', 'communityId', 'hash', 'name', 'description', 'brandingColor', 'logoImageLink']),
  status: data.state,
  owner: mapOwnerBaseFromApi(data),
  eventImage: mapEventRelatedImagesToEventImage(data.eventRelatedImages),
  sponsors: mapEventRelatedImagesToSponsors(data.eventRelatedImages),
  eventTime: mapEventTimeFromApi({ startTime: data.startTime, durationInMinutes: data.durationMinutes }),
  isPinEnabled: data.pinEnabled,
});

const mapEventListItemFromApi = (data: EventListItemApiResponse): EventListItem => ({
  ...pick(data, ['id', 'communityId', 'hash', 'name', 'description', 'state', 'deletionDate', 'agoraProxyMode', 'pin']),
  isOwned: data.owned,
  owner: mapOwnerBaseFromApi(data),
  status: data.state,
  eventTime: mapEventTimeFromApi({ startTime: data.startDate, durationInMinutes: data.eventDuration }),
  eventImage: mapEventRelatedImagesToEventImage(data.eventRelatedImages),
  eventRelatedVideos: data.eventRelatedVideos.map(mapEventRelatedVideoFromApi),
  guestCount: data.eventGuestCount,
  isPinEnabled: data.pinEnabled,
  isAgoraCloudRecordingEnabled: data.isAgoraCloudRecordingEnabled,
});

export const mapPaginatedEventListItemsFromApi = flow(
  transformPaginatedApiPayload<EventListItemApiResponse>,
  (payload) => ({
    ...payload,
    data: payload.data.map(flow(serializeDateFields('startDate'), mapEventListItemFromApi)),
  })
);

export const mapEventDetailsFromApi = (data: EventDetailsApiResponse): EventDetails => ({
  ...pick(data, [
    'id',
    'owner',
    'communityId',
    'hash',
    'name',
    'description',
    'deletionDate',
    'agoraProxyMode',
    'pin',
    'hasCompletedPreEventQuestions',
    'hasCompletedPostEventQuestions',
    'isUserRegistered',
  ]),
  isOwned: data.owned,
  eventTime: mapEventTimeFromApi({ startTime: data.startDate, durationInMinutes: data.eventDuration }),
  status: data.state,
  eventImage: mapEventRelatedImagesToEventImage(data.eventRelatedImages),
  sponsors: mapEventRelatedImagesToSponsors(data.eventRelatedImages),
  eventRelatedVideos: data.eventRelatedVideos.map(mapEventRelatedVideoFromApi),
  eventRecordings: data.eventRecordings.map(mapEventRecordingFromApi),
  isPinEnabled: data.isPinEnabled,
  isAgoraCloudRecordingEnabled: data.isAgoraCloudRecordingEnabled,
});

export const mapPaginatedSimilarEventsFromApi = (
  payload: PaginatedApiPayloadV2<SimilarEventApiResponse>
): PaginatedApiPayloadV2<SimilarEvent> => ({
  ...payload,
  data: payload.data.map(mapSimilarEventsFromApi),
});

const mapSimilarEventsFromApi = (data: SimilarEventApiResponse): SimilarEvent => ({
  ...pick(data, ['id', 'owner', 'communityId', 'hash', 'name', 'description', 'deletionDate', 'agoraProxyMode', 'pin']),
  eventTime: mapEventTimeFromApi({ startTime: data.startDate, durationInMinutes: data.eventDuration }),
  status: data.state,
  questions: data.questions.map(mapSimilarEventQuestionFromApi),
  eventRelatedVideos: data.eventRelatedVideos.map(mapEventRelatedVideoFromApi),
  guestCount: data.eventGuestCount,
  isAgoraCloudRecordingEnabled: data.isAgoraCloudRecordingEnabled,
});

const mapSimilarEventQuestionFromApi = (data: SimilarEventQuestionApResponse): SimilarEventQuestion => data;

export const mapEventDetailsToEventListItem = (eventDetails: EventDetails): EventListItem => ({
  ...eventDetails,
  guestCount: 0,
});
