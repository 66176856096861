import React, { FC } from 'react';

import { useEventDelete, useEventRoutes } from 'event/hooks';
import { useAppSelector } from 'store';
import { selectActiveEventId } from 'store/features/event';

import { EventDeleteButton } from './EventDeleteButton';

export const EventDeleteButtonContainer: FC = () => {
  const activeEventId = useAppSelector(selectActiveEventId);
  const { goToMyEvents } = useEventRoutes();
  const { openDeleteEventConfirmationModal, DeleteEventConfirmationModal } = useEventDelete({
    eventId: activeEventId,
    onSuccess: goToMyEvents,
  });

  return (
    <>
      <EventDeleteButton onClick={openDeleteEventConfirmationModal} />
      {DeleteEventConfirmationModal}
    </>
  );
};
