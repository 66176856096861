import type { Components, Theme } from '@mui/material';

export const MuiMenu: Components<Theme>['MuiMenu'] = {
  styleOverrides: {
    root: ({ theme: { spacing } }) => ({
      borderRadius: spacing(0.5),
    }),
    paper: ({ theme: { palette, spacing } }) => ({
      backgroundColor: palette.common.white,
      border: 'none',
      borderRadius: spacing(0.5),
      boxShadow: `
        0px 8px 12px 0px #091E4226,
        0px 0px 1px 0px #091E424F
      `,
      marginTop: spacing(1),
    }),
  },
};
