import { TagDescription, createApi } from '@reduxjs/toolkit/query/react';
import { SortOrder } from 'domain/Common';
import { CreateAnswerRequest } from 'domain/question';
import { getApiBaseUrl } from 'modules/api/utils';
import {
  GetP2PAttendeesResponse,
  P2PInsightAddUsersParams,
  P2PInsightDetails,
  P2PInsightFormValues,
  P2PInsightGetMeetingByIdData,
  P2PInsightGetMeetingsParams,
  P2PInsightGetMeetingsResponse,
  P2PInsightGetUsersParams,
  P2PInsightInfo,
  P2PInsightsCancelMeetingParams,
  P2PInsightsRescheduleMeetingParams,
} from 'pages/P2PInsights/types';
import {
  mapP2PInsightFromApi,
  mapP2PInsightMeetingFromApi,
  mapP2PInsightToApi,
  mapP2PInsightsFromApi,
} from 'pages/P2PInsights/utils';
import qs from 'query-string';

import axiosBaseQuery from '../_axiosBaseQuery';
import { GetP2PInsightsMeetingByIdPayload } from './types';

type WithP2PInsightMeetingId<T = unknown> = { meetingId?: number } & T;

const getTags = (userIds: number[]): TagDescription<'ALL_USERS'>[] => {
  if (userIds.length) {
    return userIds.map((userId) => ({ type: 'ALL_USERS', userId }));
  }
  return ['ALL_USERS'];
};

export const p2pInsightApi = createApi({
  reducerPath: 'p2pInsightsApi',
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v2') }),
  tagTypes: ['INSIGHTS', 'ALL_USERS', 'MEETINGS'],
  endpoints: (builder) => ({
    createP2PInsights: builder.mutation<void, { data: P2PInsightFormValues; communityId: number }>({
      query: ({ communityId, data }) => ({
        url: '/p2pi',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: mapP2PInsightToApi(communityId, data),
      }),
      invalidatesTags: ['INSIGHTS'],
    }),
    getP2PInsights: builder.query<P2PInsightInfo[], { communityId: number | undefined; sortDir: SortOrder }>({
      query: ({ communityId, sortDir }) => ({
        url: '/p2pi',
        params: { communityId, sortDir },
      }),
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'INSIGHTS' as const, id })), 'INSIGHTS'] : [],
      transformResponse: mapP2PInsightsFromApi,
    }),
    getP2PInsightById: builder.query<P2PInsightDetails, { id: number }>({
      query: ({ id }) => `/p2pi/${id}`,
      transformResponse: mapP2PInsightFromApi,
    }),
    getP2PInsightsUsers: builder.query<GetP2PAttendeesResponse, P2PInsightGetUsersParams>({
      query: ({ insightId, page, pageSize, tags, searchPhrase, isAdded, sortBy, sortOrder }) => ({
        url: `/p2pi/${insightId}/users?${qs.stringify(
          {
            page,
            pageSize,
            tags: tags.length ? tags.map((t) => t.id) : undefined,
            searchText: searchPhrase,
            isAdded: isAdded || undefined,
            sortField: sortBy,
            sortDir: sortOrder,
          },
          { arrayFormat: 'comma' }
        )}`,
      }),
      providesTags: (result) =>
        result?.data ? [...result.data.map(({ userId }) => ({ type: 'ALL_USERS' as const, userId })), 'ALL_USERS'] : [],
    }),
    addP2PAttendees: builder.mutation<void, P2PInsightAddUsersParams>({
      query: ({ insightId, searchText, tags, userIds }) => ({
        url: `/p2pi/${insightId}/add_users`,
        method: 'POST',
        data: { tags, searchText, userIds },
      }),
      invalidatesTags: (_result, _error, { userIds, insightId }) => [
        { type: 'INSIGHTS', id: insightId },
        ...getTags(userIds),
      ],
    }),
    getP2PInsightsMeetings: builder.query<P2PInsightGetMeetingsResponse, P2PInsightGetMeetingsParams>({
      query: ({ insightId, page, pageSize, searchText, sortBy, sortOrder }) => ({
        url: `/p2pi/${insightId}/meetings`,
        params: {
          page,
          pageSize,
          searchText,
          sortDir: sortOrder,
          sortField: sortBy,
        },
      }),
    }),
    getP2PInsightsMeetingById: builder.query<P2PInsightGetMeetingByIdData, { meetingId: number }>({
      query: ({ meetingId }) => `/p2pi/meeting/${meetingId}`,
      providesTags: (result) => (result ? [{ type: 'MEETINGS' as const, id: result.meeting.id }] : []),
      transformResponse: (payload: GetP2PInsightsMeetingByIdPayload) => mapP2PInsightMeetingFromApi(payload),
    }),
    rescheduleP2PInsightsMeeting: builder.mutation<void, P2PInsightsRescheduleMeetingParams>({
      query: ({ meetingStartTime, rescheduleReason, meetingId }) => ({
        url: `/p2pi/meeting/${meetingId}/reschedule`,
        method: 'POST',
        data: { meetingStartTime, rescheduleReason },
      }),
      invalidatesTags: ['MEETINGS'],
    }),
    cancelP2PInsightsMeeting: builder.mutation<void, P2PInsightsCancelMeetingParams>({
      query: ({ cancelReason, meetingId }) => ({
        url: `/p2pi/meeting/${meetingId}/cancel`,
        method: 'POST',
        data: { cancelReason },
      }),
      invalidatesTags: ['MEETINGS'],
    }),
    updateP2PInsightMeetingAnswers: builder.mutation<void, WithP2PInsightMeetingId<{ answers: CreateAnswerRequest[] }>>(
      {
        query: ({ meetingId, answers }) => ({
          url: `/p2pi/meeting/${meetingId}/answers`,
          method: 'POST',
          data: answers,
        }),
        invalidatesTags: (_result, _error, { meetingId }) => [{ type: 'MEETINGS', id: meetingId }],
      }
    ),
  }),
});

export const {
  useGetP2PInsightByIdQuery,
  useGetP2PInsightsQuery,
  useGetP2PInsightsUsersQuery,
  useAddP2PAttendeesMutation,
  useGetP2PInsightsMeetingsQuery,
  useCreateP2PInsightsMutation,
  useGetP2PInsightsMeetingByIdQuery,
  useRescheduleP2PInsightsMeetingMutation,
  useCancelP2PInsightsMeetingMutation,
  useUpdateP2PInsightMeetingAnswersMutation,
} = p2pInsightApi;
