import React, { FC, ReactElement } from 'react';

import { Stack, Typography } from '@mui/material';

import { Button } from '../Buttons';

type EmptyStateProps = {
  title: string;
  description: string;
  buttonLabel?: string;
  href?: string;
  onClick?(): void;
  icon?: ReactElement;
};

export const EmptyState: FC<EmptyStateProps> = ({ title, description, buttonLabel, href, icon, onClick }) => (
  <Stack gap={2} py={6} px={2} alignItems="center">
    {icon}
    <Typography variant="natter-text-xl">{title}</Typography>
    <Typography variant="natter-text-sm" color="text.secondary" textAlign="center">
      {description}
    </Typography>

    {buttonLabel && (href || onClick) && (
      <Button href={href} onClick={onClick} sx={({ spacing }) => ({ px: spacing(12.5) })}>
        {buttonLabel}
      </Button>
    )}
  </Stack>
);
