import type { Components, Theme } from '@mui/material';

export const MuiToolbar: Components<Theme>['MuiToolbar'] = {
  styleOverrides: {
    root: {
      borderBottom: 'solid 1px transparent',
      alignItems: 'center',
      '@media all': {
        paddingLeft: 16,
        paddingRight: 16,

        minHeight: 64,
      },
    },
  },
};
