import { isFeatureEnabled } from 'common/utils';

import { EventPreview as EventPreviewV1 } from './EventPreview';
import { EventPreview as EventPreviewV2 } from './EventPreviewV2';

export * from './EventActions';
export * from './EventScheduledTime';
export * from './EventSponsors';
export * from './EventStatusBadge';

export const EventPreview = isFeatureEnabled('MyEventsList') ? EventPreviewV2 : EventPreviewV1;
