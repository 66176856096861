import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import IconCommunityManagerButton from 'community/components/icons/IconCommunityManagerButton';

type ShowCommunityManagersProps = {
  onClick(): void;
  isSelected: boolean;
};

export const ShowCommunityManagers: FC<ShowCommunityManagersProps> = ({ isSelected, onClick }) => (
  <Stack
    direction="row"
    alignItems="center"
    onClick={onClick}
    sx={({ palette }) => ({
      userSelect: 'none',
      color: isSelected ? palette.primary.main : undefined,
      cursor: 'pointer',
      '&:hover': {
        color: palette.primary.main,
      },
    })}
  >
    <IconCommunityManagerButton active contained={isSelected} width={20} color="currentColor" />
    <Typography variant="button" marginLeft={1}>
      Show Community Managers
    </Typography>
  </Stack>
);
