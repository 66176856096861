import { dataURItoFile } from 'common/utils';
import { format, isAfter, isFirstDayOfMonth, isLastDayOfMonth } from 'date-fns';
import { EventQuestionType, EventRoomAttendee, Topic } from 'domain/event';
import { InsightState } from 'pages/P2PInsights/constants/InsightState';
import {
  P2PInsightCreateBody,
  P2PInsightDetails,
  P2PInsightDetailsApiPayload,
  P2PInsightFormValues,
  P2PInsightGetMeetingByIdData,
  P2PInsightInfoApiPayload,
  P2PInsightUserInfo,
} from 'pages/P2PInsights/types';
import { P2PInsightsQuestionAssignmentType } from 'pages/P2PInsights/types/v2';
import { GetP2PInsightsMeetingByIdPayload } from 'store/apis/p2pInsight/types';

import {
  groupP2PInsightQuestionsByAssignmentType,
  mapFormQuestionToCreateQuestionRequest,
  mapP2PApiQuestionToFormEventQuestion,
  mapP2PInsightQuestion,
} from './questions';

export const mapP2PInsightUserToEventRoomAttendee = (p2piUser: P2PInsightUserInfo): EventRoomAttendee => ({
  avatarLink: p2piUser.avatarLink ?? '',
  id: p2piUser.userId,
  name: p2piUser.displayName,
  role: p2piUser.role,
  tagsCategories: p2piUser.communityTagCategories,
});

const DATE_FORMAT = 'yyyy-MM-dd';

const calculateP2pInsightState = (insightData: { endDate: string; startDate: string; meetingsScheduled: number }) => {
  if (isAfter(new Date(), new Date(insightData.endDate))) {
    return InsightState.Finished;
  }
  if (isAfter(new Date(), new Date(insightData.startDate))) {
    return InsightState.Started;
  }
  return InsightState.Published;
};

export const mapP2PInsightV1ToApi = (communityId: number, data: P2PInsightFormValues) => {
  const formData = new FormData();
  const request = {
    communityId,
    name: data.name,
    description: data.description,
    startDate: data.date?.[0].startDate && format(data.date[0].startDate, DATE_FORMAT),
    endDate: data.date?.[0].endDate && format(data.date[0].endDate, DATE_FORMAT),
    meetingDurationMinutes: data.durationInMinutes,
    questions: [
      mapFormQuestionToCreateQuestionRequest(data.inCallInsight),
      ...data.feedbackQuestions.map(mapFormQuestionToCreateQuestionRequest),
      ...data.preEventQuestions.map(mapFormQuestionToCreateQuestionRequest),
    ],
    seniorLeaderName: data.seniorLeader?.name,
    seniorLeaderRole: data.seniorLeader?.role,
  } as P2PInsightCreateBody;
  const requestData = new Blob([JSON.stringify(request)], {
    type: 'application/json',
  });
  formData.append('request', requestData);
  if (data.seniorLeader?.userAvatar?.data) {
    formData.append('image', dataURItoFile(data.seniorLeader?.userAvatar.data, 'userImage'));
  }

  return formData;
};

export const mapP2PInsightToApi = (communityId: number, data: P2PInsightFormValues) => {
  const formData = new FormData();
  const request = {
    communityId,
    name: data.name,
    description: data.description,
    startDate: data.date?.[0].startDate && format(data.date[0].startDate, DATE_FORMAT),
    endDate: data.date?.[0].endDate && format(data.date[0].endDate, DATE_FORMAT),
    meetingDurationMinutes: data.durationInMinutes,
    questions: [
      mapP2PInsightQuestion(data.inCallInsight, P2PInsightsQuestionAssignmentType.Topic),
      ...data.feedbackQuestions.map((q) => mapP2PInsightQuestion(q, P2PInsightsQuestionAssignmentType.AfterMeeting)),
      ...data.preEventQuestions.map((q) => mapP2PInsightQuestion(q, P2PInsightsQuestionAssignmentType.BeforeMeeting)),
    ],
    seniorLeaderName: data.seniorLeader?.name,
    seniorLeaderRole: data.seniorLeader?.role,
    // TODO Cleanup types in NAT-4623
  } as unknown as P2PInsightCreateBody;
  const requestData = new Blob([JSON.stringify(request)], { type: 'application/json' });
  formData.append('request', requestData);
  if (data.seniorLeader?.userAvatar?.data) {
    formData.append('image', dataURItoFile(data.seniorLeader?.userAvatar.data, 'userImage'));
  }

  return formData;
};

export const mapP2PInsightsFromApi = (insights: P2PInsightInfoApiPayload[]) =>
  insights.map((insight) => ({
    id: insight.id,
    title: insight.name,
    inCallInsight: insight.topic,
    seniorLeader: insight.seniorLeader,
    startDate: insight.startDate,
    endDate: insight.endDate,
    state: calculateP2pInsightState({
      endDate: insight.endDate,
      startDate: insight.startDate,
      meetingsScheduled: insight.numberOfMeetings,
    }),
    numberOfMeetings: insight.numberOfMeetings,
  }));

export const mapP2PInsightFromApi = (insight: P2PInsightDetailsApiPayload) => ({
  ...insight,
  state: calculateP2pInsightState({
    endDate: insight.endDate,
    startDate: insight.startDate,
    meetingsScheduled: insight.meetingTotals.scheduled,
  }),
});

export const mapP2PInsightUsersToEventRoomAttendees = (communityMembers: P2PInsightUserInfo[]): EventRoomAttendee[] =>
  communityMembers.map((member) => ({
    avatarLink: member.avatarLink ?? '',
    handRaised: false,
    id: member.userId,
    isDisconnected: false,
    name: member.displayName,
    role: member.role,
    tagsCategories: member.communityTagCategories,
    isConnected: true,
  }));

export const mapP2PInsightMeetingFromApi = (data: GetP2PInsightsMeetingByIdPayload): P2PInsightGetMeetingByIdData => {
  const questions = groupP2PInsightQuestionsByAssignmentType(data.questions);
  return {
    ...data,
    ...questions,
    topic: questions.topics.map<Topic>((t) => ({
      id: t.question.questionId,
      value: t.question.title,
    }))[0],
    // TODO Cleanup types in NAT-4623
  } as P2PInsightGetMeetingByIdData;
};

export const mapToFormValues = (insightData: P2PInsightDetails): P2PInsightFormValues => ({
  name: insightData.name,
  isFullMonth: isFirstDayOfMonth(new Date(insightData.startDate)) && isLastDayOfMonth(new Date(insightData.endDate)),
  seniorLeader: {
    avatarLink: insightData.seniorLeader.imageUrl,
    name: insightData.seniorLeader.name,
    role: insightData.seniorLeader.role,
  },
  date: [
    {
      startDate: new Date(insightData.startDate),
      endDate: new Date(insightData.endDate),
    },
  ],
  description: insightData.description,
  durationInMinutes: insightData.meetingDuration === 'PT15M' ? 15 : 30,
  preEventQuestions: insightData.questions
    .filter(({ questionType }) => questionType === EventQuestionType.PreEvent)
    .map(mapP2PApiQuestionToFormEventQuestion),
  feedbackQuestions: insightData.questions
    .filter(({ questionType }) => questionType === EventQuestionType.PreEvent)
    .map(mapP2PApiQuestionToFormEventQuestion),
  inCallInsight: mapP2PApiQuestionToFormEventQuestion(insightData.topic),
});
