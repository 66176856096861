import React, { FC } from 'react';

import { Box } from '@mui/material';

import { ReportCardTitle } from './ReportCard.styled';

type Props = {
  title: string;
};

export const ReportCard: FC<Props> = ({ title, children }) => (
  <Box p={3} component="section">
    <ReportCardTitle>{title}</ReportCardTitle>

    {children}
  </Box>
);
