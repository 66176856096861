import React, { FC } from 'react';

import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

type SearchInputProps = { label?: string } & Pick<TextFieldProps, 'value' | 'onChange' | 'size' | 'sx' | 'className'>;

export const SearchInput: FC<SearchInputProps> = ({ value, onChange, label = 'Search', size, sx, ...props }) => (
  <TextField
    autoFocus
    size={size}
    variant="outlined"
    value={value}
    onChange={onChange}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <FontAwesomeIcon size="lg" icon={faSearch} />
        </InputAdornment>
      ),
    }}
    inputProps={{ 'aria-label': label }}
    placeholder={label}
    data-testid={`SearchInput-${label}`}
    sx={sx}
    {...props}
  />
);
