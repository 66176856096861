import React, { FC } from 'react';

import EditIcon from '@mui/icons-material/EditOutlined';
import { IconButtonWithTooltip } from 'common/components';

type Props = {
  onEdit(): void;
};

export const EventEditButton: FC<Props> = ({ onEdit: handleEdit }) => (
  <IconButtonWithTooltip
    title="Edit Event"
    size="medium"
    color="secondary"
    onClick={handleEdit}
    aria-label="Edit Event"
  >
    <EditIcon fontSize="small" />
  </IconButtonWithTooltip>
);
