import React, { FC } from 'react';

import { Menu, MenuItemType } from 'common/components';

type Props = {
  anchorEl: HTMLButtonElement | null;
  onClose(): void;
  items: MenuItemType[];
};

export const CommunitySelectorMenu: FC<Props> = ({ items, anchorEl, onClose }) => (
  <Menu
    sx={{ maxWidth: 300, mt: -3 }}
    anchor={anchorEl}
    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
    items={items}
    onClose={onClose}
  />
);
