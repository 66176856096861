import React, { FC } from 'react';

import { useToggleState } from 'common/hooks/useToggleState/useToggleState';
import { isBrowserError, isBrowserSupported } from 'common/utils';

import { ChromeWarningModal } from './ChromeWarningModal';

export const ChromeWarningModalContainer: FC = () => {
  const [displayChromeWarningModal, toggleDisplayChromeWarningModal] = useToggleState(!isBrowserSupported);

  return (
    <ChromeWarningModal
      shouldDisplay={displayChromeWarningModal}
      onClose={() => toggleDisplayChromeWarningModal(false)}
      isBrowserError={isBrowserError}
    />
  );
};
