import React, { FC, useEffect } from 'react';

import { UserTagsModal } from 'community/components';
import { useUserTagsCategories } from 'community/hooks';
import { TagsCategory } from 'domain/Common';
import { useAppSelector } from 'store';
import {
  selectCurrentCommunityMember,
  selectMyCommunityId,
  useUpdateCurrentCommunityMemberMutation,
} from 'store/apis/community';

interface MemberTagsDialogProps {
  open: boolean;
  handleClose(): void;
}
export const MemberTagsDialog: FC<MemberTagsDialogProps> = ({ open, handleClose }) => {
  const communityMember = useAppSelector(selectCurrentCommunityMember);
  const communityId = useAppSelector(selectMyCommunityId);
  const [updateCurrentCommunityMember, { isLoading, isSuccess, reset: resetUpdateCurrentCommunityMember }] =
    useUpdateCurrentCommunityMemberMutation();
  const { userTagsCategories } = useUserTagsCategories();

  const handleConfirm = (tagsCategories: TagsCategory[]) => {
    updateCurrentCommunityMember({
      // @ts-expect-error add check that communityId is not undefined
      communityId,
      data: {
        tagsCategories,
      },
      currentData: communityMember,
    });
  };

  useEffect(() => {
    if (!open) {
      resetUpdateCurrentCommunityMember();
    }
  }, [open, resetUpdateCurrentCommunityMember]);

  return (
    <UserTagsModal
      title="Change Tags"
      description="Select the Tags that match your profile, interests or areas of expertise"
      handleClose={handleClose}
      open={open}
      tagsCategories={userTagsCategories}
      isLoading={isLoading}
      isSuccess={isSuccess}
      onUpdateTags={handleConfirm}
    />
  );
};
