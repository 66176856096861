import { useEffect, useState } from 'react';

import { intervalToDuration, isAfter, isBefore } from 'date-fns';

export const useEventTimeCounter = (startDate: Date) => {
  const now = new Date();
  const [countdownEnded, setCountdownEnded] = useState(isAfter(now, startDate));
  const [countdown, setCountdown] = useState(intervalToDuration({ start: now, end: startDate }));

  useEffect(() => {
    const interval = setInterval(() => {
      const from = new Date();
      const duration = intervalToDuration({ start: from, end: startDate });

      if (isBefore(startDate, from)) {
        setCountdownEnded(true);
        clearInterval(interval);
      } else {
        setCountdown(duration);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startDate]);

  return { countdown, countdownEnded };
};
