import React, { FC } from 'react';

import { useCopyToClipboard } from 'common/hooks';
import { generateEventUrl } from 'event/utils';
import { useAppDispatch } from 'store';
import { addAppErrorMessage, addAppInfoMessage } from 'store/features/alerts';

import { EventCopyLinkButton, EventCopyLinkButtonProps } from './EventCopyLinkButton';

type Props = Pick<EventCopyLinkButtonProps, 'variant'> & { eventId: number };

export const EventCopyLinkButtonContainer: FC<Props> = ({ eventId, variant }) => {
  const dispatch = useAppDispatch();
  const publicEventUrl = generateEventUrl(eventId);

  const { copyToClipboard: handleCopyLink } = useCopyToClipboard({
    value: publicEventUrl,
    onSuccess: () => dispatch(addAppInfoMessage('Link copied to clipboard')),
    onError: () => dispatch(addAppErrorMessage('Could not copy to clipboard. Please try again.')),
  });

  return <EventCopyLinkButton variant={variant} onCopyLink={handleCopyLink} />;
};
