import React, { FC } from 'react';

import { useCopyToClipboard } from 'common/hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { selectEventPin } from 'store/apis/event';
import { addAppErrorMessage, addAppInfoMessage } from 'store/features/alerts';

import { EventCopyPinButton } from './EventCopyPinButton';

export const EventCopyPinButtonContainer: FC = () => {
  const dispatch = useAppDispatch();
  const pin = useAppSelector(selectEventPin);

  const { copyToClipboard } = useCopyToClipboard({
    value: pin!,
    onSuccess: () => dispatch(addAppInfoMessage('Event PIN copied')),
    onError: () => dispatch(addAppErrorMessage('Could not copy to clipboard. Please try again.')),
  });

  if (!pin) return null;

  return <EventCopyPinButton onCopy={copyToClipboard} />;
};
