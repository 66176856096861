import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { Stack, SvgIconTypeMap, styled } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { blue, green, grey, orange, red } from 'common/theme/palette';

import { AlertVariant } from './types';

export const iconsMap: Record<AlertVariant, OverridableComponent<SvgIconTypeMap<{}, 'svg'>>> = {
  [AlertVariant.Success]: CheckCircleIcon,
  [AlertVariant.Error]: ErrorIcon,
  [AlertVariant.Warning]: WarningIcon,
  [AlertVariant.Info]: InfoIcon,
  [AlertVariant.InfoGray]: InfoIcon,
  [AlertVariant.Transparent]: InfoOutlinedIcon,
};

export const AlertWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{
  variant: AlertVariant;
}>(({ theme, variant }) => ({
  flexDirection: 'row',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: 1,
  ...(variant === AlertVariant.Info && {
    backgroundColor: blue[50],

    '.MuiSvgIcon-root': {
      color: blue[600],
    },
  }),
  ...(variant === AlertVariant.Error && {
    backgroundColor: red[50],
    color: 'inherit',

    '& .MuiSvgIcon-root': {
      color: red[600],
    },
  }),
  ...(variant === AlertVariant.Warning && {
    backgroundColor: orange[50],
    color: 'inherit',

    '& .MuiSvgIcon-root': {
      color: orange[600],
    },
  }),
  ...(variant === AlertVariant.InfoGray && {
    backgroundColor: grey[50],
    color: 'inherit',

    '& .MuiSvgIcon-root': {
      color: grey[800],
    },
  }),
  ...(variant === AlertVariant.Success && {
    backgroundColor: green[50],
    color: 'inherit',

    '& .MuiSvgIcon-root': {
      color: green[600],
    },
  }),
  ...(variant === AlertVariant.Transparent && {
    backgroundColor: 'white',
    color: 'inherit',
    borderColor: grey[200],
    border: '1px solid',

    '& .MuiSvgIcon-root': {
      color: grey[800],
    },
  }),
}));
