import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { communityApi } from '../community';
import { userApi } from '../user';
import { masterAdminApi } from './masterAdminApi';

export const masterAdminListener = createListenerMiddleware<RootState>();

masterAdminListener.startListening({
  matcher: masterAdminApi.endpoints.updateCommunity.matchFulfilled,
  effect: (_args, { dispatch }) => {
    dispatch(communityApi.util.invalidateTags(['MY_COMMUNITIES']));
  },
});

masterAdminListener.startListening({
  matcher: masterAdminApi.endpoints.joinOrganization.matchFulfilled,
  effect: (_args, { dispatch }) => {
    dispatch(userApi.util.invalidateTags(['ACCOUNT']));
  },
});
