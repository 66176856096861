import React, { FC } from 'react';

import { EventSponsor } from 'domain/event';

import { EventSponsorImage, EventSponsorLink, EventSponsorsWrapper } from './EventSponsors.styled';

type EventSponsorsProps = {
  sponsors: EventSponsor[];
};

export const EventSponsors: FC<EventSponsorsProps> = ({ sponsors }) => (
  <EventSponsorsWrapper>
    {sponsors.map((sponsor, index) => (
      <EventSponsorLink key={index} href={sponsor.url} rel="noopener noreferrer" target="_blank">
        <EventSponsorImage src={sponsor.image.src} alt="Sponsor logo" />
      </EventSponsorLink>
    ))}
  </EventSponsorsWrapper>
);
