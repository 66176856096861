import { ReactNode } from 'react';

type MenuItemWithComponent = {
  component: ReactNode;
  onClick(): void;
  disabled?: boolean;
};

export type MenuItemWithText = {
  text: string;
  color?: string;
  icon?: ReactNode;
  onClick?(): void;
  disabled?: boolean;
  direction?: 'left' | 'right';
};

export type NestedMenuItemWithText = MenuItemWithText & {
  nestedItems: MenuItemType[];
};

export type MenuItemType = MenuItemWithComponent | MenuItemWithText | NestedMenuItemWithText;

export const isMenuItemWithText = (item: MenuItemType): item is MenuItemWithText =>
  (item as MenuItemWithText).text !== undefined;

export const isMenuItemWithComponent = (item: MenuItemType): item is MenuItemWithComponent =>
  (item as MenuItemWithComponent).component !== undefined;

export const isNestedMenuItemWithText = (item: MenuItemType): item is NestedMenuItemWithText =>
  isMenuItemWithText(item) && (item as NestedMenuItemWithText).nestedItems !== undefined;
