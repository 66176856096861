import { createApi, retry } from '@reduxjs/toolkit/query/react';
import type { ImageFile } from 'common/components/_legacy/Form';
import { ERROR_MESSAGES } from 'common/constants';
import { assert, getImageFormData } from 'common/utils';
import type { BasicPaginationParams, PaginatedApiPayload, PaginatedApiPayloadV2 } from 'domain/Common';
import type {
  Community,
  CommunityFormValues,
  CommunityGuest,
  CommunityMember,
  CommunityRolesPrivileges,
  UpdateCommunityData,
} from 'domain/Community';
import type { OrganizationMember, OrganizationMemberApiPayload } from 'domain/Organization';
import type { UserPrivilege, UserRole } from 'domain/UserProfile';
import { map } from 'lodash';
import { mapCommunityToApi } from 'mappers/community';
import { mapOrganizationMemberFromApi } from 'mappers/organization';
import { getApiBaseUrl, mapToApiParams, transformPaginatedApiPayload } from 'modules/api/utils';
import { AppDispatch } from 'store';
import { addAppSuccessMessage } from 'store/features/alerts';
import { handleQueryError } from 'store/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import { GetCommunityMembers, GetCommunityMembersParams, communityApi } from '../community';
import type {
  GetManagedOrganizationApiPayload,
  GetOrganizationMembersParams,
  OrganizationMemberFormData,
  WithOrganizationId,
} from './types';

const handleMasterAdminApiQueryError = (
  error: unknown,
  dispatch: AppDispatch,
  action: string,
  message: string,
  showAlertDialog?: boolean
) => handleQueryError(error, dispatch, `masterAdminApi.${action}`, message, showAlertDialog);

export const organizationIdErrorMessage = 'Organization ID required';

// TODO: rename masterAdminApi to organizationApi
export const masterAdminApi = createApi({
  reducerPath: 'masterAdminApi',
  tagTypes: ['COMMUNITIES', 'COMMUNITY', 'COMMUNITY_MEMBERS', 'ORGANIZATION_MEMBERS'],
  baseQuery: retry(axiosBaseQuery(), { maxRetries: 0 }),
  endpoints: (builder) => ({
    getManagedOrganizations: builder.query<GetManagedOrganizationApiPayload, void>({
      query: () => '/organization/managed',
    }),
    getOrganizationMembers: builder.query<PaginatedApiPayloadV2<OrganizationMember>, GetOrganizationMembersParams>({
      providesTags: (result) =>
        result?.data
          ? result.data.map(({ userId }) => ({ type: 'ORGANIZATION_MEMBERS', id: userId }))
          : ['ORGANIZATION_MEMBERS'],
      query: ({ organizationId, searchPhrase, page, pageSize, sortBy, sortOrder }) => ({
        url: `/organization/${organizationId}/members`,
        params: {
          page,
          size: pageSize,
          sortParam: sortBy,
          sortDir: sortOrder,
          searchText: searchPhrase || undefined,
        },
      }),
      transformResponse: ({
        organizationMembers,
      }: {
        organizationMembers: PaginatedApiPayloadV2<OrganizationMemberApiPayload>;
      }) => ({
        data: organizationMembers.data.map(mapOrganizationMemberFromApi),
        totalCount: organizationMembers.totalCount,
      }),
    }),
    removeOrganizationMember: builder.mutation<void, { userId: number; organizationId: number | undefined }>({
      invalidatesTags: ['ORGANIZATION_MEMBERS'],
      query: ({ organizationId, userId }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/remove-user`,
          method: 'DELETE',
          data: {
            userId,
          },
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addAppSuccessMessage('Organization Member removed successfully'));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(
            error,
            dispatch,
            'removeOrganizationMember',
            'Unable to remove Organization Member'
          );
        }
      },
    }),
    inviteOrganizationMember: builder.mutation<void, OrganizationMemberFormData>({
      query: ({ organizationId, email, role, communityIds }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/invite-user`,
          method: 'POST',
          data: {
            email,
            role,
            communityIds,
          },
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addAppSuccessMessage('Invitation has been sent'));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(
            error,
            dispatch,
            'inviteGuestsToCommunity',
            'Unable to invite Member to organization'
          );
        }
      },
    }),
    updateOrganizationMemberAccess: builder.mutation<void, OrganizationMemberFormData>({
      invalidatesTags: (_result, _error, { userId }) => [{ type: 'ORGANIZATION_MEMBERS', id: userId }],
      query: ({ organizationId, userId, role, communityIds }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/update-access`,
          method: 'POST',
          data: {
            userId,
            role,
            communityIds,
          },
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addAppSuccessMessage('Member access updated'));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(error, dispatch, 'inviteOrganizationMember', 'Unable to update Member access');
        }
      },
    }),
    joinOrganization: builder.mutation<void, { organizationId: number | undefined }>({
      query: ({ organizationId }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/join`,
          method: 'POST',
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addAppSuccessMessage('Successfully joined organization'));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(error, dispatch, 'joinOrganization', 'Unable to join organization');
        }
      },
    }),
    createCommunity: builder.mutation<Community, { values: CommunityFormValues } & WithOrganizationId>({
      invalidatesTags: ['COMMUNITIES'],
      query: ({ organizationId, values }) => {
        assert(organizationId, organizationIdErrorMessage);
        const data = mapCommunityToApi(values);
        return {
          url: `/organization/${organizationId}/community`,
          method: 'POST',
          data,
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(communityApi.util.invalidateTags(['MY_COMMUNITIES']));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(error, dispatch, 'createCommunity', 'Unable to create Community');
        }
      },
    }),
    getCommunitiesList: builder.query<PaginatedApiPayload<Community>, BasicPaginationParams & WithOrganizationId>({
      providesTags: (result) =>
        result?.data.length ? result.data.map(({ id }) => ({ type: 'COMMUNITIES', id })) : ['COMMUNITIES'],
      query: ({ organizationId, searchPhrase, page, pageSize, sortBy, sortOrder }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/communities`,
          params: {
            keywords: searchPhrase,
            page,
            size: pageSize,
            sortParam: sortBy,
            sortDir: sortOrder,
          },
        };
      },
      transformResponse: transformPaginatedApiPayload,
    }),
    getCommunityById: builder.query<Community, { communityId: number }>({
      providesTags: (result) => (result ? [{ type: 'COMMUNITY', id: result.id }] : []),
      query: ({ communityId }) => `/community/${communityId}`,
    }),
    getCommunityRoles: builder.query<CommunityRolesPrivileges[], { communityId: number }>({
      query: ({ communityId }) => `/community/${communityId}/roles`,
      transformResponse: (result: { roles: CommunityRolesPrivileges[] }) => result.roles,
    }),
    transferCommunity: builder.mutation<
      void,
      { communityId: number; organizationOwnerEmail: string } & WithOrganizationId
    >({
      invalidatesTags: (_result, error, { communityId }) =>
        error ? [] : ['COMMUNITIES', { type: 'COMMUNITY', id: communityId }],
      query: ({ organizationId, communityId, organizationOwnerEmail }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/transfer`,
          method: 'PATCH',
          data: {
            organizationOwnerEmail,
            communityId,
          },
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addAppSuccessMessage(`Community has been successfully transferred`));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(
            error,
            dispatch,
            'transferCommunity',
            'Transfer error occurred. Please check the email address or try again.',
            true
          );
        }
      },
    }),
    updateCommunity: builder.mutation<Community, UpdateCommunityData>({
      invalidatesTags: (_result, _error, arg) => [
        { type: 'COMMUNITIES', id: arg.id },
        { type: 'COMMUNITY', id: arg.id },
      ],
      query: (data) => ({
        url: `/community/${data.id}`,
        method: 'PUT',
        data: mapCommunityToApi(data),
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addAppSuccessMessage('Community details updated successfully'));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(error, dispatch, 'updateCommunity', 'Unable to update Community');
        }
      },
    }),
    uploadCommunityLogo: builder.mutation<
      Pick<Community, 'logoImageLink' | 'logoImageLinkSmall'>,
      { logo: ImageFile; communityId: number }
    >({
      invalidatesTags: (_result, _error, { communityId }) =>
        _error
          ? []
          : [
              { type: 'COMMUNITIES', id: communityId },
              { type: 'COMMUNITY', id: communityId },
            ],
      query: ({ communityId, logo }) => ({
        url: `/community/${communityId}/uploadLogo`,
        method: 'POST',
        data: getImageFormData(logo),
      }),
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(error, dispatch, 'uploadLogo', 'Unable to upload Community logo');
        }
      },
    }),
    deleteCommunity: builder.mutation<void, { communityId: number } & WithOrganizationId>({
      invalidatesTags: (_result, _error, { communityId }) =>
        _error ? [] : ['COMMUNITIES', { type: 'COMMUNITY', id: communityId }],
      query: async ({ organizationId, communityId }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/community/${communityId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addAppSuccessMessage(`Community has been successfully deleted.`));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(error, dispatch, 'deleteCommunity', 'Unable to delete Community');
        }
      },
    }),
    getCommunityMembers: builder.query<PaginatedApiPayload<CommunityMember>, GetCommunityMembersParams>({
      providesTags: (result) => (result?.data ? result.data.map(({ id }) => ({ type: 'COMMUNITY_MEMBERS', id })) : []),
      query: ({ page, pageSize, sortBy, sortOrder, showManagers, tags, searchPhrase, communityId, roles }) => ({
        baseURL: getApiBaseUrl('v2'),
        url: `/community/${communityId}/members`,
        params: mapToApiParams({
          page,
          size: pageSize,
          sortParam: sortBy,
          sortDir: sortOrder,
          displayOnlyManagers: showManagers,
          includeCallingUser: true,
          tagIds: map(tags, 'id'),
          roleIds: map(roles, 'id'),
          searchText: searchPhrase.length ? searchPhrase : undefined,
        }) as GetCommunityMembers,
      }),
      transformResponse: transformPaginatedApiPayload,
    }),
    getMemberPrivileges: builder.query<
      {
        communityMemberId: number;
        privileges: UserPrivilege[];
      },
      { communityId: number; memberId: number | undefined }
    >({
      query: ({ communityId, memberId }) => {
        assert(memberId, 'Member ID required');
        return `/community/${communityId}/members/${memberId}/privileges`;
      },
    }),
    updateCommunityMemberRole: builder.mutation<
      void,
      {
        communityId: number | undefined;
        communityMemberId: number;
        role: UserRole;
        privileges: UserPrivilege[];
      }
    >({
      invalidatesTags: (_result, error, { communityMemberId }) =>
        communityMemberId && !error ? [{ type: 'COMMUNITY_MEMBERS', id: communityMemberId }] : [],
      query: ({ communityId, communityMemberId, role, privileges }) => {
        assert(communityId, ERROR_MESSAGES.noCommunityId);
        return {
          url: `/community/${communityId}/members/${communityMemberId}/role`,
          method: 'PATCH',
          data: {
            role,
            privileges,
          },
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addAppSuccessMessage('Community Member updated successfully'));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(
            error,
            dispatch,
            'updateCommunityMemberRole',
            'Unable to updated Community Member'
          );
        }
      },
    }),
    removeCommunityMember: builder.mutation<void, { memberId: number; communityId: number | undefined }>({
      query: ({ communityId, memberId }) => {
        assert(communityId, ERROR_MESSAGES.noCommunityId);
        return {
          url: `/community/${communityId}/${memberId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['COMMUNITY_MEMBERS'],
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addAppSuccessMessage('Community Member removed successfully'));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(error, dispatch, 'removeCommunityMember', 'Unable to remove Community Member');
        }
      },
    }),
    inviteGuestsToCommunity: builder.mutation<void, { guests: CommunityGuest[]; communityId: number | undefined }>({
      query: ({ communityId, guests }) => {
        assert(communityId, ERROR_MESSAGES.noCommunityId);
        return {
          url: `/community/${communityId}/members`,
          method: 'PUT',
          data: guests,
        };
      },
      invalidatesTags: ['COMMUNITY_MEMBERS'],
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addAppSuccessMessage('Invitation has been sent'));
        } catch (error: unknown) {
          handleMasterAdminApiQueryError(
            error,
            dispatch,
            'inviteGuestsToCommunity',
            'Unable to invite guests to Community'
          );
        }
      },
    }),
  }),
});

export const {
  useCreateCommunityMutation,
  useGetOrganizationMembersQuery,
  useRemoveOrganizationMemberMutation,
  useGetCommunitiesListQuery,
  useLazyGetCommunitiesListQuery,
  useGetCommunityByIdQuery,
  useGetCommunityRolesQuery,
  useTransferCommunityMutation,
  useDeleteCommunityMutation,
  useGetManagedOrganizationsQuery,
  useUpdateCommunityMemberRoleMutation,
  useGetMemberPrivilegesQuery,
  useUpdateCommunityMutation,
  useUploadCommunityLogoMutation,
  useGetCommunityMembersQuery,
  useRemoveCommunityMemberMutation,
  useInviteGuestsToCommunityMutation,
  useInviteOrganizationMemberMutation,
  useUpdateOrganizationMemberAccessMutation,
  useJoinOrganizationMutation,
} = masterAdminApi;
