import React, { FC } from 'react';

import { Stack } from '@mui/material';
import { EventStatus } from 'domain/event';
import { EventRepeatButtonContainer } from 'event/management/components/EventRepeatButton';
import { isVisibleForPublished, isVisibleForPublishedOrStarted } from 'event/management/utils';
import { ProtectedComponent } from 'modules/authorization/components';

import {
  EventCopyLinkButtonContainer,
  EventCopyPinButtonContainer,
  EventDeleteButtonContainer,
  EventEditButtonContainer,
} from './components';

export type EventActionsProps = {
  eventId: number;
  eventState: EventStatus;
  isOwned: boolean | undefined;
};

export const EventActions: FC<EventActionsProps> = ({ isOwned, eventId, eventState }) => {
  if (!isOwned && isVisibleForPublishedOrStarted(eventState)) return <EventCopyLinkButtonContainer eventId={eventId} />;

  return (
    <Stack flexDirection="row" gap={1}>
      <ProtectedComponent requiredPrivileges={['CREATE_EVENT']}>
        <EventRepeatButtonContainer variant="icon" />
      </ProtectedComponent>

      <EventCopyPinButtonContainer />

      {isVisibleForPublishedOrStarted(eventState) && <EventCopyLinkButtonContainer eventId={eventId} />}

      {isVisibleForPublished(eventState) && (
        <>
          <ProtectedComponent requiredPrivileges={['MODIFY_EVENT']}>
            <EventEditButtonContainer />
          </ProtectedComponent>
          <ProtectedComponent requiredPrivileges={['MODIFY_EVENT']}>
            <EventDeleteButtonContainer />
          </ProtectedComponent>
        </>
      )}
    </Stack>
  );
};
