import { styled } from '@mui/material';
import { drawerWidth } from 'common/components';
import { omitForwardedProps } from 'common/utils';

type Props = {
  open: boolean;
  withBackground?: boolean;
};

export const MainContainer = styled('div', {
  shouldForwardProp: omitForwardedProps<Props>('open', 'withBackground'),
})<Props>(({ theme: { transitions, breakpoints, palette }, open, withBackground }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  transition: transitions.create('margin', {
    easing: transitions.easing.sharp,
    duration: transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: transitions.create('margin', {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  [breakpoints.down('sm')]: {
    marginLeft: 0,
  },

  background: withBackground ? palette.grey[25] : undefined,
}));
