import { type Components, type Theme } from '@mui/material';

export const MuiTableSortLabel: Components<Theme>['MuiTableSortLabel'] = {
  styleOverrides: {
    root: ({ theme: { typography } }) => ({
      '& > p': {
        ...typography['natter-text-xs'],
        fontWeight: 600,
      },
    }),
  },
};
