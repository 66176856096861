import { useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntercom as useIntercomExternal } from 'react-use-intercom';

import { AppRoutes } from 'router';
import { useAppSelector } from 'store';
import { selectCurrentCommunityMember, selectMyCommunity, selectMyCommunityBrandingColor } from 'store/apis/community';

export const intercomBlacklistedRoutes = [
  AppRoutes.EventRoom,
  AppRoutes.P2PiMeeting,
  AppRoutes.JoinEvent,
  AppRoutes.EventReport,
  AppRoutes.SurveyReport,
  AppRoutes.InsightsReportSurveyById,
];

export const useIntercom = () => {
  const communityMember = useAppSelector(selectCurrentCommunityMember);
  const myCommunity = useAppSelector(selectMyCommunity);
  const { brandingColor } = myCommunity ?? {};
  const fallbackColor = useAppSelector(selectMyCommunityBrandingColor);
  const isCurrentRouteBlacklisted = useRouteMatch(intercomBlacklistedRoutes);
  const { boot, update } = useIntercomExternal();

  const start = useCallback(() => {
    boot({ actionColor: brandingColor ?? fallbackColor, name: communityMember?.displayName || '' });
  }, [boot, communityMember, brandingColor, fallbackColor]);

  const hide = useCallback(() => {
    update({
      hideDefaultLauncher: true,
    });
  }, [update]);

  const show = useCallback(() => {
    if (isCurrentRouteBlacklisted) return;

    update({
      hideDefaultLauncher: false,
    });
  }, [update, isCurrentRouteBlacklisted]);

  useEffect(() => {
    isCurrentRouteBlacklisted ? hide() : show();
  }, [isCurrentRouteBlacklisted, hide, show]);

  return { start, hide, show };
};
