import { type Components, type Theme } from '@mui/material';
import { isColorWhiteListed } from 'common/components/Buttons/Button/utils/isColorWhiteListed';

import { grey, red } from '../palette';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme: { palette, typography }, ownerState: { color } }) => ({
      textTransform: 'none',
      ...typography['natter-text-sm'],
      fontWeight: 500,
      flexShrink: 0,

      ...(color &&
        isColorWhiteListed(color) && {
          '&:disabled.MuiLoadingButton-loading': {
            backgroundColor: palette[color].main,

            '.MuiLoadingButton-loadingIndicator': {
              color: palette[color].contrastText,
            },
          },
        }),
    }),
    containedPrimary: ({ theme: { palette } }) => ({
      '&.Mui-focusVisible': {
        backgroundColor: palette.primary.main,
      },

      '&:active': {
        backgroundColor: palette.primary.main,
      },
    }),
    containedSecondary: ({ theme: { palette } }) => ({
      '&.Mui-focusVisible': {
        backgroundColor: grey[300],
      },

      '&:active': {
        backgroundColor: palette.common.white,
        border: `2px solid ${palette.primary.main}`,
      },
    }),
    containedError: {
      '&.Mui-focusVisible': {
        backgroundColor: red[700],
      },

      '&:active': {
        backgroundColor: red[800],
      },
    },
    outlined: ({ theme: { palette }, ownerState: { color } }) => ({
      ...(color && isColorWhiteListed(color) && { borderColor: palette[color].main }),

      '&:hover': {
        backgroundColor: palette.extendedPrimary[25],
      },

      '&.Mui-focusVisible': {
        backgroundColor: palette.extendedPrimary[50],
      },

      '&:active': {
        backgroundColor: palette.extendedPrimary[100],
      },
    }),
    text: ({ theme: { palette }, ownerState: { color } }) => ({
      color: color && isColorWhiteListed(color) ? palette[color].main : palette.grey[700],

      '&:active': {
        backgroundColor: palette.common.white,
      },

      '&:hover': {
        backgroundColor: palette.grey[200],
      },

      '&.Mui-focusVisible': {
        backgroundColor: palette.grey[300],
      },
    }),
    textSecondary: ({ theme: { palette } }) => ({
      '&:active': {
        borderColor: palette.primary.main,
      },
    }),
    textError: ({ theme: { palette } }) => ({
      '&:active': {
        borderColor: palette.error.main,
      },
    }),
    sizeLarge: ({ theme: { spacing, typography } }) => ({
      ...typography['natter-text-md'],

      height: 40,
      minWidth: 54,
      padding: spacing(1, 2),
    }),
    sizeMedium: ({ theme: { spacing, typography } }) => ({
      ...typography['natter-text-sm'],

      height: 32,
      minWidth: 52,
      padding: spacing(0.75, 2),
    }),
    sizeSmall: ({ theme: { spacing, typography } }) => ({
      ...typography['natter-text-xs'],

      height: 24,
      minWidth: 32,
      padding: spacing(0.5, 1),
    }),
    fullWidth: {
      flexShrink: 'initial',
    },
  },
  variants: [
    {
      props: { variant: 'light' },
      style: ({ theme: { palette } }) => ({
        backgroundColor: palette.extendedPrimary[25],
        color: palette.primary.main,

        '&:hover': {
          backgroundColor: palette.extendedPrimary[50],
        },

        '&.Mui-focusVisible': {
          backgroundColor: palette.extendedPrimary[100],
        },

        '&:active; &:focus': {
          backgroundColor: palette.extendedPrimary[200],
        },
      }),
    },
  ],
};

declare module '@mui/material/Button' {
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsColorOverrides {
    light?: true;
  }
  // eslint-disable-next-line no-unused-vars
  interface ButtonPropsVariantOverrides {
    light?: true;
  }
}
