import React, { ComponentProps, ElementType, FC, useState } from 'react';

import { Tooltip } from 'common/components/Tooltip';

import { InlineEditorInactive } from './InlineEditor.styled';
import { InlineEditorInput } from './components';

type Props = {
  component?: ElementType;
  noWrap?: boolean;
  disabled?: boolean;
} & ComponentProps<typeof InlineEditorInput>;

export const InlineEditor: FC<Props> = ({
  typographyVariant = 'natter-text-sm',
  defaultValue,
  component = 'div',
  noWrap = false,
  placeholder,
  disabled = false,
  ...inputProps
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const enterEditMode = () => !disabled && setIsEditMode(true);
  const exitEditMode = () => setIsEditMode(false);

  return isEditMode ? (
    <InlineEditorInput
      typographyVariant={typographyVariant}
      defaultValue={defaultValue}
      placeholder={placeholder}
      {...inputProps}
      onSubmit={(name) => {
        inputProps.onSubmit?.(name);
        exitEditMode();
      }}
      onCancel={() => {
        inputProps.onCancel?.();
        exitEditMode();
      }}
    />
  ) : (
    <Tooltip title={disabled ? '' : 'Rename'}>
      <InlineEditorInactive
        disabled={disabled}
        variant={typographyVariant}
        fontWeight="inherit"
        noWrap={noWrap}
        component={component}
        onClick={enterEditMode}
      >
        {defaultValue || placeholder}
      </InlineEditorInactive>
    </Tooltip>
  );
};
