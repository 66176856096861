import React, { FC } from 'react';

import { PublicPage } from 'common/components';
import { withTheme } from 'common/hocs';
import { useAppSelector } from 'store';
import { useGetPublicSurveyQuery } from 'store/apis/survey';
import { selectIdFromCurrentRoute } from 'store/features/router';

import { SurveyWelcomePage } from '../components';
import { SurveyGuard } from '../components/SurveyGuard';

const SurveyPublic: FC = () => {
  const id = useAppSelector(selectIdFromCurrentRoute);
  const { data: survey, isLoading, isError } = useGetPublicSurveyQuery({ id: id! }, { skip: id === undefined });

  return (
    <PublicPage>
      <SurveyGuard id={id} survey={survey} isLoading={isLoading} isError={isError} isCompleted={false}>
        {id && survey && <SurveyWelcomePage id={id} name={survey.name} welcomeMessage={survey.welcomeMessage} />}
      </SurveyGuard>
    </PublicPage>
  );
};

export const SurveyPublicPage = withTheme(SurveyPublic);
