import { type Components, type Theme, lighten } from '@mui/material';

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme: { palette, spacing, typography } }) => ({
      ...typography['natter-text-sm'],

      color: palette.grey[700],

      minWidth: 150,
      padding: spacing(1, 1.5),
      gap: spacing(1),

      '&:hover': {
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          width: 2,
          height: '100%',
          backgroundColor: palette.primary.main,
        },

        backgroundColor: palette.grey[100],
      },

      '& .MuiListItemIcon-root': {
        minWidth: 0,
      },

      '&.Mui-selected, &[aria-selected="true"]': {
        color: palette.primary.main,
        backgroundColor: lighten(palette.primary.main, 0.9),
      },

      '&.Mui-disabled': {
        color: palette.text.secondary,
        backgroundColor: 'unset',
      },
    }),
  },
};
