import React, { FC } from 'react';

import ContentCopy from '@mui/icons-material/ContentCopy';
import { Button, IconButtonWithTooltip } from 'common/components';

export type EventCopyLinkButtonProps = {
  variant?: 'icon' | 'text';
  onCopyLink(): void;
};

export const EventCopyLinkButton: FC<EventCopyLinkButtonProps> = ({
  variant = 'icon',
  onCopyLink: handleCopyLinkClick,
}) => {
  if (variant === 'icon')
    return (
      <IconButtonWithTooltip
        title="Copy link"
        size="medium"
        color="secondary"
        onClick={handleCopyLinkClick}
        aria-label="Copy link"
      >
        <ContentCopy fontSize="small" />
      </IconButtonWithTooltip>
    );

  return (
    <Button size="large" color="secondary" onClick={handleCopyLinkClick} aria-label="Copy link">
      Copy link
    </Button>
  );
};
