import React, { FC } from 'react';

import { Alert, Typography } from '@mui/material';
import { formatDuration } from 'date-fns';
import { DateISOString } from 'domain/Common';

import { useEventTimeCounter } from '../../hooks/useEventTimeCounter';

type Props = {
  startDate: DateISOString | Date;
};

export const EventTimeCounter: FC<Props> = ({ startDate }) => {
  const { countdown, countdownEnded } = useEventTimeCounter(new Date(startDate));

  if (countdownEnded) return null;

  return (
    <Alert severity="info" data-testid="EventTimeCounter" sx={{ width: 1, mb: 4 }}>
      <Typography component="span" variant="inherit" color="text.primary">
        This Event will start in{' '}
        <Typography component="span" variant="inherit" fontWeight={600}>
          {formatDuration(countdown)}
        </Typography>
      </Typography>
    </Alert>
  );
};
