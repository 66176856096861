import React, { FC } from 'react';

import { useTheme } from '@mui/material';

const IconCommunityManager: FC<{
  width: number | string;
  color?: string;
}> = ({ width, color }) => {
  const theme = useTheme();
  return (
    <svg width={width} style={{ minWidth: width }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill={color || theme.palette.custom.darkestGrey} />
      <g clipPath="url(#clip0_5764_6322)">
        <path
          d="M20 12.5L25 20L31.25 15L28.75 27.5H11.25L8.75 15L15 20L20 12.5Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5764_6322">
          <rect width="30" height="30" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCommunityManager;
