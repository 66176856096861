import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Range } from 'react-date-range';
import { useFormContext } from 'react-hook-form';

import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons';
import { Box, Chip, ClickAwayListener, Fade, Paper, Popper, Stack, Typography } from '@mui/material';
import { monthFormat } from 'common/utils';
import { endOfMonth, format, isSameMonth } from 'date-fns';

import { ReadableTextField } from '../../../Inputs';
import { Button } from '../../Button';

export const MonthInput: FC<{
  availableMonths: Date[];
  name: string;
  ignoredErrorTypes?: string[];
}> = ({ name, availableMonths, ignoredErrorTypes = [] }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { register, setValue, watch, getValues, getFieldState, clearErrors } = useFormContext();
  const value: Range[] | undefined = watch(name);
  const error = useMemo(() => {
    const newError = getFieldState(name).error;
    if (newError?.type && typeof newError.type === 'string' && !ignoredErrorTypes.includes(newError.type)) {
      return newError;
    }
  }, [getFieldState, name, ignoredErrorTypes]);
  const [previousValue, setPreviousValue] = useState<Range[] | undefined>();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleConfirm = () => {
    setAnchorEl(null);
    clearErrors(name);
  };

  const handleClearValue = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    clearErrors(name);
    setValue(name, undefined);
  };

  const handleCancel = () => {
    if (!anchorEl) {
      return;
    }
    setValue(name, previousValue);
    setAnchorEl(null);
  };

  const handleMonthSelected = useCallback(
    (date: Date) => {
      setValue(name, [
        {
          startDate: date,
          endDate: endOfMonth(date),
        },
      ] as Range[]);
    },
    [setValue, name]
  );

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    const date = getValues()[name];
    setPreviousValue(date);

    if (anchorEl && !date?.[0]?.startDate) {
      setValue(name, [
        {
          startDate: new Date(),
          endDate: endOfMonth(new Date()),
        },
      ] as Range[]);
    }
  }, [anchorEl, getValues, name, setValue]);

  return (
    <ClickAwayListener onClickAway={handleCancel}>
      <Box>
        <ReadableTextField
          label="Month"
          value={value?.[0]?.startDate ? format(value?.[0].startDate, monthFormat) : 'Select month'}
          icon={faCalendarWeek}
          isError={!!error}
          handleClearValue={handleClearValue}
          handleClick={handleClick}
        />
        {!!error && (
          <Typography variant="caption" color="error">
            {error.message}
          </Typography>
        )}
        <Popper open={!!anchorEl} anchorEl={anchorEl} transition sx={{ zIndex: 1301 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <Paper
                sx={({ shadows }) => ({
                  boxShadow: shadows[4],
                  borderRadius: 3,
                  overflow: 'hidden',
                  marginTop: 2,
                  padding: 4,
                })}
              >
                <Stack direction="row" justifyContent="center" flexWrap="wrap" gap={2} maxWidth={884}>
                  {availableMonths.map((date) => (
                    <Chip
                      onClick={() => handleMonthSelected(date)}
                      key={date.toDateString()}
                      sx={{ width: 134 }}
                      label={
                        <Stack alignItems="center">
                          <Typography variant="h3" fontSize="16px">
                            {format(date, 'MMMM')}
                          </Typography>
                          <Typography variant="h3" fontSize="16px">
                            {format(date, 'yyyy')}
                          </Typography>
                        </Stack>
                      }
                      variant={value?.[0]?.startDate && isSameMonth(value[0].startDate, date) ? 'filled' : 'outlined'}
                    />
                  ))}
                </Stack>
                <Stack alignItems="end" mt={4}>
                  <Button onClick={handleConfirm}>Select</Button>
                </Stack>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};
