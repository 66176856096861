import React, { FC } from 'react';

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import RocketOutlinedIcon from '@mui/icons-material/RocketOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Divider, List } from '@mui/material';
import { useBreakpoints } from 'common/hooks';
import { isFeatureEnabled } from 'common/utils';
import { UserPrivilegeName } from 'domain/UserProfile';
import { AppRoutes, Routes } from 'router';

import { NavigationLinkItem, NavigationLinkItemWithPrivilege } from '../NavigationLinkItem';

export const NavigationLinksList: FC = () => {
  const { isMobile } = useBreakpoints();

  return (
    <List sx={{ px: 1.5 }}>
      <NavigationLinkItemWithPrivilege
        privilegeAvailability={[UserPrivilegeName.VIEW_DASHBOARD]}
        icon={<DashboardOutlinedIcon />}
        label="Dashboard"
        to={Routes.CoDashboard}
        activePath={Routes.CoDashboard}
      />
      <NavigationLinkItem
        icon={<ManageAccountsOutlinedIcon />}
        label="Member Setup"
        to={AppRoutes.MemberSetup}
        activePath={AppRoutes.MemberSetup}
        visibleOnlyOnActivePath
      />
      <NavigationLinkItemWithPrivilege
        privilegeAvailability={[UserPrivilegeName.MODIFY_COMMUNITY]}
        icon={<RocketOutlinedIcon />}
        label="Community Wizard"
        to={Routes.CommunityCreator}
        activePath={Routes.CommunityCreator}
        visibleOnlyOnActivePath
      />
      <NavigationLinkItemWithPrivilege
        privilegeAvailability={[UserPrivilegeName.MANAGE_COMMUNITY_MEMBERS, UserPrivilegeName.VIEW_COMMUNITY_MEMBERS]}
        icon={<GroupsOutlinedIcon />}
        label="Members"
        to={Routes.MyCommunity}
        activePath={Routes.MyCommunity}
      />
      <NavigationLinkItemWithPrivilege
        privilegeAvailability={[UserPrivilegeName.VIEW_MY_EVENTS]}
        withNoCommunity
        icon={<CalendarTodayOutlinedIcon />}
        label="Events"
        to={Routes.MyEvents}
        activePath={Routes.MyEvents}
      />
      <NavigationLinkItemWithPrivilege
        privilegeAvailability={[UserPrivilegeName.MANAGE_P2P_INSIGHTS]}
        icon={<PeopleOutlinedIcon />}
        label="Natters"
        to={Routes.P2PInsights}
        activePath={[Routes.P2PInsights]}
      />
      <NavigationLinkItemWithPrivilege
        privilegeAvailability={[UserPrivilegeName.VIEW_MY_CONNECTIONS]}
        withNoCommunity
        icon={<HubOutlinedIcon />}
        label="My Connections"
        to={Routes.MyConnections}
        activePath={Routes.MyConnections}
      />
      <NavigationLinkItemWithPrivilege
        privilegeAvailability={[UserPrivilegeName.VIEW_ALL_SURVEYS_IN_COMMUNITY, UserPrivilegeName.MANAGE_SURVEYS]}
        icon={<AssignmentOutlinedIcon />}
        label="Surveys"
        to={AppRoutes.Surveys}
        activePath={AppRoutes.Surveys}
      />
      <NavigationLinkItemWithPrivilege
        privilegeAvailability={[UserPrivilegeName.VIEW_REPORTS]}
        icon={<BarChartRoundedIcon />}
        label="Insights Reports"
        to={AppRoutes.InsightsReports}
        activePath={AppRoutes.InsightsReports}
      />

      {isMobile && (
        <NavigationLinkItem
          icon={<SettingsOutlinedIcon />}
          label="Account Settings"
          to={AppRoutes.Account}
          activePath={AppRoutes.Account}
        />
      )}

      <Divider sx={{ my: 2 }} />

      {isFeatureEnabled('CommunitySettingsV2') ? (
        <NavigationLinkItemWithPrivilege
          withCommunity
          icon={<SettingsOutlinedIcon />}
          label="Settings"
          to={AppRoutes.Settings}
          activePath={AppRoutes.Settings}
        />
      ) : (
        <NavigationLinkItemWithPrivilege
          privilegeAvailability={[UserPrivilegeName.MODIFY_COMMUNITY]}
          icon={<SettingsOutlinedIcon />}
          label="Community Settings"
          to={Routes.CommunitySettings}
          activePath={Routes.CommunitySettings}
        />
      )}
    </List>
  );
};
