import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EventRoomAttendee } from 'domain/event';
import { QuestionAssignmentKey } from 'domain/question';

import { p2piMeetingJoined_WS } from './actions';
import { P2pInsightsState } from './types';

const initialState: P2pInsightsState = {
  meetingRoom: null,
};

export const p2pSlice = createSlice({
  name: 'p2pi',
  initialState,
  reducers: {
    setupP2PIMeetingAttendees: (state, { payload }: PayloadAction<EventRoomAttendee[]>) => {
      state.attendees = payload;
    },
    setupP2PIMeetingQuestionAssignment: (state, { payload }: PayloadAction<QuestionAssignmentKey>) => {
      state.questionAssignmentKey = payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(p2piMeetingJoined_WS, (state, { payload }) => {
      state.meetingRoom = payload;
    }),
});

export const { setupP2PIMeetingAttendees, setupP2PIMeetingQuestionAssignment } = p2pSlice.actions;
