import { TypographyProps, styled } from '@mui/material';
import { TextField } from 'common/components/Inputs';

export const StyledForm = styled('form')({
  width: '100%',
  display: 'flex',
});

export const StyledTextField = styled(TextField, { shouldForwardProp: (prop) => prop !== 'typographyVariant' })<{
  typographyVariant: TypographyProps['variant'];
}>(({ theme: { spacing, typography }, typographyVariant = 'inherit' }) => ({
  flex: 1,

  marginLeft: spacing(-1),
  padding: spacing(0, 0, 0, 1),

  fontWeight: 'inherit',

  '& .MuiInputBase-root': {
    padding: spacing(0, 1.125),
    fontWeight: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',

    ...(typography[typographyVariant] && {
      ...typography[typographyVariant],
      minHeight: `calc(${typography[typographyVariant].lineHeight} + ${spacing(0.5)})`,
    }),
  },
}));
