import React, { ChangeEvent, FC, SyntheticEvent } from 'react';

import { Box, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

export const Tabs: FC<{
  tabs: string[];
  className?: string;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  value?: unknown;
  onChange?: (event: SyntheticEvent | ChangeEvent, newValue: number) => Promise<void> | void;
  centered?: boolean;
}> = ({ tabs, variant, value, onChange, centered }) => (
  <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}>
    <MuiTabs centered={centered} variant={variant} value={value} onChange={onChange}>
      {tabs.map((stepLabel) => (
        <MuiTab key={stepLabel} label={stepLabel} data-testid={`step-tab ${stepLabel}`} />
      ))}
    </MuiTabs>
  </Box>
);
