import React, { FC, MouseEvent } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import { useReadableTextFieldStyles } from './ReadableTextField.styles';

export const ReadableTextField: FC<{
  value: string;
  label: string;
  icon: IconProp;
  isError: boolean;
  handleClick: (event: MouseEvent<HTMLElement>) => void;
  handleClearValue: (event: MouseEvent<HTMLElement>) => void;
}> = ({ value, label, icon, isError, handleClick, handleClearValue }) => {
  const classes = useReadableTextFieldStyles();

  return (
    <TextField
      onClick={handleClick}
      className={classes.input}
      label={label}
      value={value}
      onChange={() => {
        // do nothing
      }}
      error={isError}
      InputProps={{
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton onClick={handleClearValue} edge="end" size="small">
              <HighlightOffIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          <InputAdornment position="end" className={classes.inputAdornment}>
            <FontAwesomeIcon icon={icon} color="black" />
          </InputAdornment>
        ),
      }}
    />
  );
};
