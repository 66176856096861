import { P2PInsightWSActions, P2PInsightWSChannels, P2PInsightWSMessages } from 'common/constants';
import { p2pInsightApi } from 'store/apis/p2pInsight';
import { createP2PInsightWSAction } from 'store/utils/p2piWS/createP2PInsightWSAction';
import { createP2PInsightWSMessage } from 'store/utils/p2piWS/createP2PInsightWSMessage';

import { SocketRoomPrefix } from '../socket';
import { createWSSubscribeAction } from '../socket/utils/createWSSubscribeAction';
import { createWSUnsubscribeAction } from '../socket/utils/createWSUnsubscribeAction';
import { P2PInsightMeetingJoinedPayload, P2PInsightsSubmitFeedbackRequest, P2PInsightsWSParams } from './types';

export const subscribeToP2PInsightMeeting = createWSSubscribeAction(SocketRoomPrefix.P2PInsight, P2PInsightWSChannels);

export const unsubscribeFromP2PInsightMeeting = createWSUnsubscribeAction(
  SocketRoomPrefix.P2PInsight,
  P2PInsightWSChannels
);

// P2PI WS Messages
export const p2piMeetingJoined_WS = createP2PInsightWSMessage<P2PInsightMeetingJoinedPayload>(
  P2PInsightWSMessages.JoinedMeeting
);

export const p2piMeetingEnded_WS = createP2PInsightWSMessage(P2PInsightWSMessages.MeetingEnded);

// P2PI WS Actions
export const joinP2piMeeting_WS = createP2PInsightWSAction(P2PInsightWSActions.Join);

export const submitP2piMeetingFeedback_WS = createP2PInsightWSAction<
  P2PInsightsWSParams<P2PInsightsSubmitFeedbackRequest>
>(P2PInsightWSActions.SubmitFeedback, (dispatch) => {
  dispatch(p2pInsightApi.util.invalidateTags(['MEETINGS']));
});

export const endP2piMeeting_WS = createP2PInsightWSAction(P2PInsightWSActions.End);

export const p2piMeetingConnectionError_WS = createP2PInsightWSAction(P2PInsightWSActions.ConnectionError);
