import { type Components, type Theme } from '@mui/material';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: '#091E427D',
      },
    },
    paper: ({ theme: { spacing } }) => ({
      borderRadius: spacing(1),
      border: 'none',
    }),
    paperWidthXs: {
      maxWidth: 400,
    },
    paperWidthSm: {
      maxWidth: 600,
    },
    paperWidthMd: {
      maxWidth: 752,
    },
    paperWidthLg: {
      maxWidth: 968,
    },
    paperFullScreen: {
      maxWidth: '100%',
      borderRadius: 0,
    },
  },
};
