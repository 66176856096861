import { createAction } from '@reduxjs/toolkit';
import { EventRoomAttendee, MeetingGuest } from 'domain/event';
import { RemoteParticipant, Room, TwilioError } from 'twilio-video';

export const eventVideoCallActions = {
  connectToRoom: {
    failed: createAction<{ error: unknown }>('[Event Video Call] connect to room failed'),
  },
  disconnectedFromRoom: createAction<{ room: Partial<Room> | undefined; error: TwilioError }>(
    '[Event Video Call] disconnected from room'
  ),
  localAudioTrack: {
    create: {
      failed: createAction<{ error: unknown }>('[Event Video Call Local Audio Track] create local audio track failed'),
    },
  },
  localVideoTrack: {
    create: {
      failed: createAction<{ error: unknown }>('[Event Video Call Local Video Track] create local video track failed'),
    },
  },
  participant: {
    trackWarningEmitted: createAction<{ name: string; publication: unknown }>(
      '[Event Video Call] participant track warning emitted'
    ),
  },
  connecionTimedOut: createAction<{
    participants: RemoteParticipant[];
    attendees: EventRoomAttendee[];
    disconnectedAttendees: MeetingGuest[];
  }>('[Event Video Call] connection timed out'),
};
