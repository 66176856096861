import React, { FC, MouseEventHandler, useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButtonProps, PopoverOrigin, Stack, SxProps, Theme } from '@mui/material';

import { Loadable } from '../../Loadable';
import { Menu, type MenuItemType } from '../../Menu';
import type { ButtonProps } from '../Button';
import { IconButton } from '../IconButton';
import type { IconButtonVariant } from '../IconButton/types';

const DefaultButton: FC<
  {
    active: boolean;
    variant?: IconButtonVariant;
  } & Pick<IconButtonProps, 'size'>
> = ({ active, size = 'small', variant }) => (
  <IconButton
    variant={variant}
    size={size}
    className={active ? 'active' : undefined}
    sx={({ palette }) => ({ color: palette.primary.main })}
    aria-label="More actions"
  >
    <MoreVertIcon />
  </IconButton>
);

export const ButtonMenu: FC<
  ButtonProps & {
    items: MenuItemType[];
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    icon?: IconProp;
    'data-testid'?: string;
    menuSx?: SxProps<Theme> | undefined;
    variant?: IconButtonVariant;
  }
> = ({
  items,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  children,
  menuSx,
  isLoading = false,
  size,
  variant = 'outlined',
  ...props
}) => {
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handleClose = () => setAnchor(null);

  return (
    <>
      <Stack data-testid={props['data-testid']} alignItems="center" position="relative">
        {isLoading && <Loadable isLoading transitionDelay={false} size={20} sx={{ position: 'absolute' }} />}
        <Box onClick={handleClick} sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>
          {children ?? <DefaultButton size={size} active={Boolean(anchor)} variant={variant} />}
        </Box>
      </Stack>
      <Menu
        sx={menuSx}
        anchor={anchor}
        onClose={handleClose}
        items={items}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      />
    </>
  );
};
