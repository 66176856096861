import React, { FC, memo } from 'react';

import { AppBar, Box, Stack, Toolbar, styled } from '@mui/material';
import { LogoContainer } from 'common/components';
import { NatterThemeProvider } from 'common/hocs';
import { useBreakpoints } from 'common/hooks';
import { Community } from 'domain/Community';
import { useAppSelector } from 'store';
import { selectIsHeaderDisplayed } from 'store/features/app';

import { CurrentUser, GoToHelpCenter, MobileMenuButton, TabletMenuButton } from './components';

const SectionWrapper = styled(Box)({
  flex: '1 1 33%',
  display: 'flex',
  alignItems: 'center',
  '& > a': { width: '100%' },
});

interface TopBarProps {
  title?: string;
  community?: Community | null;
  center?: boolean;
  onLogoClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const TopBar: FC<TopBarProps> = memo(({ center = false }) => {
  const displayHeader = useAppSelector(selectIsHeaderDisplayed);
  const { isMobile, isTablet } = useBreakpoints();

  if (!displayHeader) return null;

  return (
    <AppBar component="header" position="fixed" color="inherit" data-testid="ContentHeader">
      <Toolbar>
        {center ? (
          <SectionWrapper justifyContent="center">
            <LogoContainer />
          </SectionWrapper>
        ) : (
          <>
            <SectionWrapper>
              {isTablet && (
                <Box mr={3}>
                  <TabletMenuButton />
                </Box>
              )}
              <Stack justifyContent="center">
                <LogoContainer />
              </Stack>
            </SectionWrapper>

            <NatterThemeProvider>
              <Stack direction="row" justifyContent="end" alignItems="center" gap={0.5}>
                <GoToHelpCenter />
                {isMobile ? <MobileMenuButton /> : <CurrentUser />}
              </Stack>
            </NatterThemeProvider>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
});
